import React from 'react';

import { Icon } from 'semantic-ui-react';

interface Props {
  value: string | string[];
  options: any[];
}

function OhsSelectValueDisplay(props: Props) {
  const renderValue = () => {
    // match selected values
    const traverseOptions = (options: any, selectedValues: any) => {
      const currentSelection = selectedValues[0];
      const currentOption = options.find((option: any) => option.name === currentSelection);

      if (!currentOption) return null;

      // Get the icon element if the option has an icon
      const iconElement = currentOption.icon ? <Icon name={currentOption.icon} /> : null;

      // If this is the last selected value, return the name wrapped in a span with icon
      if (selectedValues.length === 1) {
        return (
          <span key={currentOption.name}>
            {iconElement && <span>{iconElement}</span>}
            {currentOption.name}
          </span>
        );
      }

      // Continue traversing into nested options if any
      if (currentOption.options) {
        return (
          <span key={currentOption.name}>
            {iconElement && <span>{iconElement}</span>}
            {currentOption.name} {'>'}{' '}
            {traverseOptions(currentOption.options, selectedValues.slice(1))}
          </span>
        );
      }

      return (
        <span key={currentOption.name}>
          {iconElement && <span>{iconElement}</span>}
          {currentOption.name}
        </span>
      );
    };

    // Helper function to handle string values by searching through options
    const findOption = (options: any[], selectedValue: string) => {
      const targetOption = options.find((option: any) => option.name === selectedValue);
      // If no option is found, return just the string
      if (!targetOption) return <span>{selectedValue}</span>;
      const iconElement = targetOption.icon ? <Icon name={targetOption.icon} /> : null;
      return (
        <span key={targetOption.name}>
          {iconElement && <span>{iconElement}</span>}
          {targetOption.name}
        </span>
      );
    };

    if (typeof props.value === 'string') {
      return findOption(props.options, props.value);
    }

    if (Array.isArray(props.value)) {
      return traverseOptions(props.options, props.value);
    }

    return '';
  };
  return <span>{renderValue()}</span>;
}

export default OhsSelectValueDisplay;
