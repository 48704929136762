import React, { ReactNode } from 'react';

import { Box } from '@mui/material';
import { Header } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
  messageBox: {
    width: '100%',
    border: '2px solid #95c9d4',
    borderRadius: '5px',
    background: '#f8ffff',
    padding: '1em 1.5em',
  },
  title: {
    color: '#0e566c !important',
    marginBottom: '.25rem !important',
  },
  description: {
    color: '#0e566c',
  },
}));

interface Props {
  title?: string;
  description?: string | ReactNode;
}

function OhsMessageBox({ title, description }: Props) {
  const { classes } = useStyles();

  const returnDescription = () => {
    if (!description) return '';
    if (typeof description === 'string') {
      <p className={classes.description}>{description}</p>;
    }
    return description;
  };

  return (
    <Box className={classes.messageBox}>
      {title && (
        <Header as="h3" className={classes.title}>
          {title}
        </Header>
      )}
      {returnDescription()}
    </Box>
  );
}

OhsMessageBox.defaultProps = {
  title: undefined,
  description: undefined,
};

export default OhsMessageBox;
