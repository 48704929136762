import { createAsyncThunk } from '@reduxjs/toolkit';

import getChemicalLists, {
  getChemicalAllocatedList,
  getChemicalListsWithoutTasks,
} from 'chemical/OhsChemicalServices';
import {
  OhsChemicalListsFilterPayload,
  OhsChemicalRootState,
} from 'chemical/models/OhsChemicalModel';
import OhsChemicalRecord from 'chemical/models/OhsChemicalRecord';
import parseListAllocations from 'chemical/utils/OhsParseListAllocations';
import { ApiResListsType, OhsApiRequestName } from 'global-services/api/OhsApiModels';
import { OhsGlobalSearchPayload } from 'search/OhsSearchModels';
import globalModuleSearch from 'search/OhsSearchServices';
import { updateSearchInfo } from 'search/OhsSearchUtils';
import { OhsUser } from 'user/OhsUser';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

const chemicalSearch = async (
  search: OhsGlobalSearchPayload,
  count: boolean,
  page: number,
  apiRequestName?: OhsApiRequestName
): Promise<ApiResListsType<OhsChemicalRecord[]> | null> => {
  const requiredSearchFilters =
    apiRequestName === OhsApiRequestName.WorkerGroupList
      ? {
          archived: search.filter?.archived,
          page: search.filter?.page,
          count: search.filter?.count,
          modules: search.filter?.modules,
        }
      : search.filter;
  const searchInfo = { ...search, filter: { ...requiredSearchFilters, count, page } };
  const globalSearchRes: any = await globalModuleSearch(searchInfo, apiRequestName);
  return globalSearchRes.result;
};

export const getChemicalListsWithAllocations = (
  state: OhsChemicalRootState,
  filterInfo: OhsChemicalListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const { globalSearch } = state;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const hasGlobalSearch = globalSearch.searchInfo.searchKey !== '';
  const searchInfo = updateSearchInfo(globalSearch.searchInfo, OhsApiRequestName.List, user);
  const getChemicalListsReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const chemicalList = await (hasGlobalSearch
      ? chemicalSearch(searchInfo, false, state.chemical.currentPage, OhsApiRequestName.List)
      : getChemicalLists(filterInfo));
    // exit if list result is empty
    if (chemicalList && chemicalList?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(chemicalList);
      });
    }

    // get allocated records from chemicals _ids
    const getIDs = chemicalList?.items.map((item: OhsChemicalRecord) => item._id) as string[];
    const allocatedList = await getChemicalAllocatedList(getIDs);
    // map allocated records to chemicallist
    const chemicalListWithAllocations = parseListAllocations(
      chemicalList,
      allocatedList
    ) as ApiResListsType<OhsChemicalRecord[]> | null;

    return new Promise((resolve) => {
      resolve(chemicalListWithAllocations);
    });
  };

  return getChemicalListsReq();
};

const chemicalListsRPC = async (
  state: OhsChemicalRootState,
  user: OhsUser,
  filters: OhsChemicalListsFilterPayload,
  searchPayload: OhsGlobalSearchPayload,
  page: number
): Promise<any> => {
  const userTier = user?.tierNum ?? 0;
  let searchRequestApi = OhsApiRequestName.List;
  if (filters.viewPreset === 'view_1') {
    searchRequestApi = OhsApiRequestName.OrgList;
  } else if (filters.viewPreset === 'view_2') {
    searchRequestApi = OhsApiRequestName.OrgWorkplaceList;
  }
  const searchInfo = updateSearchInfo(searchPayload, searchRequestApi, user);
  const hasGlobalSearch = searchInfo?.searchKey !== '';

  if (userTier && userTier === 3 && filters.viewPreset === 'view_3') {
    const response = await getChemicalListsWithAllocations(state, filters);
    return response;
  }
  if (userTier && (userTier === 2 || userTier === 3 || userTier === 4)) {
    const response = hasGlobalSearch
      ? await chemicalSearch(searchInfo, false, page, searchRequestApi)
      : await getChemicalLists(filters);
    return response;
  }
  if (userTier && userTier === 5) {
    const response = hasGlobalSearch
      ? await chemicalSearch(searchInfo, false, page, OhsApiRequestName.WorkerGroupList)
      : await getChemicalListsWithoutTasks(filters);
    return response;
  }

  return [];
};

export const fetchChemicalListsAsync = createAsyncThunk<
  ApiResListsType<OhsChemicalRecord[]> | null,
  undefined
>('chemical/fetchChemicalList', async (_, thunkAPI) => {
  const state = thunkAPI.getState() as OhsChemicalRootState;
  const {
    chemical,
    globalfilter: { filterInfo },
    globalSearch,
  } = thunkAPI.getState() as OhsChemicalRootState;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;

  const { currentViewPreset, currentPage } = chemical;
  const viewPresetFilter = filterInfo.chemicalModule[currentViewPreset];
  const setChemicalFilters: OhsChemicalListsFilterPayload = {
    ...viewPresetFilter,
    sort: { ...JSON.parse(String(filterInfo.chemicalModule.sort)) },
    next: currentPage > 10 ? chemical.chemicalList?.pagination.next ?? '' : undefined,
    // remove page if it is greater than 10
    ...(currentPage <= 10 && { page: currentPage }),
    count: false,
  };

  try {
    const response = await chemicalListsRPC(
      state,
      user,
      setChemicalFilters,
      globalSearch?.searchInfo,
      currentPage
    );
    return response as ApiResListsType<OhsChemicalRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchChemicalListsCountAsync = createAsyncThunk<ApiResListsType<
  OhsChemicalRecord[]
> | null>('chemical/fetchChemicalListWithCount', async (_, thunkAPI) => {
  const {
    chemical,
    globalfilter: { filterInfo },
    globalSearch,
  } = thunkAPI.getState() as OhsChemicalRootState;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;

  const { currentViewPreset } = chemical;
  const userTier = user?.tierNum ?? 0;
  const viewPresetFilter = filterInfo.chemicalModule[currentViewPreset];
  const hasGlobalSearch = globalSearch?.searchInfo?.searchKey !== '';

  const chemicalFiltersWithCount: OhsChemicalListsFilterPayload = {
    ...viewPresetFilter,
    count: true,
    page: 1,
    sort: { order: 1, key: 'name' },
  };

  let searchRequestApi = OhsApiRequestName.List;
  if (chemicalFiltersWithCount?.viewPreset === 'view_1') {
    searchRequestApi = OhsApiRequestName.OrgList;
  } else if (chemicalFiltersWithCount?.viewPreset === 'view_2') {
    searchRequestApi = OhsApiRequestName.OrgWorkplaceList;
  }
  const requiredApiRequest = userTier === 5 ? OhsApiRequestName.WorkerGroupList : searchRequestApi;

  const searchInfo = updateSearchInfo(globalSearch.searchInfo, searchRequestApi, user);

  try {
    const response = hasGlobalSearch
      ? await chemicalSearch(searchInfo, true, 1, requiredApiRequest)
      : await getChemicalLists(chemicalFiltersWithCount);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
