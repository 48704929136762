import { ApiResListsType, OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';
import OhsFetchAll from 'global-services/OhsFetchAll';

import { OhsOnboardingFilterPayload } from './OhsOnboardingModels';

const getOnboardingList = async (
  filterInfo: OhsOnboardingFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const response: any = await OhsApiRequest(
    RequestType.Admin,
    ModuleType.Freemium,
    OhsApiRequestName.List,
    {
      filter: filterInfo,
    }
  );
  return response;
};

export const getAllOnboardingList = async () => {
  const res = await OhsFetchAll(getOnboardingList, { archived: false, viewPreset: 'view_1' });
  if (!res) return null;
  return res.items;
};

export const OhsOnboardingServices = {
  getOnboardingList,
  getAllOnboardingList,
};
