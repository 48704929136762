import React from 'react';

import { TextField } from '@mui/material';

interface Props {
  cellProps: any;
  handleInputChange: (val: string, cellProps: any) => void;
}

function OhsTaskAssignInput(props: Props) {
  const [currentValue, setCurrentValue] = React.useState<string | null>(null);
  const handleChange = (data: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newVal = data.target.value;
    setCurrentValue(newVal);
    props.handleInputChange(newVal, props.cellProps);
  };
  return (
    <TextField
      onChange={handleChange}
      variant="outlined"
      margin="dense"
      multiline
      disabled={props.cellProps.row.original.loading}
      value={currentValue !== null ? currentValue : props.cellProps.row.original.message}
      size="small"
    />
  );
}

export default OhsTaskAssignInput;
