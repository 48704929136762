import { ModuleType } from 'global-services/constants/OhsObject';

import {
  getConfigValue,
  setNotificationEnabled,
  setPermissions,
  setPrivacyPermissions,
} from '../OhsConfigServices';
import OhsModulePermissions from '../OhsModulePermissions';
import OhsFileBoxConfig from '../filebox/OhsFileBoxConfig';
import OhsPrivacyPermissions from '../OhsPrivacyPermissions';

class OhsHrConfig {
  TITLE: string;

  PERMISSIONS: OhsModulePermissions;

  MORPH_PERMISSIONS: OhsModulePermissions;

  TYPE = ModuleType.HR;

  fileboxConfig?: OhsFileBoxConfig;

  POSITION_TITLE_LIST: string[];

  PRIVACY: {
    details: OhsPrivacyPermissions;
    linkedIncidents: boolean;
    linkedCorrespondences: boolean;
  };

  NOTIFICATION_ENABLED: { email: boolean; managerEmail: boolean };

  constructor(config: any, fileboxConfig?: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsModulePermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    this.PRIVACY = {
      details: new OhsPrivacyPermissions(false),
      linkedIncidents: false,
      linkedCorrespondences: false,
    };
    this.NOTIFICATION_ENABLED = { email: false, managerEmail: false };

    this.POSITION_TITLE_LIST =
      getConfigValue(config, 'POSITION_TITLE_LIST')?.map((p: any) => p.title) ?? [];
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(this.MORPH_PERMISSIONS, getConfigValue(config, 'MORPH_PERMISSIONS'));
    setNotificationEnabled(
      this.NOTIFICATION_ENABLED,
      getConfigValue(config, 'NOTIFICATION_ENABLED')
    );
    setPrivacyPermissions(this.PRIVACY, getConfigValue(config, 'PRIVACY'));
    if (fileboxConfig) {
      this.fileboxConfig = new OhsFileBoxConfig(fileboxConfig);
    }
  }
}

export default OhsHrConfig;
