import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResListsType, OhsApiRequestName } from 'global-services/api/OhsApiModels';
import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';
import globalModuleSearch from 'search/OhsSearchServices';
import { OhsActionModuleViewPresets } from 'dashboard/topFilter/OhsAssetFilterModels';
import { TierType } from 'global-services/constants/OhsObject';
import { OhsUser } from 'user/OhsUser';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import {
  getActionLists,
  getActionListsWithTasks,
  getActionTasksList,
  parseActionTaskLists,
} from './OhsActionServices';
import { OhsActionFilterPayload, OhsActionsRecord } from './OhsActionModels';

export interface ActionListsRecordState {
  isLoading: boolean;
  actionLists: ApiResListsType<any[]> | null;
  actionTasksLists: ApiResListsType<any[]> | null;
  currentPage: number;
  currentViewPreset: OhsActionModuleViewPresets;
  showSignoffActions: boolean;
}

const initialState: ActionListsRecordState = {
  isLoading: false,
  actionLists: null,
  actionTasksLists: null,
  currentPage: 1,
  currentViewPreset: OhsActionModuleViewPresets.view_1,
  showSignoffActions: false,
};

export const getActionViewPreset = (tierNum: number) => {
  switch (tierNum) {
    case 3:
      return OhsActionModuleViewPresets.view_1;
    case 4:
    case 5:
      return OhsActionModuleViewPresets.view_2;
    default:
      return OhsActionModuleViewPresets.view_1;
  }
};

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const actionFilterOptions = (state: RootState, count: boolean) => {
  const { globalfilter, actionsReport } = state;
  const curentPresetFilter = globalfilter.filterInfo.actionModule[actionsReport.currentViewPreset];
  const actionFilter = {
    ...curentPresetFilter,
    sort: { ...JSON.parse(String(curentPresetFilter.sort)) },
    page: actionsReport.currentPage > 10 ? 0 : actionsReport.currentPage,
    count,
  };
  return actionFilter;
};

const globalSearch = async (
  state: RootState,
  setActionFilters: OhsActionFilterPayload,
  count: boolean,
  page: number,
  apiRequestName?: OhsApiRequestName
): Promise<ApiResListsType<any[]> | null> => {
  const action = state.globalSearch.searchInfo;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const requiredSearchFilters =
    apiRequestName === OhsApiRequestName.WorkerGroupList
      ? {
          page: action.filter?.page,
          count: action.filter?.count,
          modules: action.filter?.modules,
        }
      : action.filter;
  const searchInfo = {
    ...action,
    filter: { ...requiredSearchFilters, count, page, archived: setActionFilters.archived },
  };
  const globalSearchRes: any = await globalModuleSearch(searchInfo, apiRequestName);
  const getIDs = globalSearchRes?.result?.items.map(
    (item: OhsActionsRecord) => item._id
  ) as string[];
  let taskList: any = [];
  if (getIDs.length > 0 && user?.tier.type !== TierType.T5)
    taskList = await getActionTasksList(getIDs);

  const actionListsWithTasks = parseActionTaskLists(
    globalSearchRes?.result,
    taskList
  ) as ApiResListsType<OhsActionsRecord[]> | null;
  return actionListsWithTasks;
};

const actionListsRPC = async (
  user: OhsUser,
  setActionFilters: OhsActionFilterPayload,
  userTier: number
): Promise<any> => {
  if (
    userTier &&
    (userTier === 2 || userTier === 3 || userTier === 4) &&
    user?.configs.action?.PERMISSIONS.view
  ) {
    const response = await getActionListsWithTasks(setActionFilters);
    return response;
  }
  if (userTier && userTier === 5 && user?.configs.action?.PERMISSIONS.view) {
    const response = await getActionLists(setActionFilters);
    return response;
  }
  return null;
};

export const fetchActionListsAsync = createAsyncThunk<
  ApiResListsType<OhsActionsRecord[]> | null,
  undefined,
  { state: RootState }
>('actions/fetchActionList', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const userTier = user?.tierNum ?? 0;
  const hasActionSearch = state.globalSearch.searchInfo.searchKey !== '';
  const currentPage = state.actionsReport.currentPage > 10 ? 0 : state.actionsReport.currentPage;

  const completeProp = state.actionsReport.showSignoffActions ? { complete: false } : {};
  const setActionFilters: OhsActionFilterPayload = {
    ...actionFilterOptions(state, false),
    ...completeProp,
  };
  const requiredApirequest =
    user?.tierNum === 5 ? OhsApiRequestName.WorkerGroupList : OhsApiRequestName.List;
  try {
    const response = hasActionSearch
      ? await globalSearch(state, setActionFilters, false, currentPage, requiredApirequest)
      : await actionListsRPC(user, setActionFilters, userTier);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchActionListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsActionsRecord[]> | null,
  undefined,
  { state: RootState }
>('actions/fetchActionListCount', async (_, thunkAPI) => {
  const state = thunkAPI.getState();
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const hasActionSearch = state.globalSearch.searchInfo.searchKey !== '';

  const completeProp = state.actionsReport.showSignoffActions ? { complete: false } : {};
  const actionFiltersWithCount: OhsActionFilterPayload = {
    ...actionFilterOptions(state, true),
    ...completeProp,
  };
  const requiredApirequest =
    user?.tierNum === 5 ? OhsApiRequestName.WorkerGroupList : OhsApiRequestName.List;
  try {
    const response = hasActionSearch
      ? await globalSearch(state, actionFiltersWithCount, true, 1, requiredApirequest)
      : await getActionLists(actionFiltersWithCount);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const actionSlice = createSlice({
  name: 'actions',
  initialState,
  reducers: {
    setActionCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setActionIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setActionViewPreset: (state, action: PayloadAction<OhsActionModuleViewPresets>) => {
      state.currentViewPreset = action.payload;
    },
    setShowOpenActions: (state, action: PayloadAction<boolean>) => {
      state.showSignoffActions = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchActionListsAsync.pending, (state) => {
        state.isLoading = true;
        state.actionLists = {
          items: [],
          pagination: state.actionLists?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchActionListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.actionLists = action.payload;
          if (state?.actionLists?.pagination?.totalPages) {
            setOhsLocalStorage('actionListtotalPages', state.actionLists.pagination.totalPages);
          }
          let setItemsLastId = '';
          const actionPlanItems = [...state.actionLists.items];
          if (actionPlanItems.length > 0) setItemsLastId = actionPlanItems.pop()._id;

          state.actionLists.pagination.next = setItemsLastId;
          state.actionLists.pagination.totalPages = getOhsLocalStorage('actionListtotalPages') ?? 0;
          state.isLoading = false;
        }
      })
      .addCase(fetchActionListsAsync.rejected, (state) => {
        state.actionLists = null;
      })
      .addCase(fetchActionListsCountAsync.fulfilled, (state, action) => {
        if (state.actionLists && action.payload) {
          setOhsLocalStorage('actionListtotalPages', action.payload.pagination.totalPages);
          state.actionLists.pagination.totalPages = action.payload.pagination.totalPages ?? 0;
        }
      });
  },
});

const actionsState = (state: RootState) => state.actionsReport;
export const getOhsActionsLists = createSelector([actionsState], (actionsReport) => actionsReport);
export const { setActionCurrentPage, setActionIsLoading, setActionViewPreset, setShowOpenActions } =
  actionSlice.actions;
export const actionModuleReducer = actionSlice.reducer;
