import OhsRecordFieldType from 'global-services/constants/record-field/OhsRecordFieldType';

export const VisitorLocalArriveListName = 'VisitorLocalArriveListName';

export const OhsPinRecordField = new OhsRecordFieldType('pin', 'Pin');

export const OhsGeoArriveRecordField = new OhsRecordFieldType(
  'geoArrive',
  'Geo Boundary Arrive Mandatory',
  `This controls whether the location-based sign-in is
  mandatory or optional. When this switch is on, users must be
  within the geo location boundary to sign-in. When this
  switch is off, users can sign-in to a site even when they
  are outside of the set geo location boundary; and/or,
  sign-in if they do not authorise the Safety Champion App to
  access to their location.`
);

export const OhsGeoLeaveRecordField = new OhsRecordFieldType(
  'geoLeave',
  'Geo Boundary Leave Default',
  `When this switch is on, users who scan the QR Code of this
  site register will be asked to enable location-based
  sign-out. When this switch is off, users can manually enable
  location-based sign-out.`
);

export const OhsRoleRecordField = new OhsRecordFieldType('role', 'Role');

export const OhsVisitorNameRecordField = new OhsRecordFieldType('name', 'Name');

export const OhsVisitorEmailRecordField = new OhsRecordFieldType('email', 'Email');

export const OhsVisitorPhoneRecordField = new OhsRecordFieldType('phone', 'Phone Number');

export const OhsVisitorPhoneCodeRecordField = new OhsRecordFieldType(
  'phoneCountryCode',
  'Phone Country Code'
);

export const OhsVisitorPhoneAndPhoneCodeRecordField = new OhsRecordFieldType(
  'phoneAndCode',
  'Phone Number'
);
export const OhsCategoryRecordField = new OhsRecordFieldType('category', 'Category');

export const OhsSubcategoryRecordField = new OhsRecordFieldType('subcategory', 'Subcategory');

export const OhsFormMessageTooltip = {
  pre: `This information is presented once the person has selected their role. 
  Note: No Intro Message is part of the Sign-out Process.`,
  in: `This is the section where the custom questions are presented. Typically, 
  this will be where "induction" information may be provided.`,
  post: 'This information is presented on submission of the sign-in / sign-out form.',
};
