import React, { useRef } from 'react';

import { Grid, Theme } from '@mui/material';
import { Message } from 'semantic-ui-react';
import { makeStyles } from 'tss-react/mui';

import { TaskAssignStatus } from 'task/OhsTaskTypes';
import OhsRegister from 'global-components/register/OhsRegister';
import { assignTaskTo, unAssignTaskTo } from 'task/OhsTaskServices';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { fetchTaskAssignStatusAsync, getOhsTaskAssignState } from 'task/store/OhsTaskAssignSlice';

import OhsTaskAssignInput from './OhsTaskAssignInput';

const useStyles = makeStyles()((theme: Theme) => ({
  alert: {
    border: '2px solid #b2dbef',
    background: '#e5f6ff',
    fontSize: '14px',
    padding: '2px 8px',
    borderRadius: '5px',
    marginBottom: '10px',
    color: theme.palette.custom.HEADER_BAR_BG_COLOR ?? 'black',
  },
  assignedUsers: {
    marginBottom: '10px',
  },
}));

interface Props {
  taskAssignList: TaskAssignStatus[] | null;
}

const assignedUsersListsColumnConfig = [
  {
    Header: 'Name',
    accessor: 'user.name',
    customWidth: '245px',
  },
  {
    Header: 'Workplace',
    accessor: 'user.tier.name',
    customWidth: '180px',
  },
  {
    Header: 'Message',
    accessor: 'message',
  },
];

const initUnAssignedColumnConfig = [
  {
    Header: 'Name',
    accessor: 'user.name',
    customWidth: '180px',
  },
  {
    Header: 'Workplace',
    accessor: 'user.tier.name',
    customWidth: '180px',
  },
];

export default function OhsTaskAssignTable(props: Props) {
  const { classes } = useStyles();
  const messageRef = useRef({});
  const [userList, setUserList] = React.useState(new Array<TaskAssignStatus>());
  const taskAssign = useAppSelector(getOhsTaskAssignState);
  const dispatch = useAppDispatch();

  const handleInputChange = (val: string, cellProps: any) => {
    const currRefVal = messageRef.current || {};
    const userId = cellProps?.row?.original?.user?._id || '';
    if (userId) {
      messageRef.current = {
        ...currRefVal,
        [userId]: val,
      };
    }
  };

  React.useEffect(() => {
    const currRefMessages: any = messageRef.current;
    if (Object.keys(currRefMessages).length > 0) {
      const listWithPreviousMessage = (props.taskAssignList || []).map((item) => {
        if (currRefMessages[item.user._id]) {
          return { ...item, message: currRefMessages[item.user._id] };
        }
        return item;
      });
      setUserList(listWithPreviousMessage);
    } else {
      setUserList(props.taskAssignList || []);
    }
  }, [props.taskAssignList]);

  const taskAssignInput = (cellProps: any) => {
    return <OhsTaskAssignInput cellProps={cellProps} handleInputChange={handleInputChange} />;
  };

  const unAssignedColumnConfig = React.useMemo(
    () => [
      ...initUnAssignedColumnConfig,
      {
        Header: 'Message',
        accessor: 'message',
        Cell(cellProps: any) {
          return (
            cellProps.row.original &&
            cellProps.row.original?._id === null &&
            taskAssignInput(cellProps)
          );
        },
      },
    ],
    [userList]
  );

  const unassignTask = async (item: TaskAssignStatus) => {
    if (taskAssign.taskAssignInfo) {
      const unAssignToPayload = {
        _id: taskAssign.taskAssignInfo._id,
        for: taskAssign.taskAssignInfo.for,
        to: {
          _id: item.user._id,
          type: item.user.type,
        },
      };
      await unAssignTaskTo(unAssignToPayload);
      if (taskAssign.taskAssignInfo) {
        dispatch(fetchTaskAssignStatusAsync(taskAssign.taskAssignInfo));
      }
    }
  };

  const assignTask = async (item: TaskAssignStatus) => {
    const currRefMessages: any = messageRef.current;
    if (taskAssign.taskAssignInfo) {
      const assignToPayload: any = {
        _id: taskAssign.taskAssignInfo._id,
        for: taskAssign.taskAssignInfo.for,
        to: {
          _id: item?.user?._id,
          type: item?.user?.type,
        },
        message: currRefMessages[item?.user?._id] ?? '',
      };
      await assignTaskTo(assignToPayload);
      if (taskAssign.taskAssignInfo) {
        messageRef.current = Object.fromEntries(
          Object.entries(currRefMessages).filter(([key]) => key !== item?.user?._id)
        );
        dispatch(fetchTaskAssignStatusAsync(taskAssign.taskAssignInfo));
      }
    }
  };

  const renderRegister = React.useCallback(
    () => (
      <OhsRegister
        columns={unAssignedColumnConfig}
        data={userList.filter((item) => item._id === null)}
        sortBy={[{ id: 'user.name', desc: false }]}
        rowOperations={{
          assignButton: {
            func: assignTask,
            permission: true,
          },
        }}
        useTableSearch
        useTablePagination
      />
    ),
    [unAssignedColumnConfig, userList]
  );

  return (
    <>
      <Grid container spacing={2} className={classes.assignedUsers}>
        <Grid item xs={12} md={12}>
          <Message info>
            <p>
              {`This task has been assigned to the following "${
                userList.filter((item) => item._id !== null).length
              }" Users:`}
            </p>
          </Message>

          <OhsRegister
            columns={assignedUsersListsColumnConfig}
            data={userList.filter((item) => item._id !== null)}
            sortBy={[{ id: 'nameId', desc: false }]}
            hideHeader
            rowOperations={{
              assignButton: {
                func: unassignTask,
                permission: true,
              },
            }}
          />
        </Grid>
      </Grid>
      {renderRegister()}
    </>
  );
}
