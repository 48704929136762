import React, { ReactNode, useEffect } from 'react';

import { Box, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import clsx from 'clsx';

import { getDateDiff } from 'global-services/OhsDataParse';
import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';
import useWindowSize from 'global-components/screenSize/useWindowSize';

// const useStyles = makeStyles<Theme, DateColumnPropTypes>((props) => ({
interface StyleTheme extends Theme, DateColumnPropTypes {}

const useStyles = makeStyles<StyleTheme>()(() => ({
  root: {
    fontSize: '14px',
    paddiing: '0',
    fontWeight: 'bold',
  },
  dateColorBoxCell: {
    width: 'auto',
  },
  whiteSpaceBox: {
    whiteSpace: 'nowrap',
  },
  boxed: {
    fontSize: '12px',
    padding: '4px 20px',
    textAlign: 'center',
    color: 'white',
    borderRadius: '4px',
    fontWeight: 'bold',
    width: 'max-content',
  },
  mobile: {
    padding: '0',
    margin: '0 5px 0 0',
    fontWeight: 'bold',
    fontSize: '14px',
  },
  boxedMobile: {
    padding: '0 20px',
    marginRight: '5px',
    fontSize: '12px',
    borderRadius: '4px',
    color: 'white',
  },
  closedBox: {
    color: 'rgba(0, 0, 0, 0.6)',
    paddingLeft: '34px',
    paddingRight: '34px',
  },

  withDiff: {
    margin: '0 2px',
  },
  dateColorBoxWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

interface DateColumnPropTypes {
  children: ReactNode;
  dueDate?: string | number | Date;
  completedDate?: string | number | Date;
  signoffDate?: string | number | Date;
  withDateDifference?: boolean;
}

const formatDate = (date: string | number | Date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
};

export const colorDate = (
  dueDate?: string | number | Date,
  completedDate?: string | number | Date,
  signoffDate?: string | number | Date,
  nowDate?: string | number | Date,
  closed?: boolean
) => {
  let diff;
  let dueDateDate;
  let completedDateDate = null;
  if (closed) {
    return '#e8e8e8'; // lighterGrey
  }
  if (signoffDate === undefined && completedDate === undefined && dueDate !== undefined) {
    const now = nowDate ?? moment(new Date(formatDate(new Date()))).startOf('day');
    const due = moment(new Date(dueDate)).startOf('day');
    diff = moment(due).diff(now, 'days');

    /* due today */
    if (diff === 0) {
      return '#22BA46'; // green
    }
    if (diff < 0) {
      /* passed due date */
      return '#DB2828'; // red
    }
    if (diff > 0 && diff <= 14) {
      return '#F2711D'; // orange
    }
    if (diff > 14) {
      return '#2285cf'; // blue
    }

    return '';
  }
  if (signoffDate === undefined && completedDate !== undefined && dueDate !== undefined) {
    dueDateDate = moment(new Date(dueDate)).startOf('day');
    completedDateDate = moment(new Date(completedDate)).startOf('day');
    diff = moment(dueDateDate).diff(completedDateDate, 'days');
    /* due today */
    if (diff >= 0) {
      return '#22BA46'; // green
    }
    if (diff < 0) {
      /* passed due date */
      return '#DB2828'; // red
    }

    return '';
  }

  if (signoffDate !== undefined && completedDate !== undefined && dueDate !== undefined) {
    dueDateDate = moment(new Date(dueDate)).startOf('day');
    completedDateDate = moment(new Date(completedDate)).startOf('day');
    const signoffDateDate = moment(new Date(signoffDate)).startOf('day');
    const diffDueDate = moment(dueDateDate).diff(signoffDateDate, 'days');
    const diffcompletedDate = moment(dueDateDate).diff(completedDateDate, 'days');

    if (diffDueDate === 0) {
      // "GREEN - Signoff Date" is equal "Due Date" but "Complete Date" is before "Due Date"
      return '#22BA46'; // green
    }
    if (diffDueDate > 0) {
      return '#21BA45';
    }
    if (diffcompletedDate >= 0) {
      // "ORANGE - Signoff Date" >= "Due Date" but "Complete Date" is equal "Due Date"
      return '#F2711C'; // orange
    }
  }
  return '#DB2828';
};

export function OhsDateColorBox(props: any) {
  const screenSize = useWindowSize();
  const { classes } = useStyles(props);
  const [dynamicColors, setDynamicClasses] = React.useState('');
  const { dueDate, completedDate, signoffDate, nowDate, noBackgroundBox, closed, isSmall } = props;
  useEffect(() => {
    const getColorDate = colorDate(dueDate, completedDate, signoffDate, nowDate, closed);

    setDynamicClasses(getColorDate);
  }, [props]);

  const isMobile = isSmall ?? screenSize.width < SmallScreenWidthBreakpoint;
  const boxstyle = noBackgroundBox ? 'color' : 'backgroundColor';
  const boxedMobile = isMobile && !noBackgroundBox;
  return (
    <Box
      className={clsx({
        [classes.dateColorBoxCell]: true,
        [classes.dateColorBoxWrap]: isMobile,
      })}
    >
      <Box
        data-testid="datecolorbox"
        sx={{ [boxstyle]: `${dynamicColors}!important` }}
        className={clsx({
          [classes.root]: noBackgroundBox,
          [classes.mobile]: isMobile && !boxedMobile,
          [classes.boxed]: !noBackgroundBox && !boxedMobile,
          [classes.boxedMobile]: isMobile && !noBackgroundBox,
          [classes.closedBox]: closed,
        })}
      >
        {props.children}
      </Box>

      {props.withDateDifference && props.dueDate && (
        <Box
          className={clsx({
            [classes.withDiff]: !isMobile,
          })}
          data-testid="withDateDifference"
        >
          {getDateDiff(props.dueDate)}
        </Box>
      )}
    </Box>
  );
}

export function OhsDateRenderBox(props: any) {
  const { classes } = useStyles(props);

  return (
    <Box
      className={clsx({
        [classes.whiteSpaceBox]: true,
      })}
    >
      {props.children}
    </Box>
  );
}

export const dateColorBoxFunc = (
  dueDate?: string | number | Date,
  dateCompleted?: string | number | Date,
  signoffDate?: string | number | Date,
  content?: string,
  withDateDifference?: boolean,
  noBackgroundBox?: boolean,
  closed?: boolean
) => {
  return (
    dueDate && (
      <OhsDateColorBox
        dueDate={dueDate}
        completedDate={dateCompleted}
        signoffDate={signoffDate}
        withDateDifference={withDateDifference}
        noBackgroundBox={noBackgroundBox}
        closed={closed}
      >
        {closed ? 'Closed' : content}
      </OhsDateColorBox>
    )
  );
};
