import { ModuleType } from 'global-services/constants/OhsObject';

import { getConfigValue } from '../OhsConfigServices';

class OhsNotificationConfig {
  NOTIFY_BEFORE_DAYS: number;

  NOTIFY_AFTER_DAYS: number;

  NOTIFY_AFTER_FREQUENCY: number;

  NOTIFY_TIME: { hours: number; minutes: number };

  NOTIFY_SUBSCRIBE_EMAIL: boolean;

  NOTIFY_SUBSCRIBE_SMS: boolean;

  NOTIFY_SUBSCRIBE_PUSH: boolean;

  TYPE = ModuleType.Notification;

  constructor(config: any) {
    this.NOTIFY_BEFORE_DAYS = getConfigValue(config, 'NOTIFY_BEFORE_DAYS');
    this.NOTIFY_AFTER_DAYS = getConfigValue(config, 'NOTIFY_AFTER_DAYS');
    this.NOTIFY_AFTER_FREQUENCY = getConfigValue(config, 'NOTIFY_AFTER_FREQUENCY');
    this.NOTIFY_TIME = getConfigValue(config, 'NOTIFY_TIME');
    this.NOTIFY_SUBSCRIBE_EMAIL = getConfigValue(config, 'NOTIFY_SUBSCRIBE_EMAIL');
    this.NOTIFY_SUBSCRIBE_SMS = getConfigValue(config, 'NOTIFY_SUBSCRIBE_SMS');
    this.NOTIFY_SUBSCRIBE_PUSH = getConfigValue(config, 'NOTIFY_SUBSCRIBE_PUSH');
  }
}

export default OhsNotificationConfig;
