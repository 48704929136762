import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { ApiResListsType, OhsApiRequestName } from 'global-services/api/OhsApiModels';
import {
  AllocatedListType,
  OhsSafetyPlanFilterPayload,
  OhsSafetyPlanRootState,
} from 'safety-plan/OhsSafetyPlanModels';
import OhsSafetyPlanRecord from 'safety-plan/OhsSafetyPlanRecord';
// eslint-disable-next-line import/no-cycle
import {
  getSafetyPlanAllocatedList,
  getSafetyPlanCopyList,
  getSafetyPlanLists,
  getSafetyPlanListsWithAllocations,
  getSafetyPlanScheduledLists,
} from 'safety-plan/OhsSafetyPlanServices';
import globalModuleSearch from 'search/OhsSearchServices';
import { getSafetyPlanSchedListViewPreset } from 'safety-plan/register/utils/OhsSafetyPlanUtils';
import { OhsSafetyPlanViewPresets } from 'dashboard/topFilter/OhsSafetyPlanFilterModels';
import OhsFetchAll from 'global-services/OhsFetchAll';
import OhsUserLocalServices from 'user/OhsUserLocalServices';
import { OhsUser } from 'user/OhsUser';

export const safetyPlanSearch = async (
  searchDetails: any,
  apiRequestName?: OhsApiRequestName
): Promise<ApiResListsType<OhsSafetyPlanRecord[]> | null> => {
  const globalSearchRes: any = await globalModuleSearch(searchDetails, apiRequestName);
  return globalSearchRes.result;
};

export const safetyPlanListsRPC = async (
  safetyPlanState: OhsSafetyPlanRootState,
  safetyPlanFilters: OhsSafetyPlanFilterPayload,
  scheduled: boolean,
  count: boolean,
  orgAdminTierId?: string
): Promise<ApiResListsType<any[]> | null> => {
  const { globalSearch } = safetyPlanState;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const userTier = user?.tierNum ?? 0;
  const hasGlobalSearch = safetyPlanState.globalSearch.searchInfo.searchKey !== '';

  const getSearchFilter = () => {
    const { filter } = globalSearch.searchInfo;
    if (scheduled) {
      return {
        ...globalSearch.searchInfo,
        filter: {
          skipOrgRecords: filter.skipOrgRecords,
          workplaces: filter.workplaces,
          page: filter.page,
          count,
          archived: filter.archived,
        },
      };
    }

    return { ...globalSearch.searchInfo, filter: { ...filter, count, skipAllocatedRecords: true } };
  };

  if (
    userTier &&
    (userTier === 2 || userTier === 4) &&
    user?.configs.safetyplan?.PERMISSIONS.view
  ) {
    if (!scheduled) {
      return hasGlobalSearch
        ? safetyPlanSearch(getSearchFilter())
        : getSafetyPlanLists(safetyPlanFilters);
    }
    return hasGlobalSearch
      ? safetyPlanSearch(getSearchFilter(), OhsApiRequestName.SafetyPlanScheduleSearch)
      : getSafetyPlanScheduledLists(safetyPlanFilters);
  }
  if (userTier && userTier === 3 && user?.configs.safetyplan?.PERMISSIONS.view) {
    if (!scheduled) {
      return getSafetyPlanListsWithAllocations(
        safetyPlanState,
        safetyPlanFilters,
        getSearchFilter()
      );
    }
    return hasGlobalSearch
      ? safetyPlanSearch(getSearchFilter(), OhsApiRequestName.SafetyPlanScheduleSearch)
      : getSafetyPlanScheduledLists(safetyPlanFilters);
  }
  if (userTier && userTier === 1) {
    // no global search
    return getSafetyPlanLists(safetyPlanFilters, orgAdminTierId);
  }
  return null;
};

export const fetchSafetyPlanCopyListsAsync = createAsyncThunk<ApiResListsType<any[]>, undefined>(
  'safetyPlan/fetchSafetyPlanCopyList',
  async (_, thunkAPI): Promise<ApiResListsType<any[]> | any> => {
    try {
      const response = await OhsFetchAll(getSafetyPlanCopyList, {
        archived: false,
        sort: {
          order: 1,
          key: '_id',
        },
      });
      const resVal = response;
      return resVal;
    } catch (err: any) {
      const error: AxiosError<any> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }

      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchSafetyPlanListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsSafetyPlanRecord[]> | null,
  { orgAdminTierId: string | undefined; tabKey: string | null }
>('safetyPlan/fetchSafetyPlanCountList', async ({ orgAdminTierId }, thunkAPI) => {
  const {
    safetyPlan,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsSafetyPlanRootState;
  const { currentViewPreset } = safetyPlan;
  const viewPresetFilter = filterInfo.safetyPlanModule[currentViewPreset];
  const safetyPlanState = thunkAPI.getState() as OhsSafetyPlanRootState;
  const safetyPlanFiltersWithCount: OhsSafetyPlanFilterPayload = {
    ...viewPresetFilter,
    count: true,
    page: 1,
    sort: { ...JSON.parse(String(filterInfo.safetyPlanModule.sort)) },
  };

  try {
    const response = safetyPlanListsRPC(
      safetyPlanState,
      safetyPlanFiltersWithCount,
      false,
      true,
      orgAdminTierId
    );
    return await response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchSafetyPlanListsAsync = createAsyncThunk<
  ApiResListsType<OhsSafetyPlanRecord[]> | null,
  { orgAdminTierId: string | undefined; tabKey: string | null }
>('safetyPlan/fetchSafetyPlanList', async ({ orgAdminTierId }, thunkAPI) => {
  const {
    safetyPlan,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsSafetyPlanRootState;

  const safetyPlanState = thunkAPI.getState() as OhsSafetyPlanRootState;
  const data = safetyPlan.safetyPlanLists;
  const { currentPage, currentViewPreset } = safetyPlan;
  const viewPresetFilter = filterInfo.safetyPlanModule[currentViewPreset];

  const genSafetyPlanFilters: OhsSafetyPlanFilterPayload = {
    ...viewPresetFilter,
    next: currentPage > 10 ? data?.pagination.next ?? '' : undefined,
    // remove page if it is greater than 10
    ...(currentPage <= 10 && { page: currentPage }),
    sort: { ...JSON.parse(String(filterInfo.safetyPlanModule.sort)) },
    count: false,
  };

  try {
    const response = safetyPlanListsRPC(
      safetyPlanState,
      genSafetyPlanFilters,
      false,
      false,
      orgAdminTierId
    );
    return await response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchSafetyPlanAllocatedListsAsync = createAsyncThunk<
  AllocatedListType[],
  { _ids: string[] }
>('safetyPlan/fetchSafetyPlanAllocatedList', async (safetyPlanIDs, thunkAPI): Promise<any> => {
  try {
    const response = await getSafetyPlanAllocatedList(safetyPlanIDs._ids);
    const resVal = response;
    return resVal;
  } catch (err: any) {
    const error: AxiosError<any> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchSafetyPlanScheduledListsCountAsync = createAsyncThunk<
  ApiResListsType<any> | null,
  string | null
>('safetyPlan/fetchSafetyPlanScheduledCountList', async (tabKey, thunkAPI) => {
  const {
    safetyPlan,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsSafetyPlanRootState;

  const safetyPlanState = thunkAPI.getState() as OhsSafetyPlanRootState;
  const { currentViewPreset } = safetyPlan;
  const viewPresetFilter = filterInfo.safetyPlanModule[currentViewPreset];

  const filtersWithCount: OhsSafetyPlanFilterPayload = {
    ...viewPresetFilter,
    count: true,
    page: 1,
    sort: { ...JSON.parse(String(filterInfo.safetyPlanModule.sort)) },
  };

  try {
    const response = await safetyPlanListsRPC(safetyPlanState, filtersWithCount, true, true);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchSafetyPlanScheduledListsAsync = createAsyncThunk<
  ApiResListsType<any> | null,
  string | null
>('safetyPlan/fetchSafetyPlanScheduledList', async (tabKey, thunkAPI) => {
  const {
    safetyPlan,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsSafetyPlanRootState;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const safetyPlanState = thunkAPI.getState() as OhsSafetyPlanRootState;
  // load Register ViewPreset filters
  const userTierNum = user?.tierNum ?? 0;
  const viewPreset =
    getSafetyPlanSchedListViewPreset(userTierNum) ??
    OhsSafetyPlanViewPresets.View1SafetyPlanSchedList;

  const data = safetyPlan.safetyPlanLists;
  const { currentPage } = safetyPlan;
  const viewPresetFilter = filterInfo.safetyPlanModule[viewPreset];

  const setSafetyPlanFilters: OhsSafetyPlanFilterPayload = {
    ...viewPresetFilter,
    next: currentPage > 10 ? data?.pagination.next ?? '' : undefined,
    // remove page if it is greater than 10
    ...(currentPage <= 10 && { page: currentPage }),
    sort: { ...JSON.parse(String(filterInfo.safetyPlanModule.sort)) },
    count: false,
  };

  try {
    const response = await safetyPlanListsRPC(safetyPlanState, setSafetyPlanFilters, true, false);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
