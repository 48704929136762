import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import { OhsSignature } from 'global-components/form/fields/signature/OhsSignatureModels';
import { OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsCusval } from 'global-components/form/cusvals/OhsCusvalModel';
import OhsGeoData from 'global-components/form/fields/geoLocation/OhsGeoDataModels';
import OhsCusvalDefinition from 'global-components/form/cusvals/OhsCusvalDefinition';
import { OhsHRContractorLinkItem } from 'global-components/form/fields/linkHrContractor/OhsHrContractorLinkModels';
import { OhsTime } from 'global-services/constants/OhsTimeModels';

import { BodyPart, Severity, EditLogs } from './OhsIncidentModels';

class OhsIncidentRecord {
  _id: string;

  tier?: OhsTier;

  category: string;

  categoryOther: string | null;

  complete?: boolean | null;

  createdBy?: OhsModuleCreateBy;

  dateCreated?: string;

  dateDue?: string;

  dateOccurred?: string;

  description: string;

  geoData: OhsGeoData | null;

  injuredBodyParts: BodyPart[];

  injuredPersonName: string;

  injuredPersonLinks: OhsHRContractorLinkItem[];

  injuredPersonRole: string;

  injuredPersonRoleOther: string | null;

  injuredPersonPhone: string;

  injuryDescription: string;

  location: string;

  locationOther?: string | null;

  overview: string;

  personReporting: string;

  propOrEnvDamage: boolean;

  propOrEnvDamageDescription: string;

  reference?: string;

  severity?: Severity;

  timeOccurred: OhsTime | null;

  tzDateCreated?: string;

  attachments: OhsAttachment[];

  categoryCusvals: OhsCusval[] | null;

  categoryCusvalsDefinition?: OhsCusvalDefinition;

  cusvals: OhsCusval[] | null;

  cusvalsDefinition?: OhsCusvalDefinition;

  propOrEnvDamageCusvals: OhsCusval[] | null;

  propOrEnvDamageCusvalsDefinition?: OhsCusvalDefinition;

  witnessName: string | null;

  witnessPhone: string | null;

  editComments?: EditLogs[];

  signatures?: OhsSignature[];

  confidential: boolean;

  constructor(tier?: OhsTier) {
    this._id = '';
    this.tier = tier;
    this.category = '';
    this.categoryOther = null;
    this.description = '';
    this.geoData = null;
    this.injuredBodyParts = [];
    this.injuredPersonName = '';
    this.injuredPersonLinks = [];
    this.injuredPersonRole = '';
    this.injuredPersonRoleOther = null;
    this.injuredPersonPhone = '';
    this.injuryDescription = '';
    this.location = '';
    this.locationOther = null;
    this.overview = '';
    this.personReporting = '';
    this.propOrEnvDamage = false;
    this.propOrEnvDamageDescription = '';
    this.timeOccurred = null;
    this.attachments = [];
    this.categoryCusvals = [];
    this.cusvals = [];
    this.propOrEnvDamageCusvals = [];
    this.witnessName = null;
    this.witnessPhone = null;
    this.confidential = false;
  }
}

export default OhsIncidentRecord;
