import React from 'react';

import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ErrorIcon from '@mui/icons-material/Error';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';

import { OTP_LENGTH } from 'global-services/constants/OhsConstants';

import { userMfaVerifyChallenge, userMfaVerifyCode } from '../../enrollflows/OhsMfaServices';
import { MfaAuthenticatorType } from '../../OhsMfaTypes';
import OhsUserMFAInput from '../../form/OhsUserMFAInput';
import { OhsMfaOtpStatus } from '../OhsMfaVerifyTypes';

const useStyles = makeStyles()({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: '35px',
  },
  text: {
    textAlign: 'center',
    margin: '0 0 10px 0',
  },
  link: {
    textTransform: 'inherit',
    textDecoration: 'none',
    margin: '0 15px 0 15px',
    cursor: 'pointer',
    fontSize: '12px',
  },
  linkWrap: {
    marginBottom: '12px',
    display: 'flex',
    flexDirection: 'column',
  },
  otpError: {
    color: 'red',
    margin: '10px 0 5px 0',
    fontSize: '14px',
    textAlign: 'center',
  },
  note: {
    marginBottom: '15px',
    textAlign: 'center',
  },
  header: {
    fontWeight: 'bold',
  },
  headerTitle: {
    fontWeight: 'bold',
    fontSize: '18px',
    marginBottom: '20px',
    color: '#4c4c4c',
  },
  errorIcon: {
    verticalAlign: '-4px',
    fontSize: '18px',
    marginRight: '4px',
  },
  validating: {
    fontSize: '14px',
    color: 'green',
    margin: '20px 0 5px 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  validatingIcon: {
    verticalAlign: '-4px',
    fontSize: '14px',
    marginRight: '8px',
    width: '16px !important',
    height: '16px !important',
    color: 'green',
  },
  bufferDuration: {
    display: 'flex',
    justifyContent: 'center',
  },
  verifyActions: {
    bottom: '0',
    left: '0',
    position: 'absolute',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: 'center',
    background: '#fafafa',
    borderTop: '1px solid #eaeaea',
    width: '100%',
    button: {
      fontWeight: 'bold',
    },
  },
  addspace: {
    marginTop: '20px',
  },
});
interface Props {
  mfaInfo?: MfaAuthenticatorType;
  setOpenHelp: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (methodId?: string) => void;
}
function OhsMfaVerifyOTP(props: Props) {
  const { classes } = useStyles();
  const [mfaInfo, setMFAInfo] = React.useState<MfaAuthenticatorType>();
  const [oTPvalidStatus, setOTPvalidStatus] = React.useState<OhsMfaOtpStatus>(OhsMfaOtpStatus.Idle);
  const [currentOTP, setOTP] = React.useState('');
  const [bufferDuration, setBufferDuration] = React.useState(false);

  const handleBufferDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBufferDuration(event.target.checked);
  };

  React.useEffect(() => {
    if (props.mfaInfo) setMFAInfo(props.mfaInfo);
  }, [props.mfaInfo]);

  const resendOTPcode = async () => {
    setOTPvalidStatus(OhsMfaOtpStatus.ResendingCode);
    const enrollUserReq = await userMfaVerifyChallenge(mfaInfo?.id ?? '');
    if (enrollUserReq) setOTPvalidStatus(OhsMfaOtpStatus.OtpResent);
  };

  const verifyOtp = async () => {
    setOTPvalidStatus(OhsMfaOtpStatus.OtpValidating);
    const enrollUserReq = await userMfaVerifyCode(currentOTP, bufferDuration);
    const isValid = enrollUserReq?.success;
    setOTPvalidStatus(isValid ? OhsMfaOtpStatus.Valid : OhsMfaOtpStatus.OtpInvalid);
    if (!isValid) setOTP('');
    if (isValid) props.onSuccess(mfaInfo?.id);
  };

  const getVerifyMsg = (oTPStatus: OhsMfaOtpStatus) => {
    switch (oTPStatus) {
      case OhsMfaOtpStatus.OtpValidating:
        return (
          <>
            <CircularProgress className={classes.validatingIcon} />
            Verifiying authentication code ...
          </>
        );
      case OhsMfaOtpStatus.OtpInvalid:
        return (
          <>
            <ErrorIcon className={classes.errorIcon} />
            Incorrect verification code.
          </>
        );

      case OhsMfaOtpStatus.ResendingCode:
        return (
          <>
            <CircularProgress className={classes.validatingIcon} />
            Resending authentication code ...
          </>
        );
      case OhsMfaOtpStatus.OtpResent:
        return <>New authentication code has been resent.</>;
      default:
        return '';
    }
  };

  const processClassName = clsx({
    [classes.link]: true,
    [classes.validating]:
      oTPvalidStatus === 'otpResent' ||
      oTPvalidStatus === 'otpValidating' ||
      oTPvalidStatus === 'resendingcode',
    [classes.otpError]: oTPvalidStatus === 'otpInvalid',
  });

  const mfaMethodText: any =
    mfaInfo && mfaInfo.method === 'app'
      ? ' Authentication App.'
      : ` ${mfaInfo?.method}: ${mfaInfo?.name}.`;

  const appMethod = mfaInfo?.method ?? '';

  React.useEffect(() => {
    if (currentOTP.length === OTP_LENGTH) {
      verifyOtp();
    }
  }, [currentOTP]);

  return (
    <Box className={classes.container}>
      <Typography variant="body1" gutterBottom className={classes.note}>
        {mfaInfo?.method === 'app'
          ? 'Enter the 6-digit authentication code generated by your app.'
          : `Please enter the authentication code that we sent to your ${mfaMethodText}`}
      </Typography>

      <OhsUserMFAInput setOTP={setOTP} currentOTP={currentOTP} />

      {oTPvalidStatus !== OhsMfaOtpStatus.Idle && (
        <Typography variant="body1" gutterBottom className={processClassName}>
          {getVerifyMsg(oTPvalidStatus)}
        </Typography>
      )}
      <Box mb={2} className={classes.linkWrap}>
        {oTPvalidStatus !== 'otpValidating' && mfaInfo?.method !== 'app' && (
          <Button
            className={clsx({
              [classes.link]: true,
              [classes.addspace]: appMethod === 'app',
            })}
            onClick={() => resendOTPcode()}
          >
            Resend Code
          </Button>
        )}
        <Button
          className={clsx({
            [classes.link]: true,
          })}
          onClick={() => props.setOpenHelp(true)}
        >
          Not Receiving code?
        </Button>
      </Box>

      <Box className={classes.verifyActions}>
        <FormControlLabel
          className={classes.bufferDuration}
          control={
            <Checkbox onClick={(e: any) => handleBufferDuration(e)} checked={bufferDuration} />
          }
          label="Remember me for 30 days."
        />
      </Box>
    </Box>
  );
}

OhsMfaVerifyOTP.defaultProps = {
  mfaInfo: null,
};
export default OhsMfaVerifyOTP;
