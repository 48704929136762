export const OhsEmail = 'email';

export const OhsMultiUser = 'multiUser';

export const OhsTimeStamp = 'timeStamp';

export const OhsMultiUserOptions = 'options';

export const OhsSessionToken = 'x-safetychampion-token';

export const OhsMultiUserSessionToken = 'x-safetychampion-multiuser-options-token';

export const OhsUploadAttachmentDetailsHeader = 'x-safetychampion-tus-response';

export const OhsLinkAccessAttachmentsDownloadHeader =
  'x-safetychampion-linkaccess-attachments-download-token';

export const OhsLinkAccessAttachmentsUploadHeader =
  'x-safetychampion-linkaccess-attachments-upload-token';

export const OhsUserStoredSessionsKey = 'all-users-stored-sessions';

export const CookiesExpiresDays = 365;

export const OhsPriorActiveSessionToken = 'prior-active-session-token';
