import { ModuleType } from 'global-services/constants/OhsObject';

import OhsModulePermissions from '../OhsModulePermissions';
import { getConfigValue, setPermissions } from '../OhsConfigServices';
import OhsFileBoxConfig from '../filebox/OhsFileBoxConfig';

class OhsActionConfig {
  TITLE: string;

  TITLE_EXTRA: {
    labels: {
      workerGroupDashboard: string;
    };
  };

  PERMISSIONS: OhsModulePermissions;

  MORPH_PERMISSIONS: OhsModulePermissions;

  TYPE = ModuleType.Action;

  CREATE_ACTION_CATEGORY_LIST: any[];

  CREATE_ACTION_CUSVALS: {
    type: string;
    _id: string;
  };

  TASK_SIGNOFF_ACTION_CUSVALS: {
    type: string;
    _id: string;
  };

  TASK_SIGNOFF_CONTROL_LEVEL_LIST: any[];

  TASK_SIGNOFF_HAZARD_CATEGORY_LIST: any[];

  TASK_SIGNOFF_SEVERTIY_LIST: any[];

  RISK_MATRIX_IMAGE_LINK: string;

  PERSON_RESPONSIBLE_LOOKUP: any[];

  fileboxConfig?: OhsFileBoxConfig;

  constructor(config: any, fileboxConfig?: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.TITLE_EXTRA = getConfigValue(config, 'TITLE_EXTRA');
    this.PERMISSIONS = new OhsModulePermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    this.CREATE_ACTION_CATEGORY_LIST = getConfigValue(config, 'CREATE_ACTION_CATEGORY_LIST');
    this.TASK_SIGNOFF_ACTION_CUSVALS = getConfigValue(config, 'TASK_SIGNOFF_ACTION_CUSVALS');
    this.CREATE_ACTION_CUSVALS = getConfigValue(config, 'CREATE_ACTION_CUSVALS');
    this.RISK_MATRIX_IMAGE_LINK = getConfigValue(config, 'RISK_MATRIX_IMAGE_LINK');
    this.PERSON_RESPONSIBLE_LOOKUP = getConfigValue(config, 'PERSON_RESPONSIBLE_LOOKUP');
    this.TASK_SIGNOFF_CONTROL_LEVEL_LIST = getConfigValue(
      config,
      'TASK_SIGNOFF_CONTROL_LEVEL_LIST'
    );
    this.TASK_SIGNOFF_HAZARD_CATEGORY_LIST = getConfigValue(
      config,
      'TASK_SIGNOFF_HAZARD_CATEGORY_LIST'
    );
    this.TASK_SIGNOFF_SEVERTIY_LIST = getConfigValue(config, 'TASK_SIGNOFF_SEVERTIY_LIST');
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(this.MORPH_PERMISSIONS, getConfigValue(config, 'MORPH_PERMISSIONS'));

    if (fileboxConfig) {
      this.fileboxConfig = new OhsFileBoxConfig(fileboxConfig);
    }
  }
}

export default OhsActionConfig;
