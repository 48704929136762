import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import { getOhsActionsLists } from 'action/OhsActionSlice';
import OhsMultipleSelectField from 'global-components/form/fields/OhsMultipleSelectField';
import { TierType } from 'global-services/constants/OhsObject';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';
import { OhsActionModuleViewPresets } from '../OhsAssetFilterModels';
import OhsCheckboxFilter from '../filterfields/OhsCheckboxFilter';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}
function OhsActionFilters(props: Props) {
  const user = OhsUserLocalServices.getLocalOhsUser();
  const moduleConf = user?.configs.action?.CREATE_ACTION_CATEGORY_LIST || [];
  const severitiesList = user?.configs.action?.TASK_SIGNOFF_SEVERTIY_LIST || [];

  const { classes } = useStyles();
  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const actionsReport = useAppSelector(getOhsActionsLists);
  const viewPreset = actionsReport.currentViewPreset;
  return (
    <Box className={classes.filterContent}>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id={`actionModule.${viewPreset}.sort`}
          required
          options={filter.actionModule.tableSortKeyLists?.sortColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {viewPreset === OhsActionModuleViewPresets.view_1 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter
            id={`actionModule.${viewPreset}.workplaces`}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsMultipleSelectField
          title="Generated By"
          id={`actionModule.${viewPreset}.categories`}
          required={false}
          options={
            moduleConf
              ?.filter((item: any) => {
                if (user?.tier.type === TierType.T5)
                  return !!item.options.find(({ opt }: any) => {
                    return opt === 'WORKER_GROUP_ACCESS';
                  });
                return true;
              })
              .map((item: any) => {
                return { name: item.title, value: item.title };
              }) ?? []
          }
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsMultipleSelectField
          title="Risk"
          id={`actionModule.${viewPreset}.severities`}
          required={false}
          options={
            severitiesList.map((item: any) => {
              return { name: item, value: item };
            }) ?? []
          }
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {user?.tier.type === TierType.T3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`actionModule.${viewPreset}.skipOrgRecords`}
            label="Skip Org Records"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
    </Box>
  );
}

export default OhsActionFilters;
