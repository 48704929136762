import { OhsUser } from 'user/OhsUser';
import OhsPrivacyPermissions from 'user/configurations/OhsPrivacyPermissions';

export const ohsHasHrPrivacyAccess = (user: OhsUser | undefined) => {
  return user && user?.configs?.hr?.PRIVACY?.details
    ? Object.values(user.configs.hr?.PRIVACY?.details).every((a) => a)
    : false;
};

export const ohsParseHrPrivacy = (user: OhsUser | undefined, response: any) => {
  if (user && user?.configs?.hr?.PRIVACY?.details) {
    Object.entries(user.configs.hr?.PRIVACY?.details).forEach(([key, value]) => {
      if (key === 'attachments' && !value) {
        response.attachments = null;
      } else {
        response[key] = value ? response[key] : '******';
      }
    });
  }
};

export const ohsHasTrainingPrivacyAccess = (user: OhsUser | undefined) => {
  const trainingDetails = user?.configs?.hr?.PRIVACY?.details;
  return (trainingDetails?.position && trainingDetails.referenceId) ?? false;
};

export const ohsParseTrainingPrivacy = (user: OhsUser | undefined, response: any) => {
  const trainingDetails = user?.configs?.hr?.PRIVACY?.details;

  if (trainingDetails) {
    if (!trainingDetails.position) response.owner.position = '******';
    if (!trainingDetails.referenceId) response.owner.referenceId = '******';
  }
};
export const ohsParseValueWithKey = (
  key: keyof OhsPrivacyPermissions,
  privacy?: OhsPrivacyPermissions
): string | null => {
  if (privacy) {
    return privacy[key] ? null : '******';
  }
  return null;
};
