import Cookies from 'js-cookie';

import {
  CookiesExpiresDays,
  OhsMultiUserSessionToken,
  OhsPriorActiveSessionToken,
  OhsSessionToken,
} from 'global-services/constants/OhsStorageNames';
import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';

const getOptionToken = (): string => {
  const optionToken = Cookies.get(OhsMultiUserSessionToken);
  return optionToken ?? '';
};

const getUserToken = (): string => {
  const userToken = Cookies.get(OhsSessionToken);
  return userToken ?? '';
};
const setUserToken = (token: string) => {
  Cookies.set(OhsSessionToken, token, {
    expires: CookiesExpiresDays,
    path: '/',
  });
};
const setPriorActiveToken = () => {
  const userToken = getUserToken();
  if (userToken) {
    setOhsLocalStorage(OhsPriorActiveSessionToken, userToken);
  }
};
const getPriorActiveToken = (): string => {
  const token = getOhsLocalStorage(OhsPriorActiveSessionToken);
  return token ?? '';
};
const OhsSessionServices = {
  getOptionToken,
  getUserToken,
  setPriorActiveToken,
  getPriorActiveToken,
  setUserToken,
};
export default OhsSessionServices;
