/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import { Link, Toolbar } from '@mui/material';

import OhsFreemiumAlertModal from 'freemium/OhsFreemiumAlertModal';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

export default function OhsFreemiumTopbar() {
  const [freemiumPaidMsgOpen, setFreemiumPaidMsgOpen] = React.useState(false);
  const openUpgradeModal = () => {
    setFreemiumPaidMsgOpen(true);
  };
  const user = OhsUserLocalServices.getLocalOhsUser();
  return (
    <>
      <Toolbar
        style={{
          minHeight: '40px',
          backgroundImage: 'linear-gradient(to right, #f47431, #fcd808)',
        }}
      >
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            color: 'white',
            margin: 'auto',
            fontWeight: 'bold',
            fontSize: '1rem',
          }}
        >
          {' You’re using Safety Champion Light. '}
          {user?.tierNum === 3 && (
            <span>
              <Link
                onClick={openUpgradeModal}
                style={{
                  color: 'white',
                  fontWeight: 'bold',
                  textDecoration: 'underline',
                }}
              >
                Upgrade
              </Link>
              {' today to experience more features and modules. '}
            </span>
          )}
        </div>
      </Toolbar>
      <OhsFreemiumAlertModal
        freemiumPaidMsgOpen={freemiumPaidMsgOpen}
        setFreemiumPaidMsgOpen={setFreemiumPaidMsgOpen}
      />
    </>
  );
}
