import _ from 'lodash';

import { OhsModuleCategory } from './OhsModuleCategory';

export const getConfigValue = (config: any, valueName: string): any => {
  if (config) {
    const valueObj = _.find(config.values, {
      name: valueName,
    });
    if (valueObj && valueObj.value) {
      return valueObj.value;
    }
  }
  return null;
};

export const setPermissions = (permissionObject: any, permissionConfig: Array<string>) => {
  if (permissionConfig) {
    if (permissionConfig.includes('*')) {
      Object.keys(permissionObject).forEach((key) => {
        permissionObject[key] = true;
      });
    } else {
      permissionConfig.forEach((key) => {
        permissionObject[key] = true;
      });
    }
  }
  return permissionObject;
};
export const setPrivacyPermissions = (permissionObject: any, permissionConfig: any) => {
  if (permissionConfig) {
    if (permissionConfig?.details) {
      Object.entries(permissionConfig?.details).forEach(([key, value]) => {
        permissionObject.details[key] = value;
      });
    }
    permissionObject.linkedIncidents = permissionConfig.linkedIncidents;
    permissionObject.linkedCorrespondences = permissionConfig.linkedCorrespondences;
  }
  return permissionObject;
};

export const setCategory = (config: any, valueName: string) => {
  const categoryListObj = getConfigValue(config, valueName);
  let newValue: OhsModuleCategory[] = [];

  if (categoryListObj) {
    Object.keys(categoryListObj).forEach((item: string) => {
      const newItem = {
        category: item,
        subcategory: categoryListObj[item] || [],
      };
      newValue = [...newValue, newItem];
    });
  }
  return newValue;
};

export const setNotificationEnabled = (permissionObject: any, permissionConfig: any) => {
  if (permissionConfig) {
    Object.entries(permissionConfig).forEach(([key, value]) => {
      permissionObject[key] = value;
    });
  }
  return permissionObject;
};
