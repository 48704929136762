import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';

import { contractorModuleReducer } from 'contractor/OhsContractorSlice';
import { dashboardReducer } from 'dashboard/OhsDashboardSlice';
import { globalfilterReducer } from 'dashboard/topFilter/OhsTopFilterSlice';
import { safetyPlanModuleReducer } from 'safety-plan/store/OhsSafetyPlanSlice';
import { inspectionModuleReducer } from 'inspection/OhsInspectionSlice';
import { humanResourceModuleReducer } from 'human-resource/OhsHumanResourceSlice';
import { documentsModuleReducer } from 'documents/OhsDocumentsSlice';
import { correspondenceModuleReducer } from 'correspondence/OhsCorrespondenceSlice';
import { correspondenceExchangeModuleReducer } from 'correspondence/OhsCorrespondenceExchangeSlice';
import { actionModuleReducer } from 'action/OhsActionSlice';
import { hrTrainingModuleReducer } from 'human-resource/OhsHumanResourceTrainingSlice';
import { hrCorrespondenceModuleReducer } from 'human-resource/OhsHrCorrespondenceSlice';
import { incidentModuleReducer } from 'incident/store/OhsIncidentSlice';
import { taskModuleReducer } from 'task/store/OhsTaskSlice';
import { taskAssignReducer } from 'task/store/OhsTaskAssignSlice';
import { visitorModuleReducer } from 'visitor/store/OhsVisitorSlice';
import { corporateRiskModuleReducer } from 'corporate-risk/OhsCorporateRiskSlice';
import { reviewPlanModuleReducer } from 'review-plan/OhsReviewPlanSlice';
import { chemicalModuleReducer } from 'chemical/store/OhsChemicalSlice';
import { globalSearchReducer } from 'search/store/OhsSearchSlice';
import { DashboardBannerReducer } from 'dashboard/banner/OhsDashboardBannerSlice';
import { assetModuleReducer } from 'assets/OhsAssetSlice';
import { noticeboardModuleReducer } from 'notice-board/store/OhsNoticeBoardSlice';
import { mobileAppAdminModuleReducer } from 'mobile-app-admin/store/OhsMobileAppAdminSlice';
import { onboardingModuleReducer } from 'freemium/list/OhsOnboardingListSlice';
import { xsiModuleReducer } from 'xsi/store/OhsXsiSlice';
import { contractorCorrespondenceModuleReducer } from 'contractor/OhsContractorCorrespondenceSlice';
import { tier1CopyDialogReducer } from 'admin2/copy/OhsTier1CopyDialogSlice';

export const rootReducer = combineReducers({
  globalSearch: globalSearchReducer,
  contractor: contractorModuleReducer,
  contractorCorrespondences: contractorCorrespondenceModuleReducer,
  dashboard: dashboardReducer,
  globalfilter: globalfilterReducer,
  tasks: taskModuleReducer,
  humanResources: humanResourceModuleReducer,
  correspondence: correspondenceModuleReducer,
  correspondenceExchange: correspondenceExchangeModuleReducer,
  hrTrainings: hrTrainingModuleReducer,
  hrCorrespondences: hrCorrespondenceModuleReducer,
  documents: documentsModuleReducer,
  chemical: chemicalModuleReducer,
  taskAssign: taskAssignReducer,
  safetyPlan: safetyPlanModuleReducer,
  inspection: inspectionModuleReducer,
  actionsReport: actionModuleReducer,
  incident: incidentModuleReducer,
  visitor: visitorModuleReducer,
  corporateRisk: corporateRiskModuleReducer,
  reviewplans: reviewPlanModuleReducer,
  dashboardBanner: DashboardBannerReducer,
  asset: assetModuleReducer,
  noticeBoard: noticeboardModuleReducer,
  mobileAppAdmin: mobileAppAdminModuleReducer,
  onboarding: onboardingModuleReducer,
  xsi: xsiModuleReducer,
  OhsTier1CopyDialogReducer: tier1CopyDialogReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
    preloadedState,
  });
};

export const store = setupStore();
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
