import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';
import OhsLoginServices from 'login/OhsLoginServices';

export const morphInto = (tier4Id: string) => {
  return OhsApiRequest(RequestType.Admin, ModuleType.MorphUser, OhsApiRequestName.MorphInto, {
    target: {
      type: 'core.tier.T4',
      _id: tier4Id,
    },
  }).then((data) => {
    OhsLoginServices.removeGlobalFilterAndUserDate();
    return data;
  });
};

export const morphToTier2 = () => {
  return OhsApiRequest(RequestType.Admin, ModuleType.MorphUser, OhsApiRequestName.MorphInto, {
    target: {
      type: 'core.tier.T2',
    },
  }).then((data) => {
    OhsLoginServices.removeGlobalFilterAndUserDate();
    return data;
  });
};

export const unMorphUser = () => {
  return OhsApiRequest(RequestType.Admin, ModuleType.MorphUser, OhsApiRequestName.UnMorph, {}).then(
    (data) => {
      OhsLoginServices.removeGlobalFilterAndUserDate();
      return data;
    }
  );
};

export const morphUpTier2 = () => {};
