/* eslint-disable */

// import { ModuleType } from '../OhsObject';
import OhsRecordFieldType from './OhsRecordFieldType';

export const OhsDateIssuedRecordField = new OhsRecordFieldType('dateIssued', 'Issue Date');

export const OhsTitleRecordField = new OhsRecordFieldType('title', 'Title');
export const OhsNameRecordField = new OhsRecordFieldType('name', 'Name');
export const OhsNameOtherRecordField = new OhsRecordFieldType('nameOther', 'Other Name');
export const OhsDateRecordField = new OhsRecordFieldType('date', 'Date');

export const OhsAttachmentsRecordField = new OhsRecordFieldType('attachments', 'Attachment(s)');

// export const OhsSignOffSignatureRecordField = new OhsRecordFieldType('signatures', 'Signatures(s)');

export const OhsCompleteRecordField = new OhsRecordFieldType('complete', 'Completed');

export const OhsPreviousAttachmentsRecordField = new OhsRecordFieldType(
  'previousAttachments',
  `Previous ${OhsAttachmentsRecordField.name}`
);

// export const OhsActionLinksRecordField = new OhsRecordFieldType(
//   'actionLinks',
//   `${user?.getModuleName(ModuleType.Action)}`
// );

export const OhsDateCompletedRecordField = new OhsRecordFieldType(
  'dateCompleted',
  'Completed Date'
);

export const OhsSignatureRecordField = new OhsRecordFieldType('control.signatures', 'Signature');

export const OhsSignOffSignatureRecordField = new OhsRecordFieldType('signatures', 'Signatures(s)');

export const OhsWorkplaceRecordField = new OhsRecordFieldType('tier.name', 'Workplace');

export const OhsAllocatedToRecordField = new OhsRecordFieldType('tier.name', 'Allocated to');

export const OhsModuleRecordField = new OhsRecordFieldType('for.type', 'Module');

export const OhsDateDueRecordField = new OhsRecordFieldType('dateDue', 'Due Date');

export const OhsArchivedRecordField = new OhsRecordFieldType('archived', 'Archived');

export const OhsCopiedRecordField = new OhsRecordFieldType('copied', 'Copied');

// export const OhsDateSignedOffRecordField = new OhsRecordFieldType('dateSignedoff', 'Sign-off Date');

// export const OhsSignedOffByNameRecordField = new OhsRecordFieldType(
//   'signedoffBy.name',
//   'Sign-off By'
// );

// export const OhsNameRecordField = new OhsRecordFieldType('name', 'Title');

export const OhsDateCreatedRecordField = new OhsRecordFieldType('dateCreated', 'Date Created');

export const OhsDescriptionRecordField = new OhsRecordFieldType('description', 'Description');

export const OhsCommentRecordField = new OhsRecordFieldType('comment', 'Comments');

export const OhsCusvalsRecordField = new OhsRecordFieldType('cusvals', 'Custom Details');

export const OhsCategoryCusvalsRecordField = new OhsRecordFieldType(
  'categoryCusvals',
  'Custom Details'
);
export const OhsSubCategoryCusvalsRecordField = new OhsRecordFieldType(
  'subcategoryCusvals',
  'Sub Custom Details'
);

export const OhsReferenceIdRecordField = new OhsRecordFieldType('referenceId', 'Reference');

export const OhsReferenceRecordField = new OhsRecordFieldType('reference', 'Ref No.');

export const OhsCategoryRecordField = new OhsRecordFieldType('category', 'Classification');

export const OhsCategoryOtherRecordField = new OhsRecordFieldType(
  'categoryOther',
  'Classification Other'
);

export const OhsSubcategoryRecordField = new OhsRecordFieldType('subcategory', 'Subclassification');

export const OhsSubcategoryOtherRecordField = new OhsRecordFieldType(
  'subcategoryOther',
  'Subclassification Other'
);

export const OhsReviewNotesRecordField = new OhsRecordFieldType('reviewNotes', 'Sign-off Notes');

export const OhsConfidentialRecordField = new OhsRecordFieldType('confidential', 'Confidential');

export const OhsEnableNotifyInjuryWork = new OhsRecordFieldType(
  'enableNotification',
  'Notify Injured Person'
);

export const OhsEnableNotifyManager = new OhsRecordFieldType(
  'enableManagerNotification',
  'Notify Manager'
);

export const OhsDateSignedOffRecordField = new OhsRecordFieldType('dateSignedoff', 'Sign-off Date');

export const OhsSignedOffByRecordField = new OhsRecordFieldType('signedoffBy.name', 'Sign-off By');

export const OhsSignedOffByNameRecordField = new OhsRecordFieldType(
  'signedoffBy.name',
  'Signed Off By (Person)'
);

export const OhsSignedOffByEmailRecordField = new OhsRecordFieldType(
  'signedoffBy.email',
  'Signed Off By (Email)'
);

export const OhsDetailRecordField = new OhsRecordFieldType('description', 'Detail');

export const OhsSignOffNotesRecordField = new OhsRecordFieldType(
  'completionNotes',
  'Sign-off Notes'
);
export const OhsStatusRecordField = new OhsRecordFieldType('status', 'Status');

export const OhsAllocationRecordField = new OhsRecordFieldType('allocation', 'Allocation');

export const OhsOverviewRecordField = new OhsRecordFieldType('overview', 'Brief Overview');

export const OhsSeverityRecordField = new OhsRecordFieldType('severity', 'Risk');

export const OhsTemplateRecordField = new OhsRecordFieldType('template', 'Template');

export const OhsRecipientRecordField = new OhsRecordFieldType('recipient', 'Recipient');

export const OhsLocationsRecordField = new OhsRecordFieldType('locations', 'Locations');

export const OhsMainLocationRecordField = new OhsRecordFieldType('main', 'Main Location');

export const OhsSubLocationRecordField = new OhsRecordFieldType('sub', 'Main Location');

export const OhsGeoDataRecordField = new OhsRecordFieldType('geoData', 'Geo Data');

export const OhsLatitudeRecordField = new OhsRecordFieldType('latitude', 'Latitude');

export const OhsLongitudeRecordField = new OhsRecordFieldType('longitude', 'Longitude');

export const OhsGeoFenceRadiusRecordField = new OhsRecordFieldType(
  'geofenceRadius',
  'Geo-fencing radius'
);

export const OhsOrganisationRecordField = new OhsRecordFieldType('organisation', 'Organisation');

export const OhsEmployeesRecordField = new OhsRecordFieldType('employees', 'Employees');

export const OhsIndustryRecordField = new OhsRecordFieldType('industry', 'Industry');

export const OhsLocationRecordField = new OhsRecordFieldType('location', 'Location');
