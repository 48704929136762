import React, { useCallback, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import { useAppDispatch } from 'app/hooks';
import { getLocalStorage, setLocalStorage, removeLocalStorage } from 'global-services/OhsDataParse';
import getMixpanelTraker from 'global-services/mixpanel/OhsMixpanel';
import { dateDiffOnly } from 'global-services/utils/OhsDateServices';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import {
  localStorageKey,
  RemovedBannerCookieRecordType,
  validUrlsToShow,
} from '../OhsDashboardBannerConstants';
import { getDashboardBannerList } from '../OhsDashboardBannerServices';
import { OhsDashboardBannerRecord } from '../OhsDashboardBannerModels';
import { BannerCarousel } from '../register/utils/OhsDashboardBannerStyledComponents';
import DashboardBannerItem from './OhsDashboardBannerItem';
import { setIsShowingBanner } from '../../OhsDashboardSlice';

const MaxEndDate = 7;

const getUpdatedRemovedBanner = ({
  userEmail,
  bannerList,
}: {
  userEmail: string;
  bannerList: OhsDashboardBannerRecord[];
}) => {
  const removedBannerCookieList = (getLocalStorage(localStorageKey) ||
    []) as RemovedBannerCookieRecordType[];

  // update all local endDate if we found it in the remote list.
  _.forEach(removedBannerCookieList, (localRecord) => {
    const remoteRecord = _.find(bannerList, { _id: localRecord._id });
    if (remoteRecord) {
      localRecord.endDate =
        dateDiffOnly(remoteRecord.endDate) <= MaxEndDate
          ? remoteRecord.endDate
          : moment(new Date()).add(MaxEndDate, 'days').format('YYYY-MM-DD');
    }
  });

  // remove expired record
  const newRemovedBannerCookieList = removedBannerCookieList.filter(
    (item) => dateDiffOnly(item.endDate) > -1
  );

  if (newRemovedBannerCookieList.length > 0) {
    setLocalStorage(localStorageKey, newRemovedBannerCookieList);
  } else {
    removeLocalStorage(localStorageKey);
  }

  return _.filter(newRemovedBannerCookieList, (item: any) => item.userEmail === userEmail);
};

export default function OhsDashboardBanner() {
  const user = OhsUserLocalServices.getLocalOhsUser();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const [showBanner, setShowBanner] = useState(false);
  const [dashboardBannerList, setDashboardBannerList] = useState<Array<OhsDashboardBannerRecord>>(
    []
  );

  const userId = _.get(user, '_id', '');
  const userEmail = _.get(user, 'email', '');
  const userOrgId = _.get(user, ['org', '_id'], '');

  useEffect(() => {
    if (user?.tierNum !== 1 && _.includes(validUrlsToShow, location.pathname)) {
      setShowBanner(true);
    }

    return () => {
      setShowBanner(false);
    };
  }, []);

  // On load of the page,
  // we get the list from API THEN filtered out
  // depending on list from `removedBanner` saved in cookies/localStorage
  useEffect(() => {
    if (showBanner) {
      getDashboardBannerList(undefined, true).then(({ items: dataResponse }: any) => {
        const removedBannerCookieList = userEmail
          ? getUpdatedRemovedBanner({ userEmail, bannerList: dataResponse })
          : [];
        const dashboardBannerFilteredList = _.filter(dataResponse, (item) => {
          return _.findIndex(removedBannerCookieList, { _id: item._id }) === -1;
        });
        if (dashboardBannerFilteredList && dashboardBannerFilteredList.length > 0) {
          dispatch(setIsShowingBanner(true));
        } else {
          dispatch(setIsShowingBanner(false));
        }
        setDashboardBannerList(dashboardBannerFilteredList);
      });
    }
  }, [userEmail, showBanner]);

  // Filter or remove selected banner right away
  // after saving its ID on localStorage > `removedBanner`
  const removeBannerOnClick = useCallback(
    ({ _id, endDate }: any) => {
      const mixpanel = getMixpanelTraker();
      try {
        const banner = _.find(dashboardBannerList, { _id });
        mixpanel?.track('Close Banner', {
          BannerMessage: banner?.content.message,
          BannerId: banner?._id,
          BannerStartDate: banner?.startDate,
          BannerEndDate: banner?.endDate,
        });
      } catch (e) {
        console.error(e);
      }
      const removedBannerListCookie = getLocalStorage(localStorageKey) || [];

      // Maximum endDate value on localStorage is MaxEndDate days.
      const validateEndDate =
        dateDiffOnly(endDate) > MaxEndDate
          ? moment(new Date()).add(MaxEndDate, 'days').format('YYYY-MM-DD')
          : endDate;
      const newRemovedBannerValue = [
        ...removedBannerListCookie,
        {
          _id,
          endDate: validateEndDate,
          userId,
          userEmail,
          userOrgId,
        },
      ];

      const dashboardBannerFilteredList = _.filter(dashboardBannerList, (item) => _id !== item._id);

      if (dashboardBannerFilteredList && dashboardBannerFilteredList.length > 0) {
        dispatch(setIsShowingBanner(true));
      } else {
        dispatch(setIsShowingBanner(false));
      }
      setLocalStorage(localStorageKey, newRemovedBannerValue);
      setDashboardBannerList(dashboardBannerFilteredList);
    },
    [dashboardBannerList, userEmail, userOrgId, userId]
  );

  // Render a slider of banner list(s)
  return showBanner && dashboardBannerList.length > 0 ? (
    <BannerCarousel itemCount={dashboardBannerList.length} slidesToShow={1}>
      {_.map(dashboardBannerList, (bannerItem) => (
        <DashboardBannerItem
          key={bannerItem._id}
          {...bannerItem.content}
          removeBannerOnClick={() =>
            removeBannerOnClick({ ..._.pick(bannerItem, ['_id', 'endDate']) })
          }
        />
      ))}
    </BannerCarousel>
  ) : (
    ''
  );
}
