import Cookies from 'js-cookie';
import _ from 'lodash';

import {
  OhsSessionToken,
  OhsMultiUserSessionToken,
} from 'global-services/constants/OhsStorageNames';
import { OhsLocalStoragePrefix, TusUploadLocalStoragePrefix } from 'global-services/OhsDataParse';

const AngularLocalStoragePrefix = 'ngStorage-';
const resetUserData = () => {
  Cookies.remove(OhsSessionToken);
  Cookies.remove(OhsMultiUserSessionToken);
  _.forEach(Object.entries(localStorage), (value: any) => {
    const itemName = value[0] as string;

    if (
      itemName &&
      (itemName.indexOf(AngularLocalStoragePrefix) > -1 ||
        itemName.indexOf(OhsLocalStoragePrefix) > -1 ||
        itemName.indexOf(TusUploadLocalStoragePrefix) > -1)
    ) {
      localStorage.removeItem(itemName);
    }
  });
};
const removeAllLocalStorage = () => {
  _.forEach(Object.entries(localStorage), (value: any) => {
    const itemName = value[0] as string;
    localStorage.removeItem(itemName);
  });
};
const OhsUserDataServices = { resetUserData, removeAllLocalStorage };
export default OhsUserDataServices;
