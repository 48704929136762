/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
import React from 'react';

// import _ from 'lodash';

import { FieldValues } from 'react-hook-form';
import { Item } from 'semantic-ui-react';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsMultipleSelectField from 'global-components/form/fields/OhsMultipleSelectField';
import OhsCheckboxField from 'global-components/form/fields/OhsCheckboxField';

import { getGlobalFilter } from '../OhsTopFilterSlice';

interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
  fieldid: string;
  label: string | React.ReactNode;
}
export default function OhsCheckboxFilter(props: Props) {
  const filter = useAppSelector(getGlobalFilter);

  const [fieldvalue]: [boolean] = props.ohsFormSettings.useFormMethods.watch([props.fieldid]);

  return (
    <div>
      <FormControlLabel
        control={
          <Checkbox
            checked={fieldvalue}
            {...props.ohsFormSettings.useFormMethods.register(props.fieldid, {
              required: false,
            })}
            sx={{ padding: '0 9px' }}
          />
        }
        label={props.label}
      />
    </div>
  );
}
