import { ModuleType } from 'global-services/constants/OhsObject';
import {
  OhsDateCreatedRecordField,
  OhsEmployeesRecordField,
  OhsOrganisationRecordField,
} from 'global-services/constants/record-field/OhsGlobalRecordFiled';

export type OhsExcelFilterType = 'multiple' | 'dateRange' | 'amount';
export interface OhsExcelFilter {
  type: OhsExcelFilterType;
  options?: Array<string>;
  value: Array<string>;
  maxAmount?: number;
  minAmount?: number;
}
export interface OhsExcelConfig {
  fn: string;
  fd: string;
  filter?: OhsExcelFilter;
  selected: boolean;
}
export const ModuleTypeExcelConfigNameMap = new Map([
  [ModuleType.SafetyPlan, 'OhsSafetyPlanExcelConfigList'],
]);

export const OhsFreemiumConfigList: Array<OhsExcelConfig> = [
  {
    fn: 'orgName',
    fd: `${OhsOrganisationRecordField.name} Name`,
    selected: true,
  },
  {
    fn: 'userName',
    fd: 'User Name',
    selected: true,
  },
  {
    fn: 'complete',
    fd: 'Complete',
    selected: true,
  },
  {
    fn: 'location',
    fd: 'Location',
    selected: true,
  },
  {
    fn: 'industry',
    fd: 'Industry',
    selected: true,
  },
  {
    fn: 'dateCompleted',
    fd: 'Date Completed',
    filter: { type: 'dateRange', value: [] },
    selected: true,
  },
  {
    fn: 'dateCreated',
    fd: OhsDateCreatedRecordField.name,
    filter: { type: 'dateRange', value: [] },
    selected: true,
  },
  {
    fn: 'numEmployees',
    fd: `Number of ${OhsEmployeesRecordField.name}`,
    filter: { type: 'amount', value: [] },
    selected: true,
  },
];
