import { useEffect } from 'react';

import { generatePath, useLocation, useNavigate } from 'react-router-dom';

import { ModuleUrl } from 'global-services/constants/OhsRoutes';
import { isAuthenticated } from 'global-services/OhsAuthenticate';
import { fetchCurrentUserSettings } from 'user/OhsUserServices';

import OhsLoginServices from './OhsLoginServices';

export default function OhsRootHome() {
  const location = useLocation();
  const navigate = useNavigate();

  const forwardBaseOnTier = async () => {
    const withHashUrl = location.hash.includes('#') && location.pathname === '/';
    if (withHashUrl) {
      const newUrl = location.hash.replace('#', '');
      const url = generatePath(newUrl);
      navigate(url, { replace: true });
      return;
    }

    const currentUser = await fetchCurrentUserSettings();
    if (currentUser?._id) {
      const homeUrl = OhsLoginServices.getHomeUrl(currentUser.tier);
      if (homeUrl) navigate(homeUrl);
    } else navigate(`/${ModuleUrl.Login}`);
  };

  useEffect(() => {
    if (isAuthenticated() === true) {
      forwardBaseOnTier();
    } else navigate(`/${ModuleUrl.Login}`);
  });
  return null;
}
