import * as _ from 'lodash';
import {
  differenceInHours,
  formatDuration,
  intervalToDuration,
  isAfter,
  isToday,
  isYesterday,
} from 'date-fns';
import moment from 'moment';
import momentTz from 'moment-timezone';

import { OhsCountry } from 'global-services/utils/OhsCountry';

export interface AttachmentPayloadType {
  _id?: string;
  group?: string;
  file?: File;
  delete?: boolean;
}

export const arrayToObj = (array: any, mode?: string): any => {
  const obj: any = {};
  let key: string;
  if (mode == null) {
    _.forEach(array, (value) => {
      obj[value] = true;
    });
  } else {
    _.forEach(array, (value) => {
      key = value[mode];
      obj[key] = value;
    });
  }
  return obj;
};
export const OhsLocalStoragePrefix = 'ohsStorage-';
export const TusUploadLocalStoragePrefix = 'tus::tus-';

export const setOhsLocalStorage = (itemName: string, itemValues: any) => {
  const configName = OhsLocalStoragePrefix + itemName;
  if (typeof itemValues === 'string') {
    localStorage.setItem(configName, itemValues);
  } else {
    localStorage.setItem(configName, JSON.stringify(itemValues));
  }
};
export const getOhsLocalStorage = (itemName: string): any => {
  const configName = OhsLocalStoragePrefix + itemName;
  const data = localStorage.getItem(configName);
  try {
    if (data) {
      const dataObj = JSON.parse(data);
      return dataObj;
    }
    return null;
  } catch (error) {
    console.error(error);
    if (data) {
      return data;
    }

    return null;
  }
};
export const setLocalStorage = (itemName: string, itemValues: any) => {
  const configName = itemName;
  if (typeof itemValues === 'string') {
    localStorage.setItem(configName, itemValues);
  } else {
    localStorage.setItem(configName, JSON.stringify(itemValues));
  }
};
export const getLocalStorage = (itemName: string): any => {
  const configName = itemName;
  if (localStorage.getItem(configName)) {
    return JSON.parse(localStorage.getItem(configName)!);
  }
  return null;
};
export const removeLocalStorage = (itemName: string): any => {
  return localStorage.removeItem(itemName);
};

export const getOhsLocalStorageConfig = (configName: string, valueName: string) => {
  const valueString = localStorage.getItem(OhsLocalStoragePrefix + configName);
  try {
    const configReturn = _.filter(JSON.parse(valueString ?? ''), {
      name: valueName,
    });
    if (configReturn == null || configReturn.length === 0) {
      return null;
    }
    return configReturn[0].value;
  } catch (error) {
    return null;
  }
};

export const SsoFlagName = 'isSso';
export const SsoFlagLocalStorageName = OhsLocalStoragePrefix + SsoFlagName;

export const getEnumKeyByEnumValue = (myEnum: any, enumValue: string): string => {
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  const keyText = keys.length > 0 ? keys[0] : '';

  if (keyText) {
    const result = keyText.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  return keyText;
};

export const getCurrentTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};
export const isValidDateString = (dateString: string): boolean => {
  if (dateString) {
    // eslint-disable-next-line prefer-regex-literals
    const regexp = new RegExp('^\\d{4}-\\d{2}-\\d{2}$');
    return regexp.test(dateString);
  }
  return false;
};

export const formatDateInterval = (startDate: Date, endDate: Date) => {
  const duration = intervalToDuration({
    start: new Date(startDate),
    end: new Date(endDate),
  });
  const checkYear = duration.years ?? 0;
  const intervalDate = formatDuration(duration, {
    format: checkYear > 0 ? ['years', 'months'] : ['months', 'days'],
    delimiter: ', ',
  });

  let isPastDue = false;
  if (isAfter(startDate, endDate)) {
    isPastDue = true;
  }
  if (isToday(endDate)) {
    return 'Today';
  }
  if (isYesterday(endDate)) {
    return 'Yesterday';
  }
  if (differenceInHours(endDate, startDate) < 23 && !isPastDue) {
    return `${duration.hours ? duration.hours : ''} hours`;
  }

  return `${isPastDue ? ' - ' : ''}${intervalDate}`;
};

export const getDateDiff = (date: string) => {
  const startDate = new Date();
  const endDate = new Date(`${date}T00:00:00`);
  const dateDiff = formatDateInterval(startDate, endDate);
  return dateDiff;
};

export const replaceCountryCodeByName = (content: string) => {
  try {
    const contentList = (content || '').split(' ');
    const countryCodeStr = contentList[contentList.length - 1];
    const countryCode = Number(countryCodeStr);
    if (countryCode > 0 && countryCode <= OhsCountry.length) {
      const countryItem = OhsCountry[countryCode - 1];
      if (countryItem && countryItem.name) {
        return content.substring(0, content.length - countryCodeStr.length) + countryItem.name;
      }
    }
  } catch (error) {
    console.error(error);
    return content;
  }
  return content;
};

export const parseOtherDisplayValue = (
  value: string | null,
  otherValue: string | null,
  valueOnly?: boolean | undefined
) => {
  if (value && value.toLowerCase() === 'other') {
    if (valueOnly === true) return otherValue ?? '';
    return otherValue ? `Other (${otherValue})` : 'Other';
  }
  return value ?? '';
};

export const parseObjectDisplayValue = (value: any) => {
  if (value == null) return '';
  if (typeof value === 'string') return value;
  if (Array.isArray(value) === true) return value.join(', ');
  return value;
};

export const youtubeParser = (url: string): string => {
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  try {
    const match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : '';
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const dayMonthYearDisplay = (key: string, value: number) => {
  // remove 's' to days / weeks / months if frequencyValue is 1 ...  e.g. 1 day
  if (_.isNil(value) || _.isNil(key)) return '';
  return `${value} ${value === 1 ? key.slice(0, -1) : key}`;
};

// date format accepted by backend
export const formatDate = (date: string) => {
  if (date) {
    const formattedDateRes = moment(new Date(date)).format('YYYY-MM-DD') ?? '';
    return formattedDateRes;
  }
  return date;
};

/* if current timezone does not match given timezone,
  give current timezone's date time tz equivalent */
export const returnCurrentTimezoneEquivalent = ({
  date,
  time,
  tz,
}: {
  date: string;
  time: { hours: number; minutes: number };
  tz: string;
}) => {
  const currentTimeZone = getCurrentTimezone();

  // if current timezone matches provided timezone
  if (currentTimeZone === tz) return '';

  try {
    const dateTimeString = `${date} ${String(time.hours).padStart(2, '0')}:${String(
      time.minutes
    ).padStart(2, '0')}`;

    const dateFormatWithTz = momentTz.tz(dateTimeString, 'YYYY-MM-DD HH:mm', tz);
    const convertedTz = momentTz(dateFormatWithTz).tz(currentTimeZone);
    const newDate = convertedTz.format('YYYY-MM-DD');
    const newHr = convertedTz.format('HH');
    const newMin = convertedTz.format('mm');

    if (!dateFormatWithTz.isValid() || !convertedTz.isValid()) {
      throw new Error('invalid date');
    }

    return {
      date: newDate,
      time: {
        hours: +newHr,
        minutes: +newMin,
      },
      tz: currentTimeZone,
    };
  } catch (e) {
    return null;
  }
};
