import React from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import _ from 'lodash';

import { OhsApiRequestName } from 'global-services/api/OhsApiModels';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import {
  getOhsDashboard,
  incrementPendingRequest,
  decrementPendingRequest,
  clearPendingRequest,
} from 'dashboard/OhsDashboardSlice';
import { ModuleType } from 'global-services/constants/OhsObject';

import axios from '../../global-services/api/OhsApi';

function OhsBackdrop() {
  const dispatch = useAppDispatch();
  const dashboards = useAppSelector(getOhsDashboard);

  const excludeRpcLists = [
    OhsApiRequestName.List,
    OhsApiRequestName.ListAssigned,
    OhsApiRequestName.AssignAggregate,
    OhsApiRequestName.BookmarkedList,
    OhsApiRequestName.BookmarkedListByTask,
    OhsApiRequestName.CustomList,
    OhsApiRequestName.MultiUserList,
    OhsApiRequestName.GetTokenWidgets,
    OhsApiRequestName.MultiUser,
    OhsApiRequestName.AssignTo,
    OhsApiRequestName.UnAssignTo,
    OhsApiRequestName.AssignStatus,
  ];
  const excludeRpcTypeNameList: { type: ModuleType; name: OhsApiRequestName }[] = [
    { type: ModuleType.User, name: OhsApiRequestName.Fetch },
    { type: ModuleType.User, name: OhsApiRequestName.MfaList },
    { type: ModuleType.User, name: OhsApiRequestName.Whoami },
  ];

  const isShowSpinner = (getConfig: any) => {
    let config = getConfig;
    if (_.isString(getConfig)) {
      config = JSON.parse(getConfig);
    }
    const isInExcludeRpcLists = excludeRpcLists.includes(config.method.name);
    const isInExcludeRpcTypeNameList =
      _.find(excludeRpcTypeNameList, {
        type: config.method.type,
        name: config.method.name,
      }) != null;
    // only show spinner if the request is not in
    // the 'excludeRpcTypeNameList' and 'excludeRpcLists' list
    return isInExcludeRpcLists !== true && isInExcludeRpcTypeNameList !== true;
  };

  const interceptors = React.useMemo(
    () => ({
      request: (config: InternalAxiosRequestConfig) => {
        if (isShowSpinner(config.data) === true) dispatch(incrementPendingRequest());
        return config;
      },
      response: (response: AxiosResponse) => {
        if (isShowSpinner(response.config.data) === true) dispatch(decrementPendingRequest());
        return response;
      },
      error: (error: AxiosError) => {
        dispatch(decrementPendingRequest());
        return Promise.reject(error);
      },
    }),
    []
  ); // create the interceptors

  React.useEffect(() => {
    dispatch(clearPendingRequest());
    // add request interceptors
    const requestId = axios.interceptors.request.use(interceptors.request, interceptors.error);
    // add response interceptors
    const responseId = axios.interceptors.response.use(interceptors.response, interceptors.error);
    return () => {
      // remove all intercepts when done
      axios.interceptors.request.eject(requestId);
      axios.interceptors.response.eject(responseId);
      dispatch(clearPendingRequest());
    };
  }, [axios]);

  const { pendingRequests } = dashboards;
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: 9999 }}
        open={pendingRequests > 0}
        onClick={() => {
          dispatch(clearPendingRequest());
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default OhsBackdrop;
