export class OhsConstants {
  // the folder which puts modules from angular js
  public static readonly LegacyModuleFolder: string = 'sc';

  public static readonly FreemiumOrgId: string = '5d7c5ddfa5639d40fbf3b27a';

  SmallScreenWidthBreakpoint: number = 1200;

  public static readonly SessionDay: number = 1;

  public static readonly VisitorSignInSteps: string[] = [
    'Your Role',
    'Your Details',
    'More Info',
    'Finish',
  ];

  public static readonly VisitorSignInStepsWithout3: string[] = [
    'Your Role',
    'Your Details',
    'Finish',
  ];

  public static readonly morphPermission: any = {
    'core.module.action': ['view', 'create', 'edit', 'signoff', 'archive'],
    'core.module.admin2': ['view'],
    'core.module.asset': ['view', 'create', 'edit', 'signoff', 'archive', 'delete'],
    'core.module.chemical': [
      'view',
      'create',
      'edit',
      'signoff',
      'edit_allocated',
      'archive',
      'delete',
    ],
    'core.module.contractor': ['view', 'create', 'edit', 'archive', 'delete'],
    'core.module.correspondence': [
      'view_prose',
      'create_prose',
      'edit_prose',
      'archive_prose',
      'view_roll',
      'create_roll',
      'edit_roll',
      'archive_roll',
      'delete_roll',
      'setup_exchange',
      'send_exchange',
      'archive_exchange',
      'delete_exchange',
      'form_create',
      'form_edit',
      'form_archive',
      'form_export',
    ],
    'core.module.crisk': ['view', 'create', 'edit', 'signoff', 'archive', 'delete'],
    'core.module.export': ['view'],
    'core.module.filebox.action': ['view', 'create', 'edit', 'delete'],
    'core.module.filebox.asset': ['view', 'create', 'edit', 'delete'],
    'core.module.filebox.chemical': ['view', 'create', 'edit', 'delete'],
    'core.module.filebox.contractor': ['view', 'create', 'edit', 'delete'],
    'core.module.filebox.crisk': ['view', 'create', 'edit', 'delete'],
    'core.module.filebox.hr': ['view', 'create', 'edit', 'delete'],
    'core.module.filebox.incident': ['view', 'create', 'edit', 'delete'],
    'core.module.filebox.reviewplan': ['view', 'create', 'edit', 'delete'],
    'core.module.filebox.vdoc': ['view', 'create', 'edit', 'delete'],
    'core.module.hr': ['view', 'create', 'edit', 'archive', 'delete'],
    'core.module.incident': ['view', 'create', 'edit', 'signoff', 'archive'],
    'core.module.inspection': [
      'view',
      'create',
      'edit',
      'signoff',
      'archive',
      'delete',
      'delete_one_off_child',
    ],
    'core.module.noticeboard': [
      'view',
      'create',
      'edit',
      'archive',
      'form_create',
      'form_edit',
      'form_archive',
      'form_export',
    ],
    'core.module.reviewplan': ['view', 'create', 'edit', 'signoff', 'archive', 'delete'],
    'core.module.safetyplan': ['view', 'create', 'edit', 'signoff', 'archive', 'delete'],
    'core.module.training': ['view', 'create', 'edit', 'signoff', 'archive', 'delete'],
    'core.module.vdoc': ['view', 'create', 'edit', 'up_version', 'signoff', 'archive', 'delete'],
    'core.module.visitor': ['site_view', 'visit_view', 'visit_arrive', 'visit_leave'],
  };

  public static readonly ohsModuleHomeUrl: any = {
    'core.module.action': 'action.home',
    'core.module.import': 'admin2.import',
    'core.module.incident': 'accident.home',
    'core.module.admin': 'admin.home',
    'core.module.admin2': 'admin2.home',
    'core.module.safetyplan': 'safety-plan.home',
    'core.module.hr': 'people.home',
    'core.module.training': 'people.training',
    'core.module.vdoc': 'documents.home',
    'core.module.inspection': 'inspection.home',
    'core.module.chemical': 'chemical.home',
    'core.module.contractor': 'contractor.home',
    'core.module.reviewplan': 'reviewplan.home',
    'core.module.correspondence': 'correspondence.home',
  };
}
export const FreemiumOrgId: string = '5d7c5ddfa5639d40fbf3b27a';

export const TaskModuleHomeFolder = 'task';
export const SafetyPlanModuleHomeFolder = 'safety-plan';
export const InspectionModuleHomeFolder = 'inspection';
export const IncidentModuleHomeFolder = 'ai';
export const HumanResourceModuleHomeFolder = 'people';
export const CorrespondenceModuleHomeFolder = 'correspondence';
export const DocumentsModuleHomeFolder = 'documents';
export const ChemicalModuleHomeFolder = 'chemical';
export const ActionModuleFolder = 'action';
export const AssetsModuleFolder = 'assets';
export const ContractorModuleFolder = 'contractor';
export const ReviewPlanModuleFolder = 'review-plan';
export const DashboardBannerModuleFolder = 'dashboardbanner';
export const NoticeBoardModuleHomeFolder = 'noticeboard';
export const CorporateRiskModuleHomeFolder = 'crisk';
export const OnboardingModuleHomeFolder = 'onboarding';
export const OTP_LENGTH = 6;
export const HiddenModules = {
  'core.tier.T2': [],
  'core.tier.T3': ['core.module.import'],
  'core.tier.T4': ['core.module.import'],
  'core.tier.T5': ['core.module.import'],
};

export const ContractorLinkable = ['core.module.safetyplan', 'core.module.reviewplan'];
export const VisitorModuleHomeFolder = 'visitor';
export const AdminExportOptions = {
  config2_users: [
    { fn: 'email', fd: 'Email Address' },
    { fn: 'name', fd: 'Name' },
    { fn: 'phoneCountryCode', fd: 'Country Code' },
    { fn: 'phone', fd: 'Mobile Number' },

    { fn: 'exportTierType', fd: 'Type' },
    { fn: 'exportTierId', fd: 'Workplace ID' },
    { fn: 'exportTierInfo', fd: 'Workplace Name' },
    { fn: 'disabled', fd: 'Disabled (Yes/No)', ft: 'bool' },
    { fn: 'multiuser', fd: 'Multi-user (Yes/No)', ft: 'bool' },
    { fn: 'exportProfileInfo', fd: 'Profile Name' },
  ],
  config2_tiers: [
    { fn: 'exportTierId', fd: 'Workplace ID' },
    { fn: 'name', fd: 'Name' },
    { fn: 'address', fd: 'Address' },
    { fn: 'state', fd: 'State' },
    { fn: 'country', fd: 'Country' },
    { fn: 'disabled', fd: 'Disabled (Yes/No)', ft: 'bool' },
    { fn: 'exportProfileInfo', fd: 'Profile Name' },
  ],
  config2_profiles: [
    { fn: 'exportProfileId', fd: 'Profile ID' },
    { fn: 'title', fd: 'Profile Name' },
    { fn: 'description', fd: 'Profile Description' },
    { fn: 'type', fd: 'Type' },
    { fn: 'exportTierType', fd: 'Apply To' },
    { fn: 'disabled', fd: 'Disabled (Yes/No)', ft: 'bool' },
  ],
};
