import React from 'react';

import { isUndefined } from 'lodash';
import { Button, Icon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react';

import registerButtonStyles from 'global-components/register/RegisterButtonStyles';
import { TaskAssignStatusFilter } from 'task/OhsTaskTypes';
import { useAppDispatch } from 'app/hooks';
import {
  clearTaskAssign,
  setOnAssignModalCloseFunc,
  toggleAssignModalOpen,
} from 'task/store/OhsTaskAssignSlice';
import OhsTaskAssignHook from 'task/hooks/OhsTaskAssignHook';
import { ModuleType } from 'global-services/constants/OhsObject';

interface Props {
  row: any;
  name?: string;
  color?: SemanticCOLORS;
  parentId: (row: any) => string;
  parentType: (row: any) => ModuleType;
  taskId: (row: any) => string;
  onModalCloseFunc?: () => void;
  icon?: SemanticICONS;
  disabled?: boolean;
}
function OhsAssignRowButton(props: Props) {
  const { classes } = registerButtonStyles();
  const dispatch = useAppDispatch();
  const { getTaskAssignStatus, setTaskAssignInfo } = OhsTaskAssignHook();

  const loadTaskAssign = () => {
    const taskStatusObject: TaskAssignStatusFilter = {
      _id: props.taskId(props.row?.original),
      for: {
        _id: props.parentId(props.row?.original),
        type: props.parentType(props.row?.original),
      },
    };
    getTaskAssignStatus(taskStatusObject);
    setTaskAssignInfo(taskStatusObject);
  };

  const handleAssign = () => {
    loadTaskAssign();
    dispatch(toggleAssignModalOpen(true));
    dispatch(
      setOnAssignModalCloseFunc(() => {
        dispatch(clearTaskAssign());
        if (!isUndefined(props.onModalCloseFunc)) props.onModalCloseFunc();
      })
    );
  };

  React.useEffect(() => {
    dispatch(clearTaskAssign());
  }, []);

  return (
    <Button
      className={classes.button}
      color={props.color}
      onClick={handleAssign}
      disabled={props.disabled}
    >
      {props.icon && (
        <Icon
          className={
            `${props.name}` === ''
              ? `${classes.iconOnly} ${classes.buttonIcons}`
              : classes.buttonIcons
          }
          name={props.icon}
        />
      )}
      {props.name && <span className={classes.hideForSmall}>{props.name}</span>}
    </Button>
  );
}

OhsAssignRowButton.defaultProps = {
  name: undefined,
  icon: undefined,
  onModalCloseFunc: undefined,
  color: 'grey',
  disabled: false,
};

export default OhsAssignRowButton;
