import {
  OhsEmail,
  OhsMultiUser,
  OhsMultiUserOptions,
  OhsMultiUserSessionToken,
  OhsSessionToken,
  OhsTimeStamp,
} from 'global-services/constants/OhsStorageNames';
import { OhsMultiUserLoginOption } from 'login/multi/OhsMultiUserLoginOption';

import OhsSessionServices from './OhsSessionServices';

class OhsUserSession {
  [OhsEmail]: string;

  id?: string;

  [OhsMultiUser]: boolean;

  [OhsTimeStamp]: number;

  version: string = '1.0';

  [OhsMultiUserOptions]?: OhsMultiUserLoginOption[];

  [OhsMultiUserSessionToken]?: string;

  [OhsSessionToken]?: string;

  constructor(email: string, isMultiUser?: boolean) {
    this[OhsEmail] = email;
    this[OhsMultiUser] = isMultiUser === true;
    this[OhsTimeStamp] = new Date().getTime();
    const userToken = OhsSessionServices.getUserToken();
    if (userToken) this[OhsSessionToken] = userToken;
    const optionToken = OhsSessionServices.getOptionToken();
    if (optionToken) this[OhsMultiUserSessionToken] = optionToken;
  }
}

export default OhsUserSession;
