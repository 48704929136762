import { createAsyncThunk } from '@reduxjs/toolkit';

import getNoticeBoardLists, {
  getNoticeBoardAllocatedList,
  getNoticeBoardFormList,
} from 'notice-board/OhsNoticeBoardService';
import {
  OhsNoticeBoardListsFilterPayload,
  OhsNoticeBoardRootState,
} from 'notice-board/model/OhsNoticeBoardModel';
import { ApiResListsType, OhsApiRequestName } from 'global-services/api/OhsApiModels';
import globalSearchKey from 'search/OhsSearchServices';
import { OhsGlobalSearchPayload } from 'search/OhsSearchModels';
import OhsNoticeBoardRecord from 'notice-board/model/OhsNoticeBoardRecord';
import OhsFetchAll from 'global-services/OhsFetchAll';
import { updateSearchInfo } from 'search/OhsSearchUtils';
import { OhsUser } from 'user/OhsUser';
import OhsUserLocalServices from 'user/OhsUserLocalServices';
import { TierType } from 'global-services/constants/OhsObject';
import parseListAllocations from 'notice-board/utils/OhsParseAllocationList';

const noticeBoardSearch = async (
  task: OhsGlobalSearchPayload,
  count: boolean,
  page: number
): Promise<ApiResListsType<OhsNoticeBoardRecord[]> | null> => {
  const searchInfo = { ...task, filter: { ...task.filter, count, page } };
  const globalSearchRes: any = await globalSearchKey(searchInfo);
  return globalSearchRes.result;
};

export const getNoticeBoardListsWithAllocations = (
  state: OhsNoticeBoardRootState,
  filterInfo: OhsNoticeBoardListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const { globalSearch } = state;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const hasGlobalSearch = globalSearch.searchInfo.searchKey !== '';
  const searchPayload = updateSearchInfo(globalSearch.searchInfo, OhsApiRequestName.List, user);
  const getNoticeBoardListsReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const noticeBoardLists = await (hasGlobalSearch
      ? await noticeBoardSearch(searchPayload, false, 1)
      : getNoticeBoardLists(filterInfo));

    // exit if list result is empty
    if (noticeBoardLists && noticeBoardLists?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(noticeBoardLists);
      });
    }
    // get allocated records from noticeBoardlist _ids
    const getIDs: string[] = [];
    noticeBoardLists?.items.forEach((item: OhsNoticeBoardRecord) => {
      if (item._id && item.tier?.type === TierType.T3) getIDs.push(item._id);
    });
    if (getIDs && getIDs.length === 0) {
      return new Promise((resolve) => {
        resolve(noticeBoardLists);
      });
    }
    const allocatedList = await getNoticeBoardAllocatedList(getIDs);
    // map allocated records to noticeboardlist
    const noticeBoardListWithAllocations = parseListAllocations(
      noticeBoardLists,
      allocatedList
    ) as ApiResListsType<OhsNoticeBoardRecord[]> | null;

    return new Promise((resolve) => {
      resolve(noticeBoardListWithAllocations);
    });
  };

  return getNoticeBoardListsReq();
};

const noticeBoardListsRPC = async (
  user: OhsUser,
  state: OhsNoticeBoardRootState,
  filters: OhsNoticeBoardListsFilterPayload,
  searchPayload: OhsGlobalSearchPayload,
  page: number
): Promise<any> => {
  let searchRequestApi = OhsApiRequestName.List;
  if (filters.viewPreset === 'view_1') {
    searchRequestApi = OhsApiRequestName.List;
  }
  const searchInfo = updateSearchInfo(searchPayload, searchRequestApi, user);
  const hasGlobalSearch = searchInfo?.searchKey !== '';
  const userTier = user?.tierNum ?? 0;
  if (userTier && userTier === 3 && user?.configs.noticeboard?.PERMISSIONS.view) {
    const response = hasGlobalSearch
      ? await noticeBoardSearch(searchInfo, false, page)
      : await getNoticeBoardListsWithAllocations(state, filters);
    return response;
  }
  if (
    userTier &&
    (userTier === 2 || userTier === 4) &&
    user?.configs.noticeboard?.PERMISSIONS.view
  ) {
    const response = hasGlobalSearch
      ? await noticeBoardSearch(searchInfo, false, page)
      : await getNoticeBoardLists(filters);
    return response;
  }

  return null;
};

export const fetchNoticeBoardListsAsync = createAsyncThunk<
  ApiResListsType<any[]> | null,
  undefined
>('noticeboard/fetchNoticeBoardList', async (_, thunkAPI) => {
  const state = thunkAPI.getState() as OhsNoticeBoardRootState;
  const {
    noticeBoard,
    globalfilter: { filterInfo },
    globalSearch,
  } = thunkAPI.getState() as OhsNoticeBoardRootState;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const { currentViewPreset, currentPage } = noticeBoard;
  const viewPresetFilter = filterInfo.noticeboardModule[currentViewPreset];
  const setNoticeBoardFilters: OhsNoticeBoardListsFilterPayload = {
    ...viewPresetFilter,
    page: currentPage > 10 ? 0 : currentPage,
    sort: { ...JSON.parse(String(filterInfo.noticeboardModule.sort)) },
    count: false,
  };

  try {
    const response = await noticeBoardListsRPC(
      user,
      state,
      setNoticeBoardFilters,
      globalSearch.searchInfo,
      currentPage
    );
    return response as ApiResListsType<any[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchNoticeBoardFullListsAsync = createAsyncThunk<
  ApiResListsType<any[]> | null,
  undefined
>('noticeboard/fetchNoticeBoardFullList', async (__, thunkAPI) => {
  const {
    noticeBoard,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsNoticeBoardRootState;
  const { currentViewPreset } = noticeBoard;
  const viewPresetFilter: any = filterInfo.noticeboardModule[currentViewPreset];

  try {
    const noticeBoardList: any = OhsFetchAll(getNoticeBoardLists, {
      ...viewPresetFilter,
      sort: JSON.parse(String(filterInfo.noticeboardModule.sort)),
    });
    return noticeBoardList as ApiResListsType<any[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

const noticeBoardFormListRPC = async (
  user: OhsUser,
  filters: OhsNoticeBoardListsFilterPayload,
  searchInfo: OhsGlobalSearchPayload,
  page: number
): Promise<any> => {
  const hasGlobalSearch = searchInfo?.searchKey !== '';
  const userTier = user?.tierNum ?? 0;
  if (
    userTier &&
    (userTier === 2 || userTier === 4 || userTier === 3) &&
    user?.configs.noticeboard?.PERMISSIONS.view
  ) {
    const response = hasGlobalSearch
      ? await noticeBoardSearch(searchInfo, false, page)
      : await getNoticeBoardFormList(filters);
    return response;
  }

  return null;
};

export const fetchNoticeBoardFormListAsync = createAsyncThunk<
  ApiResListsType<any[]> | null,
  undefined
>('noticeboard/fetchNoticeBoardFormList', async (_, thunkAPI) => {
  const {
    noticeBoard,
    globalfilter: { filterInfo },
    globalSearch,
  } = thunkAPI.getState() as OhsNoticeBoardRootState;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const { currentViewPreset, currentPage } = noticeBoard;
  const viewPresetFilter = filterInfo.noticeboardModule[currentViewPreset];
  const setNoticeBoardFilters: OhsNoticeBoardListsFilterPayload = {
    ...viewPresetFilter,
    viewPreset: 'view_1',
    page: currentPage > 10 ? 0 : currentPage,
    sort: { ...JSON.parse(String(filterInfo.noticeboardModule.sort)) },
    count: false,
  };

  try {
    const response = await noticeBoardFormListRPC(
      user,
      setNoticeBoardFilters,
      globalSearch.searchInfo,
      currentPage
    );
    return response as ApiResListsType<any[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchNoticeBoardFormListCountAsync = createAsyncThunk<ApiResListsType<
  OhsNoticeBoardRecord[]
> | null>('noticeboard/fetchNoticeBoardFormListWithCount', async (_, thunkAPI) => {
  const {
    noticeBoard,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsNoticeBoardRootState;

  const { currentViewPreset } = noticeBoard;
  const viewPresetFilter = filterInfo.noticeboardModule[currentViewPreset];

  const noticeboardFiltersWithCount: OhsNoticeBoardListsFilterPayload = {
    ...viewPresetFilter,
    viewPreset: 'view_1',
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  try {
    const response = await getNoticeBoardFormList(noticeboardFiltersWithCount);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchNoticeBoardListsCountAsync = createAsyncThunk<ApiResListsType<
  OhsNoticeBoardRecord[]
> | null>('noticeboard/fetchNoticeBoardListWithCount', async (_, thunkAPI) => {
  const {
    noticeBoard,
    globalSearch: { searchInfo },
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsNoticeBoardRootState;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const hasGlobalSearch = !!searchInfo.searchKey;
  const { currentViewPreset } = noticeBoard;
  const viewPresetFilter = filterInfo.noticeboardModule[currentViewPreset];

  const noticeboardFiltersWithCount: OhsNoticeBoardListsFilterPayload = {
    ...viewPresetFilter,
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };
  const searchPayload = updateSearchInfo(searchInfo, OhsApiRequestName.List, user);

  try {
    const response = hasGlobalSearch
      ? await noticeBoardSearch(searchPayload, true, 1)
      : await getNoticeBoardLists(noticeboardFiltersWithCount);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
