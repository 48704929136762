import React from 'react';

import { Checkbox, Divider, FormControl, FormControlLabel, FormGroup, Grid } from '@mui/material';
import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';

import { OhsFormFieldBaseProps } from '../services/OhsFormModels';
import OhsFormFieldLayout from '../components/OhsFormFieldLayout';

const useStyles = makeStyles()(() => ({
  checkbox: {
    paddingLeft: '14px',
  },
}));

interface Props extends OhsFormFieldBaseProps {
  options: { name: string; value: string; icon?: string }[];
  disabled?: boolean;
  rowDirection?: Boolean;
}
function InputEle(props: Props) {
  const { classes } = useStyles();
  const disabled = props.disabled || false;
  const conditionalClass = props.ohsFormSettings.layout === 0 ? classes.checkbox : '';

  const hasSelectAll = props.options.length > 4;

  const optionValue = props.ohsFormSettings.useFormMethods.watch(props.id) || [];

  const options = props.options.map((item) => item.value);
  const validOption = Array.isArray(optionValue) && Array.isArray(options);
  const allSelected = validOption && optionValue.length === options.length;

  const handleSetAll = () => {
    const newList = !allSelected ? options : [];
    props.ohsFormSettings.useFormMethods.setValue(props.id, newList);
    props.ohsFormSettings.useFormMethods.trigger(props.id);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const { value } = e.target;
    if (checked) {
      // const newList = [...optionValue, value];
      let newList = [];
      if (typeof optionValue === 'string') {
        newList = [optionValue, value];
      } else {
        newList = [...optionValue, value];
      }

      props.ohsFormSettings.useFormMethods.setValue(props.id, newList);
      props.ohsFormSettings.useFormMethods.trigger(props.id);
    } else {
      // const newList = optionValue.filter((item: string) => item !== value);
      let newList = optionValue;
      if (typeof optionValue === 'string') {
        newList = [];
      } else {
        newList = optionValue.filter((item: string) => item !== value);
      }
      props.ohsFormSettings.useFormMethods.setValue(props.id, newList);
      props.ohsFormSettings.useFormMethods.trigger(props.id);
    }
  };

  return (
    <Grid item xs={12} md={12}>
      <FormControl component="fieldset">
        <FormGroup className={conditionalClass} row={Boolean(props?.rowDirection)}>
          {hasSelectAll === true && !disabled && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    name="selectAll"
                    checked={allSelected}
                    onChange={handleSetAll}
                    color="primary"
                  />
                }
                label={allSelected ? 'Unselect All' : 'Select All'}
              />
              <Divider />
            </>
          )}
          {props.options.map((option, index) => {
            // const isChecked = Array.isArray(optionValue)
            //   ? !_.isNil(optionValue.find((item: string) => item === option.value))
            //   : false;
            let isChecked = false;

            if (Array.isArray(optionValue)) {
              isChecked = !_.isNil(optionValue.find((item: string) => item === option.value));
            }

            if (optionValue && typeof optionValue === 'string') {
              isChecked = optionValue === option.value;
            }

            return (
              <FormControlLabel
                disabled={disabled}
                control={
                  <Checkbox
                    color="primary"
                    value={option.value}
                    required={props.required}
                    {...props.ohsFormSettings.useFormMethods.register(props.id, {
                      required: props.required,
                    })}
                    onChange={(e) => handleChange(e, !isChecked)}
                    checked={isChecked}
                  />
                }
                key={index}
                label={
                  <span>
                    {option?.icon && (
                      <>
                        <i className={`icon ${option.icon}`} />{' '}
                      </>
                    )}
                    {option.name}
                  </span>
                }
                sx={{
                  '& .MuiFormControlLabel-asterisk': {
                    display: 'none !important',
                  },
                }}
              />
            );
          })}
        </FormGroup>
      </FormControl>
    </Grid>
  );
}

const defaultProps = {
  disabled: false,
  rowDirection: false,
};

InputEle.defaultProps = defaultProps;

export default function OhsCheckboxField(props: Props) {
  return <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />;
}

OhsCheckboxField.defaultProps = defaultProps;
