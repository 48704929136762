import React, { useState } from 'react';

import clsx from 'clsx';
import { Box, IconButton } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Icon, SemanticICONS } from 'semantic-ui-react';
import _, { isUndefined } from 'lodash';

import { OhsChemicalViewPresets } from 'chemical/models/OhsChemicalModel';
import {
  OhsChemicalClassRecordField,
  OhsChemicalHazardRecordField,
  OhsChemicalMaxRecordField,
  OhsChemicalNameRecordField,
  OhsChemicalSDSIssueDateRecordField,
} from 'chemical/models/OhsChemicalRecordFields';
import {
  OhsAttachmentsRecordField,
  OhsDateDueRecordField,
  OhsLocationsRecordField,
} from 'global-services/constants/record-field/OhsGlobalRecordFiled';
import { parseOtherDisplayValue } from 'global-services/OhsDataParse';
import OhsChemicalRecord from 'chemical/models/OhsChemicalRecord';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { getOhsArrayRowDisplay } from 'global-components/register/table/rowDisplay/OhsArrayRowDisplay';
import OhsCellColumnWithNotes from 'global-components/register/table/utils/OhsCellColumnWithNotes';
import { dateColorBoxFunc } from 'global-components/register/table/utils/OhsDateColor';
import OhsModal from 'global-components/modal/OhsModal';
import Attachments from 'chemical/components/Attachments';
import { openFileOnBrowser } from 'global-components/form/fields/attachments/OhsAttachmentServices';
import OhsBooleanRowDisplay from 'global-components/register/table/rowDisplay/OhsBooleanRowDisplay';

import HazardStatement from '../components/HazardStatement';

export function OhsClassCell(row: any) {
  if (row.original.transport.class) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <span>{row.original.transport.class}</span>
      </Box>
    );
  }
}

export function OhsMaxAgeCell(row: any) {
  if (row.original.locations) {
    const sumQMax: number = row.original.locations.reduce(
      (acc: number, location: any) => acc + Number(location.qMax),
      0
    );
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
        <span>{sumQMax > 0 ? sumQMax : null}</span>
      </Box>
    );
  }
}

export function OhsHazardStatementCell(row: any) {
  return <HazardStatement data={row.original.risk} />;
}

const useStyles = makeStyles()({
  root: {
    display: 'flex',
    gap: '5px',
    verticalAlign: 'middle',
    justifyContent: 'center',
  },
  circle: {
    display: 'flex',
    borderRadius: '100%',
    justifyContent: 'center',
    verticalAlign: 'middle',
    border: '1px solid #e5e5e5',
  },
  icon: {
    margin: '0px !important',
  },
});

function RenderDocumentsIcon(row: any) {
  const { classes } = useStyles();
  const [openRiskModal, setOpenRiskModal] = useState(false);
  const [openSDSModal, setOpenSDSModal] = useState(false);
  const { attachments: sdsAttachments = [] } = row?.original?.sds || [];
  const { attachments: riskAttachments = [] } = row?.original?.risk || [];

  const renderAttachmentBox = (
    attachments: OhsAttachment[],
    iconName: SemanticICONS,
    title: string,
    openModal: boolean,
    setOpenModalFunc: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    const handleClick = async () => {
      if (attachments.length > 1) {
        setOpenModalFunc(true);
      } else {
        openFileOnBrowser(attachments[0]);
      }
    };
    return (
      <div>
        {!!attachments.length && (
          <Box className={clsx(classes.circle)}>
            <IconButton onClick={handleClick} title={title}>
              <Icon className={clsx(classes.icon)} name={iconName} size="small" color="blue" />
            </IconButton>
          </Box>
        )}
        <OhsModal
          title={`${title} Modal`}
          open={openModal}
          setModalOpenFunc={setOpenModalFunc}
          ContentNode={<Attachments attachments={attachments} />}
        />
      </div>
    );
  };

  return (
    <Box className={classes.root}>
      {renderAttachmentBox(
        riskAttachments,
        'warning sign',
        'Risk Assessment Attachment(s)',
        openRiskModal,
        setOpenRiskModal
      )}

      {renderAttachmentBox(
        sdsAttachments,
        'file alternate outline',
        'Safety Data Sheet(SDS) Attachment(s)',
        openSDSModal,
        setOpenSDSModal
      )}
    </Box>
  );
}

export const customHeaderCell = (label: string) => <OhsBooleanRowDisplay header label={label} />;
export const allocationCell = (row: any) => {
  const { allocationActive } = row.original;
  return <OhsBooleanRowDisplay value={allocationActive} />;
};

export const parseLocationsArrays = (record: any) => {
  const results = [] as any[];
  if (record && record?.locations?.length > 0) {
    record.locations.forEach((item: any) => {
      const newItem = {} as any;
      newItem[OhsLocationsRecordField.key] = _.isNull(item?.sub)
        ? parseOtherDisplayValue(item?.main, item?.mainOther, true)
        : `${parseOtherDisplayValue(item?.main, item?.mainOther, true)} -- ${parseOtherDisplayValue(
            item?.sub,
            item?.subOther,
            true
          )}`;
      if (item?.qMax) {
        newItem[OhsChemicalMaxRecordField.key] = `${item.qMax}`;
      }
      results.push(newItem);
    });
  }
  return results;
};
export const locationArrayDefine = [
  { key: OhsLocationsRecordField.key, name: OhsLocationsRecordField.name },
  { key: OhsChemicalMaxRecordField.key, name: OhsChemicalMaxRecordField.name },
];
export const getLocationColor = (record: OhsChemicalRecord) => {
  if (record?.locations?.length > 0 && record?.presetLocations !== record?.locations) {
    return 'green';
  }
  return 'nocolor';
};

export const chemicalColumnConfig: any = [
  {
    Header: OhsChemicalNameRecordField.name,
    accessor: OhsChemicalNameRecordField.key,
    Cell: ({ row }: any) => {
      const displayTitle = row.original.supplierName
        ? `${row.original.name} (${row.original.supplierName || ''})`
        : row.original.name;
      return (
        <OhsCellColumnWithNotes
          item={{
            title: displayTitle,
            notes: row.original.purpose,
          }}
        />
      );
    },
  },

  getOhsArrayRowDisplay(
    OhsLocationsRecordField.name,
    parseLocationsArrays,
    locationArrayDefine,
    getLocationColor
  ),
  {
    Header: OhsChemicalMaxRecordField.name,
    accessor: OhsChemicalMaxRecordField.key,
    Cell: ({ row }: any) => OhsMaxAgeCell(row),
  },
  {
    Header: OhsChemicalHazardRecordField.name,
    accessor: OhsChemicalHazardRecordField.key,
    Cell: ({ row }: any) => OhsHazardStatementCell(row),
  },
  {
    Header: OhsChemicalClassRecordField.name,
    accessor: OhsChemicalClassRecordField.key,
    Cell: ({ row }: any) => OhsClassCell(row),
  },
  {
    Header: OhsChemicalSDSIssueDateRecordField.name,
    accessor: OhsChemicalSDSIssueDateRecordField.key,
  },
  {
    Header: OhsDateDueRecordField.name,
    accessor: OhsDateDueRecordField.key,
    Cell: (val: any) => {
      const { dateDue } = val.row.original;
      return dateColorBoxFunc(dateDue, undefined, undefined, dateDue, false);
    },
  },
  {
    Header: customHeaderCell(OhsAttachmentsRecordField.name),
    accessor: OhsAttachmentsRecordField.key,
    Cell: ({ row }: any) => RenderDocumentsIcon(row),
  },
];

const getChemicalListViewPreset = (view: string): OhsChemicalViewPresets | undefined => {
  switch (view) {
    case 'view_1':
      return OhsChemicalViewPresets.View1ChemicalList;
    case 'view_2':
      return OhsChemicalViewPresets.View2ChemicalList;
    case 'view_3':
      return OhsChemicalViewPresets.View3ChemicalList;
    case 'view_4':
      return OhsChemicalViewPresets.View4ChemicalList;
    default:
      return undefined;
  }
};

export const isEmptyObject = (obj: Record<string, any>) => {
  // Check if it's an empty object using lodash's isEmpty
  if (_.isEmpty(obj)) {
    return true;
  }

  // Check for properties with null, empty arrays, or empty strings
  return Object.values(obj).every(
    (value) =>
      value === null ||
      isUndefined(value) ||
      (_.isArray(value) && _.isEmpty(value)) ||
      (_.isString(value) && _.isEmpty(_.trim(value)))
  );
};

export function isChemicalFiltersEqual(obj1: any, obj2: any) {
  const stringifiedObj1 = JSON.stringify(obj1);
  const stringifiedObj2 = JSON.stringify(obj2);
  return stringifiedObj1 === stringifiedObj2;
}
const allocationProgressiconMap = {
  fulfilled: <Icon name="check" color="green" />,
  rejected: <Icon name="undo" color="orange" />,
  pending: <Icon name="spinner" loading />,
};
export function BulkAllocationProgressCell(status: 'fulfilled' | 'rejected' | 'pending') {
  const { classes } = useStyles();
  return (
    <Box className={classes.root}>
      {allocationProgressiconMap[status] || allocationProgressiconMap.pending}
    </Box>
  );
}
export default getChemicalListViewPreset;
