export type MfaSettingsStatus =
  | 'otpValidating'
  | 'otpInvalid'
  | 'valid'
  | 'resendingcode'
  | 'idle'
  | 'otpResent';

export enum OhsMfaOtpStatus {
  OtpValidating = 'otpValidating',
  OtpInvalid = 'otpInvalid',
  Valid = 'valid',
  ResendingCode = 'resendingcode',
  Idle = 'idle',
  OtpResent = 'otpResent',
}
