import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResListsType, ApiResponseType } from 'global-services/api/OhsApiModels';
import { ModuleType } from 'global-services/constants/OhsObject';
import { assignTaskTo, getAssignAggregate, getAssignStatus } from 'task/OhsTaskServices';
import { TaskAssignStatus, TaskAssignStatusFilter } from 'task/OhsTaskTypes';

export interface TaskAssignRecordState {
  isLoading: boolean;
  taskAssignInfo: TaskAssignStatusFilter | null;
  taskAssignStatus: TaskAssignStatus[] | null;
  taskAssignAggregateList: { records: TaskAssignStatus[]; isLoading: boolean };
  assignModalOpen: boolean;
  onAssignModalCloseFunc: (() => void) | null;
}

const initialState: TaskAssignRecordState = {
  isLoading: false,
  taskAssignInfo: null,
  taskAssignStatus: null,
  taskAssignAggregateList: { records: [], isLoading: false },
  assignModalOpen: false,
  onAssignModalCloseFunc: null,
};

export const fetchTaskAssignStatusAsync = createAsyncThunk<
  TaskAssignStatus[] | null,
  TaskAssignStatusFilter,
  { state: RootState }
>('tasks/fetchTaskAssignStatus', async (list: TaskAssignStatusFilter, thunkAPI) => {
  const taskStatusObject: TaskAssignStatusFilter = { _id: list._id, for: { ...list.for } };

  try {
    const response = await getAssignStatus(taskStatusObject);
    const resVal = response as TaskAssignStatus[] | null;
    return resVal;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchTaskAssignAggregateAsync = createAsyncThunk<
  ApiResponseType<{
    items: TaskAssignStatus[];
  }> | null,
  TaskAssignStatusFilter[],
  { state: RootState }
>('tasks/fetchTaskAssignAggregateAsync', async (_ids: TaskAssignStatusFilter[], thunkAPI) => {
  try {
    const response = await getAssignAggregate(_ids);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response);
  }
});

interface AssignToProps {
  _id: string;
  type: ModuleType;
}
export const assignToAsync = createAsyncThunk<
  ApiResListsType<any[]> | null,
  AssignToProps,
  { state: RootState }
>('tasks/assignToAsync', async (item: AssignToProps, thunkAPI) => {
  const { taskAssignInfo } = thunkAPI.getState().taskAssign;

  if (taskAssignInfo) {
    const assignToPayload = {
      _id: taskAssignInfo?._id,
      for: taskAssignInfo?.for,
      to: { ...item },
    };
    try {
      const response = await assignTaskTo(assignToPayload);
      const resVal = response as ApiResListsType<any[]> | null;
      return resVal;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
  return null;
});

export const openAssignModal = createAsyncThunk(
  'taskAssign/openAssignModal',
  async (
    {
      taskId,
      parentId,
      parentType,
      onModalCloseFunc,
    }: {
      taskId: string;
      parentId: string;
      parentType: ModuleType;
      onModalCloseFunc: () => void;
    },
    thunkAPI
  ) => {
    try {
      const taskAssignObject: TaskAssignStatusFilter = {
        _id: taskId,
        for: { _id: parentId, type: parentType },
      };
      const response = await getAssignStatus(taskAssignObject);
      const resVal = response as TaskAssignStatus[] | null;
      return {
        assignStatusList: resVal,
        modalCloseFunc: onModalCloseFunc,
        assignPayloadObject: taskAssignObject,
      };
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const taskAssignSlice = createSlice({
  name: 'taskAssign',
  initialState,
  reducers: {
    setTaskAssign: (state, action: PayloadAction<TaskAssignStatusFilter>) => {
      state.taskAssignInfo = action.payload;
    },
    clearTaskAssign: (state) => {
      state.taskAssignStatus = null;
    },
    setTaskAssignLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    toggleAssignModalOpen: (state, action: PayloadAction<boolean>) => {
      state.assignModalOpen = action.payload;
    },
    setOnAssignModalCloseFunc(state, action: PayloadAction<(() => void) | null>) {
      state.onAssignModalCloseFunc = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskAssignStatusAsync.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTaskAssignStatusAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.taskAssignStatus = action.payload;
        }
        state.isLoading = false;
      })
      .addCase(fetchTaskAssignStatusAsync.rejected, (state) => {
        state.taskAssignStatus = null;
      })
      .addCase(fetchTaskAssignAggregateAsync.fulfilled, (state, action) => {
        if (action.payload?.success) {
          const taskAssignRecords = action.payload.result?.items ?? [];
          state.taskAssignAggregateList.records = taskAssignRecords;
        }
        state.taskAssignAggregateList.isLoading = false;
      })
      .addCase(fetchTaskAssignAggregateAsync.rejected, (state) => {
        state.taskAssignAggregateList.isLoading = false;
      })
      .addCase(openAssignModal.pending, (state) => {
        state.isLoading = true;
        state.taskAssignStatus = null;
      })
      .addCase(openAssignModal.fulfilled, (state, action) => {
        if (action.payload) {
          state.taskAssignStatus = action.payload.assignStatusList;
          state.taskAssignInfo = action.payload.assignPayloadObject;
          state.assignModalOpen = true;
          state.onAssignModalCloseFunc = action.payload.modalCloseFunc;
        }
        state.isLoading = false;
      })
      .addCase(openAssignModal.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

// Memoized Selectors
const taskAssignState = (state: RootState) => state.taskAssign;
export const getOhsTaskAssignState = createSelector([taskAssignState], (taskAssign) => taskAssign);

export const {
  setTaskAssign,
  setTaskAssignLoading,
  clearTaskAssign,
  toggleAssignModalOpen,
  setOnAssignModalCloseFunc,
} = taskAssignSlice.actions;
export const taskAssignReducer = taskAssignSlice.reducer;
