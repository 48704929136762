import { OhsFilterList } from 'dashboard/topFilter/OhsFilterModels';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { GlobalSearchState } from 'search/OhsSearchModels';
import { OhsUser } from 'user/OhsUser';
import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsHrLookup } from 'human-resource/models/OhsHumanResourceModels';

export interface OhsIncidentCustomFilters {
  workplaceOwned?: boolean;
  archived?: boolean;
  categories?: string[];
  workplaces?: string[];
  next?: string;
}

export interface OhsIncidentFilterPayload extends OhsIncidentCustomFilters {
  viewPreset?: 'view_1' | 'view_2' | 'view_3';
  page: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
}
export interface OhsModuleListsTypes {
  rowData: any[];
  isLoading: boolean;
  totalPages: number;
}
export interface OhsIncidentRootState {
  globalfilter: {
    filterInfo: OhsFilterList;
  };
  incident: IncidentListsRecordState;
  globalSearch: GlobalSearchState;
}

export enum OhsIncidentViewPresets {
  View1IncidentList = 'view1IncidentList',
  View2IncidentList = 'view2IncidentList',
  View3IncidentList = 'view3IncidentList',
}

export interface IncidentListsRecordState {
  isLoading: boolean;
  incidentLists: ApiResListsType<any[]> | null;
  currentPage: number;
  currentViewPreset: OhsIncidentViewPresets;
}
export interface FilterOptionsProps {
  filterInfo: OhsFilterList;
  data: ApiResListsType<any[]> | null;
  currentPage: number;
}

export type Severity = 'Low' | 'Medium' | 'High' | null;

export interface BodyPart {
  bodyPart: string;
  bodyPartOther: string | null;
  injury: string;
  injuryOther: string | null;
  comment: string;
}

export interface ExternalBodiesNotified {
  name: string;
  nameOther: string;
  date: string;
  comment: string;
}

export interface EditLogs {
  by: OhsUser;
  comment: string;
  date: string;
}

export enum IncidentStatus {
  Active = 'active', // save or sign off
  Completed = 'completed', // update sign off
  Invalid = 'invalid', // invalid
}

export interface OhsIncidentSearchFilter {
  page: number;
  count: boolean;
  modules: string[];
  skipOrgRecords: boolean;
  workplaces: string[];
  task: {
    complete: boolean;
    includeMasterRecords: boolean;
  };
  skipAllocatedRecords: boolean;
}

export interface LinkedIncidentType {
  _id: string;
  reference: string;
  type: ModuleType.Incident;
}

export interface InjuredPersonLinkItem extends OhsHrLookup {
  enableNotification?: boolean;
  enableManagerNotification?: boolean;
}
