import _ from 'lodash';

import ohsApiClient from './OhsApi';
import { ModuleType } from '../constants/OhsObject';
import {
  getApiRequestErrorMessage,
  OhsApiRequestName,
  OhsApiRequestOptions,
  RequestType,
  requestTypeActionsMsg,
} from './OhsApiModels';
import OhsApiServices from './OhsApiServices';

const OhsApiRequest = <T>(
  type: RequestType,
  methodType: ModuleType,
  methodName: OhsApiRequestName,
  payload?: any,
  options?: OhsApiRequestOptions
): Promise<T | null> => {
  const endpoint = `/${type}`;
  const config = { headers: {}, requestTimestamp: new Date().getTime() } as any;
  config.headers['Content-Type'] = 'application/json';
  OhsApiServices.handleConfigHeaders(config, methodType, methodName, payload, options);

  const importsModules = [
    OhsApiRequestName.ImportChemical,
    OhsApiRequestName.ImportHR,
    OhsApiRequestName.ImportContractor,
    OhsApiRequestName.ImportIncident,
    OhsApiRequestName.ImportTraining,
    OhsApiRequestName.ImportUsers,
    OhsApiRequestName.ImportWorkplaces,
    OhsApiRequestName.ImportAction,
  ];

  return ohsApiClient
    .post(
      endpoint,
      {
        method: {
          type: methodType,
          name: methodName,
        },
        payload: payload || {},
      },
      config
    )
    .then((response) => {
      OhsApiServices.updateSession(response);
      if (_.has(response, 'data.success') && response.data.success === true) {
        const successMsg = requestTypeActionsMsg(methodName, payload);
        if (successMsg) OhsApiServices.showSuccess(successMsg, options);

        const isSearchModule =
          methodType === ModuleType.Search ||
          methodName === OhsApiRequestName.SearchForAggregate ||
          methodType === ModuleType.Task;
        const isTaskModule = methodType === ModuleType.Task;
        const isSuperSetModule = methodType === ModuleType.SuperSet;

        const isSearchListRequest =
          isSearchModule &&
          (methodName === OhsApiRequestName.List ||
            methodName === OhsApiRequestName.OrgList ||
            methodName === OhsApiRequestName.WorkerTaskAssignedList ||
            methodName === OhsApiRequestName.OrgWorkplaceList ||
            methodName === OhsApiRequestName.WorkerGroupList ||
            methodName === OhsApiRequestName.VisitList ||
            methodName === OhsApiRequestName.FileBoxList ||
            methodName === OhsApiRequestName.SearchForAggregate ||
            methodName === OhsApiRequestName.SearchSignOff);

        const isSearchWithScheduleRequest =
          isSearchModule &&
          (methodName === OhsApiRequestName.SafetyPlanScheduleSearch ||
            methodName === OhsApiRequestName.InspectionScheduleSearch ||
            methodName === OhsApiRequestName.InspectionAvailableSearch);

        const isTaskListRequest =
          isTaskModule &&
          (methodName === OhsApiRequestName.List ||
            methodName === OhsApiRequestName.ListAssigned ||
            methodName === OhsApiRequestName.BookmarkedList ||
            methodName === OhsApiRequestName.ListSignedOff);

        const isSuperSetCustomListRequest =
          isSuperSetModule && methodName === OhsApiRequestName.CustomList;

        if (
          isSearchListRequest ||
          isSearchWithScheduleRequest ||
          isTaskListRequest ||
          isSuperSetCustomListRequest
        ) {
          return response.data; // return full response - ApiResponseType<ApiResListsType<any[]>>
        }

        if (
          (methodType === ModuleType.Xsi && methodName === OhsApiRequestName.List) ||
          (methodType === ModuleType.Xsi && methodName === OhsApiRequestName.Create) ||
          methodName === OhsApiRequestName.XsiSetupLink ||
          methodType === ModuleType.Linkaccess
        )
          return response.data; // ApiResponseType<any> | null>

        if (
          methodName === OhsApiRequestName.List ||
          methodName === OhsApiRequestName.ListScheduled ||
          methodName === OhsApiRequestName.ListAvailable ||
          methodName === OhsApiRequestName.ListAssigned ||
          methodName === OhsApiRequestName.SiteList ||
          methodName === OhsApiRequestName.VisitList ||
          methodName === OhsApiRequestName.FormList ||
          methodName === OhsApiRequestName.ListRoll ||
          methodName === OhsApiRequestName.ListExchange ||
          methodName === OhsApiRequestName.SearchExchange ||
          methodName === OhsApiRequestName.ListProse ||
          methodName === OhsApiRequestName.BookmarkedList ||
          methodName === OhsApiRequestName.NoticeBoardList ||
          (methodType === ModuleType.VersionDocument &&
            methodName === OhsApiRequestName.Copylist) ||
          methodName === OhsApiRequestName.ExportListOrg ||
          methodName === OhsApiRequestName.ExportListUser ||
          methodName === OhsApiRequestName.ExportTemplateList ||
          methodName === OhsApiRequestName.ContractorLinkedCorrespondence ||
          methodName === OhsApiRequestName.LinkedCorrespondenceAll ||
          methodName === OhsApiRequestName.ContractorLookup ||
          methodName === OhsApiRequestName.ListLinkable ||
          methodName === OhsApiRequestName.TaskAggregate ||
          methodName === OhsApiRequestName.Copylist ||
          methodName === OhsApiRequestName.HRLookup
        )
          return response.data.result; // ApiResListsType<any[]>

        if (
          methodName === OhsApiRequestName.MfaEnroll ||
          methodName === OhsApiRequestName.MfaDeleteMethod ||
          methodName === OhsApiRequestName.MfaReset ||
          methodName === OhsApiRequestName.MfaEnrollConfirm ||
          methodName === OhsApiRequestName.MfaVerify ||
          methodName === OhsApiRequestName.AssignAggregate ||
          methodName === OhsApiRequestName.BookmarkedListByTask ||
          methodName === OhsApiRequestName.GetToken ||
          methodName === OhsApiRequestName.GetTokenWidgets ||
          methodName === OhsApiRequestName.MobileAppAdminVersionList ||
          methodName === OhsApiRequestName.ContractorLinkedTasks ||
          methodName === OhsApiRequestName.MfaCancel ||
          methodName === OhsApiRequestName.EditExchange
        )
          return response.data;

        if (methodName === OhsApiRequestName.ResetPassword) return response.data;

        if (response.data.result.item) return response.data.result.item;
        if (response.data.result.items) return response.data.result.items;

        if (importsModules.includes(methodName)) {
          return response.data;
        }

        return response.data.result;
      }

      if (_.has(response, 'data.success') && response.data.success === false) {
        if (methodType === ModuleType.Linkaccess) {
          return response.data;
        }
        OhsApiServices.handleOhsApiErrors(response, methodName, options);
        return response.data;
      }

      return null;
    })
    .catch((error) => {
      console.error(error);
      OhsApiServices.showError(getApiRequestErrorMessage(methodName, error), options);
      return null;
    });
};

export default OhsApiRequest;
