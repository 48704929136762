/* eslint-disable react/prop-types */
import React from 'react';

import {
  Row,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useExpanded,
  useFilters,
  ColumnInstance,
} from 'react-table';
import { Icon } from 'semantic-ui-react';
import update from 'immutability-helper';
// import { useSelector } from 'react-redux';
import { Checkbox } from '@mui/material';

import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';
import useWindowSize from 'global-components/screenSize/useWindowSize';

import OhsRegisterTableColOperationButtons from './table/rowButtons/OhsRegisterTableColOperationButtons';
import OhsRegisterTableHeaderSearchBar from './table/OhsRegisterTableHeaderSearchBar';
import OhsRegisterTablePagination from './table/OhsRegisterTablePagination';
import OhsRegisterTable from './table/OhsRegisterTable';
import OhsRegisterCardList from './cardList/OhsRegisterCardList';
import { RowOperations, expanderColumnId, searchColumnId } from './OhsRegisterModels';

interface RegisterTableProps {
  columns: Array<any>;
  data: Array<any>;
  sortBy?: { id: string; desc: boolean }[];
  setSelectedRows?: React.Dispatch<React.SetStateAction<Row<any>[]>>;
  setRows?: (data: any[]) => void;
  hasExpanding?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  rowOperations?: RowOperations;
  enableDragDrop?: boolean;
  useTablePagination?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  useTableSearch?: boolean;
  hideColumns?: string[];
  hideHeader?: boolean;
  columnSorting?: boolean;
  isLoading?: boolean;
  onRowClick?: (item: any) => void;
  getCurrentSearchedRows?: (data: any[]) => void;
}

const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }: any, ref) => {
  const defaultRef = React.useRef() as any;
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Checkbox ref={resolvedRef} {...rest} />;
});

const tableSelectionColumn = (columns: ColumnInstance<object>[]) => [
  {
    id: 'selection',
    Header: ({ getToggleAllRowsSelectedProps }: any) => (
      <div>
        <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
      </div>
    ),
    Cell: ({ row }: any) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
  },
  ...columns,
];

const hasAllocationColumn = (columns: ColumnInstance<object>[]) => [
  {
    // Build our expander column
    id: expanderColumnId, // Make sure it has an ID
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }: any) => {
      return (
        <span
          {...getToggleAllRowsExpandedProps()}
          style={{ width: '20px', padding: '5px 0 5px 0' }}
        >
          {isAllRowsExpanded ? <Icon name="chevron down" /> : <Icon name="chevron right" />}
        </span>
      );
    },
    Cell: ({ row }: any) => {
      // eslint-disable-next-line no-nested-ternary
      return row.canExpand ? (
        <span
          {...row.getToggleRowExpandedProps({})}
          style={{ width: '20px', padding: '5px 0 5px 0' }}
        >
          {row.isExpanded ? <Icon name="chevron down" /> : <Icon name="chevron right" />}
        </span>
      ) : row.depth !== 0 ? (
        <Icon
          color={row.original.allocationActive ? 'green' : 'red'}
          name={`arrow ${row.original.allocationActive ? 'right' : 'left'}`}
        />
      ) : null;
    },
  },
  ...columns,
];

const tableSearchColumn = (columns: ColumnInstance<object>[], props: RegisterTableProps) => [
  ...columns,
  {
    id: searchColumnId,
    Header: (header: any) =>
      props.useTableSearch && props.useTableSearch ? (
        <OhsRegisterTableHeaderSearchBar header={header} />
      ) : (
        <div />
      ),
    Cell: (data: any) => {
      const { row } = data;
      if (typeof row.original === 'string' && row.original.includes('Sent')) return '';

      data.cell.column.customStyle = {
        textAlign: 'left',
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        boxSizing: 'border-box',
      };
      return <OhsRegisterTableColOperationButtons row={row} rowOperations={props.rowOperations} />;
    },
  },
];

function OhsRegister(props: RegisterTableProps) {
  const [records, setRecords] = React.useState(new Array<any>());
  const screenSize = useWindowSize();
  const screenWidth = screenSize.width;

  const tableColumns = React.useMemo(() => props.columns, [props.columns]);
  const getTableOptions = {
    columns: tableColumns,
    data: records,
    initialState: {
      pageSize: 100,
      hiddenColumns:
        props.hideColumns && props.hideColumns.length > 0
          ? props.hideColumns
          : tableColumns
              .filter((col: any) => col.show === false)
              .map((col) => col.accessor || col.id),
    },
    autoResetSortBy: false,
    manualGlobalFilter: true,
  } as any;

  const tableOptions = React.useMemo(() => getTableOptions, [getTableOptions]);

  if (!props.useTablePagination) {
    tableOptions.initialState.pageSize = 100000;
  }
  if (props.sortBy && props.sortBy.length > 0) {
    tableOptions.initialState.sortBy = props.sortBy;
  }
  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
    pageOptions,
    gotoPage,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
    rows, // Rows after filtering
  } = useTable(
    tableOptions,
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,

    (hooks) => {
      if (props.setSelectedRows != null) {
        hooks.visibleColumns.push((columns: ColumnInstance<object>[]) =>
          tableSelectionColumn(columns)
        );
      }
      if (props.hasExpanding === true) {
        hooks.visibleColumns.push((columns: ColumnInstance<object>[]) =>
          hasAllocationColumn(columns)
        );
      }

      hooks.visibleColumns.push((columns: ColumnInstance<object>[]) =>
        tableSearchColumn(columns, props)
      );
    }
  ) as any;

  const visibleColumnIds = tableOptions.columns
    .map((column: any) => column.accessor || column.id)
    .filter((id: any) => typeof id === 'string' && id.trim() !== '');
  const getNestedValue = (obj: any, path: string) => {
    return path
      ?.split('.')
      ?.reduce(
        (acc: any, key: string) => (acc && acc[key] !== undefined ? acc[key] : undefined),
        obj
      );
  };
  React.useEffect(() => {
    if (globalFilter?.trim()?.length > 0) {
      const filtered = props.data.filter((item) => {
        const lowerCaseFilter = globalFilter.trim().toLowerCase();
        return visibleColumnIds.some((columnId: string) => {
          const value = getNestedValue(item, columnId);
          return (
            value !== undefined && String(value)?.trim()?.toLowerCase().includes(lowerCaseFilter)
          );
        });
      });

      setRecords(filtered);
    } else {
      setRecords(props.data);
    }
  }, [globalFilter?.trim() || undefined, props.data]);

  React.useEffect(() => {
    if (props.setSelectedRows && selectedFlatRows) {
      props.setSelectedRows(selectedFlatRows);
    }
  }, [selectedFlatRows]);

  React.useEffect(() => {
    // Rows after filtering
    if (props.getCurrentSearchedRows) {
      props.getCurrentSearchedRows(rows);
    }
  }, [rows]);

  const moveRow = (dragIndex: number, hoverIndex: number) => {
    const dragRecord = records[dragIndex];
    setRecords(
      update(records, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
    );
    if (props.setRows) {
      props.setRows(
        update(records, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRecord],
          ],
        })
      );
    }
  };
  return (
    <>
      {screenWidth >= SmallScreenWidthBreakpoint && (
        <OhsRegisterTable
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          page={page}
          prepareRow={prepareRow}
          selectedFlatRows={selectedFlatRows}
          setSelectedRows={props.setSelectedRows}
          moveRowFunc={moveRow}
          enableDragDrop={props.enableDragDrop}
          hideHeader={props.hideHeader}
          isLoading={props.isLoading ?? false}
          columnSorting={props.columnSorting}
          onRowClick={props.onRowClick}
        />
      )}
      {screenWidth < SmallScreenWidthBreakpoint && (
        <OhsRegisterCardList
          getTableProps={getTableProps}
          headerGroups={headerGroups}
          page={page}
          prepareRow={prepareRow}
          selectedFlatRows={selectedFlatRows}
          setSelectedRows={props.setSelectedRows}
          setGlobalFilter={setGlobalFilter}
          globalFilter={globalFilter}
          isLoading={props.isLoading ?? false}
          onRowClick={props.onRowClick}
          useTableSearch={props.useTableSearch}
        />
      )}

      {props.useTablePagination === true && pageOptions && pageOptions.length > 1 && (
        <OhsRegisterTablePagination
          gotoPage={gotoPage}
          pageIndex={pageIndex}
          totalPages={pageOptions.length}
        />
      )}
    </>
  );
}

OhsRegister.defaultProps = {
  sortBy: [],
  setSelectedRows: undefined,
  setRows: undefined,
  hasExpanding: false,
  rowOperations: {},
  enableDragDrop: false,
  useTablePagination: false,
  useTableSearch: false,
  hideColumns: [],
  hideHeader: false,
  isLoading: false,
  columnSorting: false,
  onRowClick: undefined,
  getCurrentSearchedRows: undefined,
};

export default OhsRegister;
