import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';
import OhsMultipleSelectField from '../../../global-components/form/fields/OhsMultipleSelectField';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}

function OhsDocumentWorkplaceFilters(props: Props) {
  const { classes } = useStyles();
  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const user = OhsUserLocalServices.getLocalOhsUser();
  const categories = user?.configs.vdoc?.CATEGORY_LIST ?? [];
  const userTierNum = user?.tierNum ?? 0;

  const [subCategoryLists, setSubCategoryLists] = React.useState<string[]>([]);

  React.useEffect(() => {
    const slist = categories.map((a: any) => a.subcategory);
    const getSubCategories = _.sortBy(_.uniqBy([...new Set(slist.flat())], (item) => item));
    setSubCategoryLists(getSubCategories);
  }, []);

  return (
    <Box className={classes.filterContent}>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id="documentsModule.workplace.sort"
          required
          options={filter.documentsModule.workplace.tableSortKeyLists.sortColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {userTierNum === 3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter ohsFormSettings={props.ohsFormSettings} />
        </Grid>
      )}

      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <div>
          <OhsMultipleSelectField
            title="Document Type"
            id="documentsModule.workplace.categories"
            required={false}
            options={categories.map((c) => ({
              name: c.category,
              value: c.category,
            }))}
            ohsFormSettings={props.ohsFormSettings}
          />
        </div>
      </Grid>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <div>
          <OhsMultipleSelectField
            title="Document Category"
            id="documentsModule.workplace.subcategories"
            required={false}
            options={subCategoryLists.map((category: any) => ({
              name: category,
              value: category,
            }))}
            ohsFormSettings={props.ohsFormSettings}
          />
        </div>
      </Grid>
    </Box>
  );
}

export default OhsDocumentWorkplaceFilters;
