import React from 'react';

import OhsModal from 'global-components/modal/OhsModal';
import OhsSessionServices from 'user/session/OhsSessionServices';
import OhsLogoutModal from 'login/OhsLogoutModal';

import OhsMfaVerifyFlow from './OhsMfaVerifyFlow';
import { userMfaCancel } from '../enrollflows/OhsMfaServices';

interface Props {
  open: boolean;
  title: string;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: (methodId?: string) => void;
  onClose?: () => void;
}

function OhsMfaVerifyModal(props: Props) {
  const [logoutModalOpen, setLogoutModalOpen] = React.useState(false);
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    } else {
      // Cancel MFA
      const activeToken = OhsSessionServices.getPriorActiveToken();
      if (activeToken) {
        userMfaCancel();
      } else {
        setLogoutModalOpen(true);
      }
    }
  };
  return (
    <>
      <OhsModal
        open={props.open}
        title={props.title}
        setModalOpenFunc={props.setModalOpen}
        maxWidth="xs"
        headerTextColor="black"
        headerColor="#fafafa"
        ContentNode={<OhsMfaVerifyFlow onSuccess={props.onSuccess} />}
        onClose={handleClose}
      />
      <OhsLogoutModal open={logoutModalOpen} setLogoutModalOpen={setLogoutModalOpen} />
    </>
  );
}

OhsMfaVerifyModal.defaultProps = {
  onClose: undefined,
};

export default OhsMfaVerifyModal;
