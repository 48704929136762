// Example usage
// const decoded = decodeJWT(token);
// console.log('Header:', decoded.header)
// console.log('Payload:', decoded.payload);
// console.log('Signature:', decoded.signature);
function decodeJWT(token: string) {
  try {
    // Split the token into parts: Header, Payload, Signature
    const parts = token.split('.');
    if (parts.length !== 3) {
      throw new Error('Invalid JWT token');
    }

    // Decode Base64Url (replace - with +, _ with /)
    const decodeBase64Url = (str: string) => {
      const base64 = str.replace(/-/g, '+').replace(/_/g, '/');
      const padded = base64.padEnd(base64.length + ((4 - (base64.length % 4)) % 4), '=');
      return atob(padded);
    };

    // Parse the decoded strings
    const header = JSON.parse(decodeBase64Url(parts[0]));
    const payload = JSON.parse(decodeBase64Url(parts[1]));
    const signature = parts[2]; // Not decoded (binary data)

    return { header, payload, signature };
  } catch (error) {
    console.error('Error decoding JWT:', error);
    return null;
  }
}
const OhsLoginUtils = { decodeJWT };

export default OhsLoginUtils;
