import React from 'react';

import { TableCellProps } from '@mui/material';

import OhsRegisterTableBodyCell from './OhsRegisterTableBodyCell';
import OhsRegisterTableRow from './OhsRegisterTableRow';

interface Props {
  row: any;
  index: number;
  onRowClick?: (item: any) => void;
}

export default function OhsRegisterTableMoveRow(props: Props) {
  const handleClick = () => {
    if (props.onRowClick) {
      props.onRowClick(props.row);
    }
  };
  return (
    <OhsRegisterTableRow
      key={props.index}
      {...props.row.getRowProps()}
      {...(props.onRowClick && { onClick: handleClick })}
    >
      {props.row.cells.map(
        (
          cell: {
            column: any;
            getCellProps: () => JSX.IntrinsicAttributes & TableCellProps;
            render: (arg0: string) => React.ReactNode;
          },
          index: number
        ) => {
          return (
            <OhsRegisterTableBodyCell
              {...cell.getCellProps()}
              key={props.index.toString() + index}
              style={{
                width: cell.column.customWidth ? cell.column.customWidth : 'auto',
                padding: '6px 10px',
                minHeight: '200px',
                ...(cell.column.customStyle ? { ...cell.column.customStyle } : {}),
              }}
            >
              {cell.render('Cell')}
            </OhsRegisterTableBodyCell>
          );
        }
      )}
    </OhsRegisterTableRow>
  );
}

OhsRegisterTableMoveRow.defaultProps = {
  onRowClick: undefined,
};
