import React from 'react';

import _ from 'lodash';
import { makeStyles } from 'tss-react/mui';
import { IconButton, Typography } from '@mui/material';
import { Icon } from 'semantic-ui-react';

import { OhsAttachment } from './OhsAttachmentModels';
import {
  downloadAttachment,
  downloadFileFromURL,
  fileSizeParse,
  openOfficeFile,
} from './OhsAttachmentServices';
import OhsImageViewerModal, { OhsImageViewerItem } from './OhsImageViewerModal';

const ExcelFileExtensions = ['xlsx', 'xlsb', 'xls', 'xlsm', 'csv'];
const PdfFileExtensions = ['pdf'];
const PptFileExtensions = ['pptx', 'ppsx', 'ppt', 'pps', 'potx', 'ppsm'];
const WordFileExtensions = ['doc', 'docx', 'dotx'];
const ImgFileExtensions = ['png', 'gif', 'jpg', 'jpeg', 'webp'];
function isFileBelongTo(fileName: string | undefined, fileExtentionList: string[]): boolean {
  let flag = false;
  if (fileName) {
    fileExtentionList.forEach((item) => {
      if (fileName.toLowerCase().indexOf(`.${item}`) > 0) {
        flag = true;
      }
    });
  }
  return flag;
}

interface Props {
  attachments: OhsAttachment[];
  setAttachments?: (attachments: OhsAttachment[]) => void;
  disabled?: boolean;
  disableImageViewer?: boolean;
}

const useStyles = makeStyles()(() => ({
  fileItem: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
  },
  imageHolder: {
    width: '2.5em',
  },
  image: {
    width: '100%',
  },
}));

function OhsAttachmentList(props: Props) {
  const { classes } = useStyles();
  const [open, setOpen] = React.useState(false);
  const [imgIndex, setImgIndex] = React.useState(0);
  const [images, setImages] = React.useState(new Array<OhsImageViewerItem>());

  const toggleDelete = (attachment: OhsAttachment) => {
    if (_.isUndefined(props.setAttachments)) return;
    const attachmentList = [...props.attachments];
    const targetIndex = attachmentList.findIndex((item) => attachment.key === item.key);

    if (targetIndex === -1) return;

    if (attachment.isDeleted !== true) {
      attachmentList[targetIndex] = { ...attachmentList[targetIndex], isDeleted: true };
    } else {
      delete attachmentList[targetIndex].isDeleted;
    }

    props.setAttachments(attachmentList);
  };

  const openImageModal = (index: number) => {
    const attachment = props.attachments[index];
    const targetIndex = _.findIndex(images, { original: attachment.url });
    setOpen(true);
    setImgIndex(targetIndex);
  };

  React.useEffect(() => {
    const imageList = props.attachments.filter(
      (attachment) =>
        isFileBelongTo(attachment.fileName, ImgFileExtensions) === true && attachment.url
    );
    const newImages = imageList.map((image) => ({
      original: image.url ?? '',
      thumbnail: image.url ?? '',
    }));
    setImages(newImages);
  }, [props.attachments]);

  return (
    <div>
      {props.attachments.map((attachment, index) => (
        <div key={attachment.key} className={classes.fileItem}>
          {isFileBelongTo(attachment.fileName, ExcelFileExtensions) && (
            <IconButton
              onClick={() => {
                openOfficeFile(attachment.url);
              }}
            >
              <Icon name="file excel outline" size="big" color="blue" />
            </IconButton>
          )}
          {isFileBelongTo(attachment.fileName, PptFileExtensions) && (
            <IconButton
              onClick={() => {
                openOfficeFile(attachment.url);
              }}
            >
              <Icon name="file powerpoint outline" size="big" color="blue" />
            </IconButton>
          )}
          {isFileBelongTo(attachment.fileName, WordFileExtensions) && (
            <IconButton
              onClick={() => {
                openOfficeFile(attachment.url);
              }}
            >
              <Icon name="file word outline" size="big" color="blue" />
            </IconButton>
          )}
          {isFileBelongTo(attachment.fileName, PdfFileExtensions) && (
            <IconButton
              onClick={() => {
                downloadFileFromURL(attachment.url as string, '_blank');
              }}
            >
              <Icon name="file pdf outline" size="big" color="blue" />
            </IconButton>
          )}
          {isFileBelongTo(attachment.fileName, ImgFileExtensions) && (
            <IconButton
              onClick={() => {
                if (props.disableImageViewer !== true) openImageModal(index);
              }}
              href={props.disableImageViewer === true ? attachment.url ?? '' : ''}
            >
              <div className={classes.imageHolder}>
                <img className={classes.image} src={attachment.url} alt="" />
              </div>
            </IconButton>
          )}
          {attachment.fileName &&
            isFileBelongTo(
              attachment.fileName,
              ExcelFileExtensions.concat(PdfFileExtensions)
                .concat(PptFileExtensions)
                .concat(WordFileExtensions)
                .concat(ImgFileExtensions)
            ) === false && (
              <IconButton href={attachment.url ?? ''}>
                <Icon name="file outline" size="big" color="blue" />
              </IconButton>
            )}
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 'bold', color: '#5cd2f7', cursor: 'pointer' }}
            onClick={() => {
              downloadAttachment(attachment);
            }}
          >
            {attachment.fileName}({fileSizeParse(attachment.size)})
          </Typography>
          {props.setAttachments && !props.disabled && (
            <IconButton
              onClick={() => {
                toggleDelete(attachment);
              }}
            >
              {attachment.isDeleted !== true && <Icon name="trash" />}
              {attachment.isDeleted === true && <Icon name="recycle" />}
            </IconButton>
          )}
        </div>
      ))}
      <OhsImageViewerModal open={open} setOpen={setOpen} imageList={images} startIndex={imgIndex} />
    </div>
  );
}

OhsAttachmentList.defaultProps = {
  setAttachments: undefined,
  disabled: false,
  disableImageViewer: false,
};

export default OhsAttachmentList;
