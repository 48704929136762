import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLevelDownAlt, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons';
import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { useAppDispatch } from 'app/hooks';
import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';
import { toggleRightSideDrawer } from 'dashboard/OhsDashboardSlice';
import { unMorphUser } from 'user/morph/MorphServices';
import checkCurrentUrlAfterMorph from 'user/morph/UrlCheckServices';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import { OhsMaterialIconButton } from '../../global-components/buttons/OhsMaterialButton';

const useStyles = makeStyles()((theme: Theme) => ({
  iconButton: {
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
    },
    [theme.breakpoints.up(SmallScreenWidthBreakpoint)]: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
    },
  },
}));

export default function OhsMorphButtons() {
  const user = OhsUserLocalServices.getLocalOhsUser();
  const dispatch = useAppDispatch();

  const { classes } = useStyles();
  const unMorph = async () => {
    const response: any = await unMorphUser();

    if (response) {
      checkCurrentUrlAfterMorph(response.tier);
    }
  };
  const toggleMorphDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    dispatch(toggleRightSideDrawer(open));
  };

  let button;
  if (user?.isMorph === true) {
    button = (
      <OhsMaterialIconButton
        color="inherit"
        onClick={() => unMorph()}
        size="small"
        className={classes.iconButton}
      >
        {user.tierNum === 2 ? (
          <FontAwesomeIcon icon={faLevelDownAlt} />
        ) : (
          <FontAwesomeIcon icon={faLevelUpAlt} />
        )}
      </OhsMaterialIconButton>
    );
  } else {
    button = (
      <OhsMaterialIconButton
        color="inherit"
        onClick={toggleMorphDrawer(true)}
        size="small"
        className={classes.iconButton}
      >
        <FontAwesomeIcon icon={faLevelDownAlt} />
      </OhsMaterialIconButton>
    );
  }
  return button;
}
