import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import { getOhsSafetyPlanLists } from 'safety-plan/store/OhsSafetyPlanSlice';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import OhsCheckboxFilter from '../filterfields/OhsCheckboxFilter';
import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';
import OhsCategoriesMultSelectFilter from '../filterfields/OhsCategoriesFilter';
import OhsTaskDueDateFilter from '../filterfields/OhsTaskDueDateFilter';
import { OhsSafetyPlanViewPresets } from '../OhsSafetyPlanFilterModels';
import OhsSubcategoriesMultiSelectFilter from '../filterfields/OhsSubcategoriesFilter';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}

function OhsSafetyPlanFilters(props: Props) {
  const { classes } = useStyles();

  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const user = OhsUserLocalServices.getLocalOhsUser();
  const safetyPlan = useAppSelector(getOhsSafetyPlanLists);
  const getModuleCategoryLists = user?.configs.safetyplan?.CLASSIFICATION_LIST ?? [];
  const viewPreset = safetyPlan.currentViewPreset as unknown as OhsSafetyPlanViewPresets;

  return (
    <Box className={classes.filterContent}>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id="safetyPlanModule.sort"
          required
          options={filter.safetyPlanModule.tableSortKeyLists?.sortColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>

      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsCategoriesMultSelectFilter
          ohsFormSettings={props.ohsFormSettings}
          id={`safetyPlanModule.${viewPreset}.categories`}
          options={getModuleCategoryLists}
        />
      </Grid>

      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSubcategoriesMultiSelectFilter
          ohsFormSettings={props.ohsFormSettings}
          id={`safetyPlanModule.${viewPreset}.subcategories`}
          categoriesList={getModuleCategoryLists}
        />
      </Grid>

      {[
        OhsSafetyPlanViewPresets.View2SafetyPlanList,
        OhsSafetyPlanViewPresets.View1SafetyPlanSchedList,
      ].includes(viewPreset) && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter
            id={`safetyPlanModule.${viewPreset}.workplaces`}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}

      {[
        OhsSafetyPlanViewPresets.View1SafetyPlanSchedList,
        OhsSafetyPlanViewPresets.View2SafetyPlanSchedList,
      ].includes(viewPreset) && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsTaskDueDateFilter
            label="Task Due Date"
            fieldId={`safetyPlanModule.${viewPreset}.dateDue`}
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}

      {[
        OhsSafetyPlanViewPresets.View2SafetyPlanList,
        OhsSafetyPlanViewPresets.View1SafetyPlanSchedList,
      ].includes(viewPreset) && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`safetyPlanModule.${viewPreset}.allocatedToOrg`}
            label="Allocated to Organisation"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}

      {[OhsSafetyPlanViewPresets.View2SafetyPlanList].includes(viewPreset) && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`safetyPlanModule.${viewPreset}.notAllocated`}
            label="Not Allocated"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {[
        OhsSafetyPlanViewPresets.View2SafetyPlanList,
        OhsSafetyPlanViewPresets.View3SafetyPlanList,
        OhsSafetyPlanViewPresets.View1SafetyPlanSchedList,
        OhsSafetyPlanViewPresets.View2SafetyPlanSchedList,
      ].includes(viewPreset) && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`safetyPlanModule.${viewPreset}.allocatedToWorkplace`}
            label="Allocated to Workplace"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {[
        OhsSafetyPlanViewPresets.View2SafetyPlanList,
        OhsSafetyPlanViewPresets.View3SafetyPlanList,
        OhsSafetyPlanViewPresets.View1SafetyPlanSchedList,
        OhsSafetyPlanViewPresets.View2SafetyPlanSchedList,
      ].includes(viewPreset) && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid={`safetyPlanModule.${viewPreset}.workplaceOwned`}
            label="Workplace Owned"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
    </Box>
  );
}

export default OhsSafetyPlanFilters;
