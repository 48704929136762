/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';

import _ from 'lodash';
import { Row } from 'react-table';
import { makeStyles } from 'tss-react/mui';
import clsx from 'clsx';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Divider from '@mui/material/Divider';
import { Theme } from '@mui/material/styles';
import {
  Box,
  CardActions,
  IconButton,
  OutlinedInput,
  IconButtonProps,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import InputAdornment from '@mui/material/InputAdornment';
import { styled } from '@mui/styles';
import { Icon } from 'semantic-ui-react';

import { setTierName } from 'global-services/constants/tier/OhsTierServices';
import OhsComponentLoading from 'global-services/utils/OhsLoading';
import useWindowSize from 'global-components/screenSize/useWindowSize';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import { expanderColumnId, searchColumnId } from '../OhsRegisterModels';
import OhsNoResultsAlert from '../OhsNoResultsAlert';

const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    margin: '10px -5px 10px -5px',
    boxShadow: 'none',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px 0 0',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '15px',
    lineHeight: '20px',
  },
  headerWrap: {
    background: '#f7f7f7',
    padding: '4px 8px 4px 8px',
    position: 'relative',
    minHeight: '40px',
    '.MuiCardHeader-action': {
      margin: '0 -5px 0 0',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      flex: 'none',
      alignSelf: 'auto',
    },
    '.ui[class*="right floated"].buttons': {
      margin: '0 !important',
    },
  },
  listContent: {
    padding: '5px 0 10px 0 !important',
    marginTop: '5px',
  },
  listitem: {
    display: 'flex',
    alignItems: 'center',
    padding: '3px 5px 3px 0',
    borderTop: '1px solid #f1f1f1',
    lineHeight: '20px',
    '& b': {
      display: 'flex',
      background: '#fafafa',
      padding: '5px 5px 5px 8px',
      minWidth: '40%',
      marginRight: '5px',
    },
  },
  allocationList: {
    background: '#f7f7f7',
    padding: '0',
    margin: '0',
    display: 'flex',
    fontSize: '10px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  allocItem: {
    position: 'relative',
    border: 'none',
    marginTop: '5px',
    boxShadow: 'none',
    alignItems: 'center',
  },
  actionsWrap: {
    width: '100%',
    background: '#f7f7f7',
    padding: '0 10px',
    justifyContent: 'space-between',
    '.ui[class*="right floated"].buttons': {
      margin: '0!important',
    },
  },
  allocIcon: {
    color: 'green',
    marginRight: '5px',
    justifyContent: 'left',
  },
  deAllocItem: {
    color: 'red!important',
  },
  cardHeader: {
    fontSize: '1.28571429rem',
    fontWeight: 'bold',
    backgroundColor: theme.palette.custom?.HEADER_BAR_BG_COLOR,
    color: theme.palette.custom?.MAIN_BG_COLOR,
  },
  actionCell: {
    clear: 'both',
    position: 'absolute',
    right: '5px',
    '.ui[class*="right floated"].buttons': {
      margin: '5px 0!important',
    },
  },
  cardBottom: {
    paddingBottom: '2px',
    marginBottom: '2px',
  },
  expanderCellHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
}));

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions?.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface Props {
  setSelectedRows?: React.Dispatch<React.SetStateAction<Row<any>[]>>;
  rowFilterNode?: React.ReactNode;
  getTableProps: any;
  headerGroups: any;
  page: any;
  prepareRow: any;
  selectedFlatRows: any;
  setGlobalFilter: any;
  globalFilter: any;
  isLoading: boolean;
  onRowClick?: (item: any) => void;
  useTableSearch?: boolean;
}

function OhsRegisterCardList(props: Props) {
  const { classes } = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [searchKey, setSearchKey] = React.useState(props.globalFilter);
  const user = OhsUserLocalServices.getLocalOhsUser();
  const moduleConfig = user?.configs?.safetyplan;
  const screenSize = useWindowSize();

  const cleanKey = () => {
    setSearchKey('');
    props.setGlobalFilter(undefined);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue: any = event.target.value;
    props.setGlobalFilter(newValue || undefined);
    setSearchKey(newValue);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  React.useEffect(() => {
    if (props.setSelectedRows) {
      props.setSelectedRows(props.selectedFlatRows);
    }
  });

  return (
    <>
      {props.useTableSearch && (
        <OutlinedInput
          size="small"
          sx={{ width: '100%', padding: '0' }}
          value={searchKey}
          onChange={handleChange}
          placeholder="Search"
          endAdornment={
            <InputAdornment position="end">
              {_.isNil(searchKey) || searchKey === '' ? (
                <IconButton type="submit">
                  <SearchIcon />
                </IconButton>
              ) : (
                <IconButton onClick={cleanKey}>
                  <ClearIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      )}

      {props.isLoading && (
        <CardContent>
          <OhsComponentLoading />
        </CardContent>
      )}
      {props.page.map((row: any, index: number) => {
        props.prepareRow(row);
        // eslint-disable-next-line consistent-return
        const titleCell = _.find(row.cells, (cell) => {
          if (cell.value != null) return cell;
        });
        const contentCells = row.cells.filter(
          (cell: any) =>
            // cell.value != null &&
            cell.column.id !== titleCell.column.id &&
            cell.column.id !== 'searchCol' &&
            cell.column.id !== 'expander' &&
            cell.column.id !== 'select'
        );
        const expanderCell = _.find(row.cells, {
          column: { id: expanderColumnId },
        });
        const operationCell = _.find(row.cells, {
          column: { id: searchColumnId },
        });
        const allocCell = contentCells[contentCells.length - 1];
        const allocatedDeallocated = allocCell?.row?.original?.allocationActive;
        const contentSelectCell = row.cells.filter((cell: any) => cell.column.id === 'select');
        return (
          <React.Fragment key={index}>
            <Card
              className={clsx({
                [classes.root]: row.depth == null || row.depth === 0,
                [classes.allocItem]: row.depth == null || row.depth !== 0,
              })}
              {...(props.onRowClick && { onClick: props.onRowClick })}
            >
              <CardHeader
                titleTypographyProps={{
                  fontSize: '12px',
                }}
                action={operationCell && screenSize.width > 381 && operationCell.render('Cell')}
                title={
                  row.depth == null || row.depth === 0 ? (
                    <Typography className={classes.title} variant="subtitle1">
                      {contentSelectCell.map((cell: any, cIndex: number) => {
                        return (
                          <Typography className={classes.listitem} variant="subtitle1" key={cIndex}>
                            {cell.column.Cell ? cell.render('Cell') : cell.value}
                          </Typography>
                        );
                      })}{' '}
                      {titleCell.value}
                    </Typography>
                  ) : (
                    <Box
                      className={clsx({
                        [classes.allocationList]: true,
                        [classes.allocIcon]: true,
                        [classes.deAllocItem]: !allocatedDeallocated,
                      })}
                    >
                      <Icon
                        name="arrow right"
                        className={clsx({
                          [classes.allocIcon]: true,
                          [classes.deAllocItem]: !allocatedDeallocated,
                        })}
                      />
                      <Typography
                        paragraph
                        m={0}
                        pl={1}
                        sx={{ textAlign: 'left', maxWidth: '220px' }}
                      >
                        {allocatedDeallocated
                          ? `Allocated to ${setTierName(allocCell.row.original.tier).name ?? ''}`
                          : `Deallocated from ${
                              setTierName(allocCell.row.original.tier).name ?? ''
                            }`}
                      </Typography>
                      <Box className={classes.actionCell}>
                        {operationCell && operationCell.render('Cell')}
                      </Box>
                    </Box>
                  )
                }
                className={classes.headerWrap}
              />
              {(row.depth == null || row.depth === 0) &&
                contentCells &&
                contentCells.length > 0 && (
                  <Box>
                    <CardContent className={classes.listContent}>
                      {contentCells.map((cell: any, cIndex: number) => {
                        return (
                          <Typography className={classes.listitem} variant="subtitle1" key={cIndex}>
                            <b>{cell.column.Header ? cell.render('Header') : cell.value} : </b>
                            {cell.column.Cell ? cell.render('Cell') : cell.value}
                          </Typography>
                        );
                      })}
                    </CardContent>
                  </Box>
                )}
              {contentCells && contentCells.length > 0 && (
                <Box className={classes.cardBottom}>
                  <CardActions className={classes.actionsWrap} disableSpacing>
                    {row.canExpand && row.subRows.length > 0 && expanderCell?.render('Cell') ? (
                      <Box className={classes.expanderCellHeader}>
                        {expanderCell?.render('Cell')}
                        <Box>Allocations</Box>
                      </Box>
                    ) : (
                      <Box />
                    )}
                    {operationCell && screenSize.width < 381 && (
                      <Box sx={{ clear: 'both' }}>
                        {operationCell && operationCell.render('Cell')}
                      </Box>
                    )}
                  </CardActions>
                </Box>
              )}
            </Card>
          </React.Fragment>
        );
      })}

      {props.isLoading === false && props.page.length === 0 && (
        <Box pt={1} pb={1}>
          <OhsNoResultsAlert>No item(s) found!</OhsNoResultsAlert>
        </Box>
      )}
    </>
  );
}

export default OhsRegisterCardList;
