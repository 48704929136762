import React from 'react';

import { Button, ModalDescription } from 'semantic-ui-react';
import { Box, Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import OhsModal from 'global-components/modal/OhsModal';
import OhsMessageBox from 'global-components/message-box/OhsMessageBox';
import { removeLocalStorage } from 'global-services/OhsDataParse';
import OhsUserLocalServices from 'user/OhsUserLocalServices';
import { SmallScreenWidthBreakpoint } from 'global-components/theme/OhsLayoutConstants';

import OhsLoginServices from './OhsLoginServices';

export const useStyles = makeStyles()((theme: Theme) => ({
  actionWrap: {
    width: '100%',
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row-reverse',
    marginBottom: '-5px',
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      flexDirection: 'column',
    },
  },
  trustBtn: {
    marginBottom: '5px !important',
    minWidth: '200px',
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      marginBottom: '1rem !important',
    },
  },
  logoutWrap: {
    width: '100%',
    display: 'flex',
    [theme.breakpoints.down(SmallScreenWidthBreakpoint)]: {
      flexDirection: 'column',
    },
  },
  actionBtn: {
    marginBottom: '10px',
  },
}));

interface Props {
  open: boolean;
  setLogoutModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function OhsLogoutModal(props: Props) {
  const { classes } = useStyles();
  const user = OhsUserLocalServices.getLocalOhsUser();
  const handleClose = () => {
    props.setLogoutModalOpen(false);
  };

  const handleTrustLogout = async () => {
    OhsLoginServices.logout(user?.email ?? '', false);
    removeLocalStorage('userData');
    props.setLogoutModalOpen(false);
  };

  // Filters out the session associated with the current user's email.
  // Saves the updated array of active sessions back to local storage.
  const handleClearUserLogout = async () => {
    OhsLoginServices.logout(user?.email ?? '', true);
    removeLocalStorage('userData');
    props.setLogoutModalOpen(false);
  };
  const handleCancel = async () => {
    props.setLogoutModalOpen(false);
  };

  return (
    <OhsModal
      open={props.open}
      title="Log out"
      onClose={handleClose}
      ContentNode={
        <ModalDescription>
          <OhsMessageBox
            title="Log out of safetychampion.online?"
            description="Trusting this browser will save your authentication and system settings,
             you will still be required to enter your user name and password to log back in."
          />
        </ModalDescription>
      }
      actionNode={
        <Box className={classes.actionWrap}>
          <Button className={classes.trustBtn} onClick={() => handleTrustLogout()} color="green">
            Trust and Log Out
          </Button>
          <Box className={classes.logoutWrap}>
            <Button onClick={() => handleClearUserLogout()} style={{ marginBottom: '5px' }}>
              Forget and Log Out
            </Button>
            <Button onClick={() => handleCancel()} style={{ marginBottom: '5px' }}>
              Cancel
            </Button>
          </Box>
        </Box>
      }
    />
  );
}

export default OhsLogoutModal;
