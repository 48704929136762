import _ from 'lodash';

import {
  OhsEmail,
  OhsMultiUser,
  OhsMultiUserSessionToken,
  OhsSessionToken,
  OhsUserStoredSessionsKey,
} from 'global-services/constants/OhsStorageNames';
import { OhsMultiUserLoginOption } from 'login/multi/OhsMultiUserLoginOption';

import OhsUserSession from './OhsUserSession';
import OhsSessionServices from './OhsSessionServices';

export function findIndexByProperty<T>(array: T[], property: keyof T, value: T[keyof T]): number {
  return array.findIndex((item) => item[property] === value);
}

export function checkUserSessionData(storedSessionsData: string | null): boolean {
  let isValidData = true;
  let storedUserSessions = [];
  try {
    if (_.isNull(storedSessionsData) || storedSessionsData === '') return false;

    // check if stored session data is valid - array and contains email property
    // [{ email: 'test@example.com', token: 'test-token', .... }, ..... }]
    if (storedSessionsData) {
      storedUserSessions = JSON.parse(storedSessionsData) as OhsUserSession[];
      isValidData = Array.isArray(storedUserSessions) && storedUserSessions.length > 0;

      // check if parsed session localstorage is not array
      if (!_.isArray(storedUserSessions)) return false;

      storedUserSessions.forEach((item) => {
        if (
          !_.has(item, OhsEmail) || // check if every item has email property
          !_.isString(item.email) // check if email is not empty
        ) {
          isValidData = false;
        }
        if (
          !_.has(item, OhsMultiUser) || // check if every item has multiuser property
          !_.isBoolean(item.multiUser) // check if multiuser property is boolean
        ) {
          isValidData = false;
        }
        if (_.has(item, OhsMultiUserSessionToken)) {
          // if multiuser session property, check if it is string
          if (!_.isString(item[OhsMultiUserSessionToken])) {
            isValidData = false;
          }
        }
        if (_.has(item, OhsSessionToken)) {
          // if session property, check if it is string
          if (!_.isString(item[OhsSessionToken])) {
            isValidData = false;
          }
        }
      });
    }
  } catch (error) {
    console.error(error);

    return false;
  }

  return isValidData;
}

class OhsUsersStoredSessions {
  private userSessions: OhsUserSession[] = [];

  constructor() {
    try {
      // Load existing data from localStorage or use the initial userSessions
      const storedData = localStorage.getItem(OhsUserStoredSessionsKey);
      const isValidSession = checkUserSessionData(storedData);

      this.userSessions = isValidSession && storedData ? JSON.parse(storedData) : [];
      // Skip duplicated records
      const newUserSessions = new Array<OhsUserSession>();
      const userEmailSet = new Set<string>();
      this.userSessions.forEach((item) => {
        if (item && item.email && userEmailSet.has(item.email) === false) {
          userEmailSet.add(item.email);
          newUserSessions.push(item);
        }
      });
      this.userSessions = newUserSessions;
    } catch (error) {
      this.userSessions = [];
      console.error(error);
    }
    this.saveToSessionsLocalStorage();
  }

  updateMultiUserOptions(multiUserResponse: any): OhsMultiUserLoginOption[] {
    const options = multiUserResponse.options as OhsMultiUserLoginOption[];
    const { email } = multiUserResponse;
    const currUser = this.getUserActiveSession(email);
    if (options && options.length && currUser && currUser.options) {
      options.forEach((item) => {
        const storedOptions = _.find(currUser.options, { user: { _id: item.user._id } });
        if (storedOptions && storedOptions.sessionToken) {
          item.sessionToken = storedOptions.sessionToken;
        }
      });
      currUser.options = options;
      this.updateUserSession(currUser);
    }
    if (options && options.length && currUser === null) {
      const newUser = new OhsUserSession(email, true);
      newUser.options = options;
      this.updateUserSession(newUser);
    }

    return options;
  }

  getUserActiveSession(email: string): OhsUserSession | null {
    const activeUser = _.find(this.userSessions, { email });
    if (activeUser && activeUser.email) {
      return activeUser;
    }
    return null;
  }

  getMultiUserTokenById(userId: string): string {
    let token = '';
    try {
      if (this.userSessions) {
        this.userSessions.forEach((item) => {
          if (item.options) {
            item.options.forEach((option) => {
              if (option.user._id === userId && option.sessionToken) {
                token = option.sessionToken;
              }
            });
          }
        });
      }
    } catch (error) {
      console.error(error);
    }

    return token;
  }

  // Save the current data array to localStorage
  private saveToSessionsLocalStorage(): void {
    localStorage.setItem(OhsUserStoredSessionsKey, JSON.stringify(this.userSessions));
  }

  // Add a new object to the array
  updateUserSession(item: OhsUserSession): void {
    if (item.email) {
      const index = _.findIndex(this.userSessions, { email: item.email });
      if (index > -1) {
        this.userSessions[index] = item;
      } else {
        this.userSessions.push(item);
      }
      this.saveToSessionsLocalStorage();
    }
  }

  // Remove an object from the array by index
  removeUserSession(email: string): void {
    const index = findIndexByProperty(this.userSessions, OhsEmail, email);

    if (index >= 0 && index < this.userSessions.length) {
      this.userSessions.splice(index, 1);

      this.saveToSessionsLocalStorage();
    }
  }

  removeUserSessionByToken(): void {
    const newSessionList = new Array<OhsUserSession>();
    const optionToken = OhsSessionServices.getOptionToken();
    const userToken = OhsSessionServices.getUserToken();
    for (let index = 0; index < this.userSessions.length; index += 1) {
      const oldSession = this.userSessions[index];
      let skip = false;
      if (
        !oldSession['x-safetychampion-multiuser-options-token'] ||
        !oldSession['x-safetychampion-token']
      ) {
        skip = true;
      }
      if (
        oldSession['x-safetychampion-multiuser-options-token'] &&
        oldSession['x-safetychampion-multiuser-options-token'] === optionToken
      ) {
        skip = true;
      }
      if (
        oldSession['x-safetychampion-token'] &&
        oldSession['x-safetychampion-token'] === userToken
      ) {
        skip = true;
      }
      if (skip === false) newSessionList.push(oldSession);
    }
    this.userSessions = newSessionList;
    this.saveToSessionsLocalStorage();
  }

  getUserSession(currentEmail: string): OhsUserSession | undefined {
    const index = findIndexByProperty(this.userSessions, OhsEmail, currentEmail);
    if (index >= 0 && index < this.userSessions.length) {
      const getUserSessions = this.userSessions[index];
      return getUserSessions;
    }
    return undefined;
  }

  // Get all data
  getAllUserSessions(): OhsUserSession[] {
    return [...this.userSessions];
  }
}

export default OhsUsersStoredSessions;
