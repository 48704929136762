/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { toast } from 'react-toastify';

import {
  CookiesExpiresDays,
  OhsLinkAccessAttachmentsDownloadHeader,
  OhsLinkAccessAttachmentsUploadHeader,
  OhsMultiUserSessionToken,
  OhsSessionToken,
} from 'global-services/constants/OhsStorageNames';
import { ModuleUrl } from 'global-services/constants/OhsRoutes';
import OhsUserDataServices from 'user/OhsUserDataServices';
import { LinkAccessType, ModuleType } from 'global-services/constants/OhsObject';

import {
  getApiRequestErrorMessage,
  OhsApiErrorCodes,
  OhsApiRequestName,
  OhsApiRequestOptions,
} from './OhsApiModels';

const showSuccess = (msg: any, options?: OhsApiRequestOptions) => {
  if (options?.disableToast === true) return;
  toast.success(msg);
};

const showError = (error: any, options?: OhsApiRequestOptions) => {
  if (options?.disableToast === true || options?.disableErrorToast === true) return;
  toast.error(error);
};

// Add / Update user active sessions to be used for login
// const updateSessionToken = (response: AxiosResponse, methodName: OhsApiRequestName) => {
//   const OhsUsersSessions = new OhsUsersStoredSessions();
//   OhsUsersSessions.handleAddUpdateUserSession(response, methodName);
// };
const updateSession = (response: AxiosResponse) => {
  if (response.headers && response.headers[OhsSessionToken]) {
    const newSessionToken = response.headers[OhsSessionToken];

    if (newSessionToken) {
      Cookies.set(OhsSessionToken, newSessionToken, {
        expires: CookiesExpiresDays,
        path: '/',
      });
    }
  }

  if (response.headers && response.headers[OhsMultiUserSessionToken]) {
    Cookies.set(OhsMultiUserSessionToken, response.headers[OhsMultiUserSessionToken], {
      expires: CookiesExpiresDays,
      path: '/',
    });
  }

  // communication module

  if (
    response.headers &&
    response.headers[OhsLinkAccessAttachmentsDownloadHeader] &&
    _.has(response, 'data.result.item._id')
  ) {
    response.data.result.item.downloadToken =
      response.headers[OhsLinkAccessAttachmentsDownloadHeader];
  }
  if (
    response.headers &&
    response.headers[OhsLinkAccessAttachmentsUploadHeader] &&
    _.has(response, 'data.result.item._id')
  ) {
    response.data.result.item.uploadToken = response.headers[OhsLinkAccessAttachmentsUploadHeader];
  }
};

const handleOhsApiErrors = (
  response: any,
  methodName: OhsApiRequestName,

  options?: OhsApiRequestOptions
) => {
  let isShowErrorToast = true;
  if (
    _.has(response, 'data.error.code') &&
    response.data.error.code &&
    response.data.error.code.length
  ) {
    if (response.data.error.code.includes(OhsApiErrorCodes.InvalidVisitLeave)) {
      isShowErrorToast = false;
    }
    if (response.data.error.code.includes(OhsApiErrorCodes.SessionExpired)) {
      isShowErrorToast = false;
      OhsUserDataServices.resetUserData();
      window.location.href = `/${ModuleUrl.Login}`;
    }
    if (response.data.error.code.includes(OhsApiErrorCodes.UserInvalidSession)) {
      isShowErrorToast = false;
      // const OhsCurrentActiveSession = new OhsUserActiveSession();

      // Remove user access token from local storage
      OhsUserDataServices.removeAllLocalStorage();
      OhsUserDataServices.resetUserData();

      if (window.location.pathname !== `/${ModuleUrl.Login}`)
        window.location.href = `/${ModuleUrl.Login}`;
    }
  }
  if (isShowErrorToast === true)
    showError(getApiRequestErrorMessage(methodName, response?.data?.error), options);
};

const Dev2Url = 'https://api.dev2.safetychampion.dev';

const Dev1Url = 'https://api.dev1.safetychampion.dev';

const LegacyProdRoot = 'https://api.safetychampion.online';

const ProdRoot = 'https://api3.safetychampion.online';

const getApiRoot = () => {
  const { API_ROOT } = window as any;

  if (API_ROOT) {
    return API_ROOT;
  }
  return Dev2Url;
};

const handleConfigHeaders = (
  config: any,
  methodType: ModuleType,
  methodName: OhsApiRequestName,
  payload?: any,
  options?: OhsApiRequestOptions
) => {
  const sessionToken = options?.sessionToken;
  let LinkAccessAndWithoutTokenFlag = false;
  if (methodType === ModuleType.Linkaccess) {
    if (
      payload &&
      payload.accessType &&
      payload.accessType !== LinkAccessType.SetupComplete &&
      payload.accessType !== LinkAccessType.SetupVerify
    ) {
      LinkAccessAndWithoutTokenFlag = true;
    }
  }

  if (sessionToken) {
    if (sessionToken[OhsMultiUserSessionToken]) {
      config.headers[OhsMultiUserSessionToken] = sessionToken[OhsMultiUserSessionToken];
    }
    if (sessionToken[OhsSessionToken]) {
      config.headers[OhsSessionToken] = sessionToken[OhsSessionToken];
    }
  } else if (
    Cookies.get(OhsSessionToken) &&
    methodName !== OhsApiRequestName.MultiUser &&
    methodName !== OhsApiRequestName.Authenticate &&
    LinkAccessAndWithoutTokenFlag !== true &&
    methodName !== OhsApiRequestName.linkAccessFetch &&
    methodName !== OhsApiRequestName.linkAccessList
  ) {
    config.headers[OhsSessionToken] = Cookies.get(OhsSessionToken);
  }

  if (
    Cookies.get(OhsMultiUserSessionToken) &&
    methodName !== OhsApiRequestName.Authenticate &&
    methodName !== OhsApiRequestName.AuthenticateInactive &&
    LinkAccessAndWithoutTokenFlag !== true &&
    methodName !== OhsApiRequestName.linkAccessFetch &&
    methodName !== OhsApiRequestName.linkAccessList
  ) {
    config.headers[OhsMultiUserSessionToken] = Cookies.get(OhsMultiUserSessionToken);
  }

  if (options?.linkAccessDownloadToken) {
    config.headers[OhsLinkAccessAttachmentsDownloadHeader] = options?.linkAccessDownloadToken;
  }
};

const OhsApiServices = {
  updateSession,
  handleOhsApiErrors,
  showSuccess,
  showError,
  getApiRoot,
  handleConfigHeaders,
};
export default OhsApiServices;
