import { ModuleType } from 'global-services/constants/OhsObject';

export default class OhsContractorLookup {
  _id: string;

  type: ModuleType.Contractor;

  moduleName: string;

  businessName: string;

  contactName: string | null;

  contactEmail?: string | null;

  constructor(
    _id: string,
    businessName: string,
    contactName: string | null,
    contactEmail: string | null
  ) {
    this._id = _id;
    this.type = ModuleType.Contractor;
    this.moduleName = '';
    this.businessName = businessName;
    this.contactEmail = contactEmail;
    this.contactName = contactName;
  }
}
