import _ from 'lodash';

import OhsUserLocalServices from 'user/OhsUserLocalServices';

import { TierType } from '../OhsObject';
import { OhsTier } from './OhsTier';

// eslint-disable-next-line import/prefer-default-export
export const setTierName = (tier: OhsTier): OhsTier => {
  const userTier: OhsTier = { ...tier };
  const user = OhsUserLocalServices.getLocalOhsUser();

  if (userTier.type === TierType.T3) {
    userTier.name = user?.org?.name || '';
  } else {
    userTier.name = _.find(user?.configs.admin2?.LOWER_TIER_ACCESS, {
      _id: userTier._id,
    })?.name!;
  }
  return userTier;
};
