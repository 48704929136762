import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { getOhsLocalStorage, setOhsLocalStorage } from 'global-services/OhsDataParse';
import OhsCorrespondenceListsRecord, {
  OhsCorrespondenceFilterPayload,
} from 'correspondence/models/OhsCorrespondenceRecord';
import { OhsUser } from 'user/OhsUser';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import {
  getCorrespondenceExchangeLists,
  searchCorrespondenceExchangeLists,
} from './OhsCorrespondenceServices';
import OhsCorrespondenceExchangeRecord from './models/OhsCorrespondenceExchangeRecord';

export interface CorrespondenceListsRecordState {
  isLoading: boolean;
  exchangeList: ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null;
  currentPage: number;
}

const initialState: CorrespondenceListsRecordState = {
  isLoading: false,
  exchangeList: null,
  currentPage: 1,
};

export const getViewPreset = (tierNum: number) => {
  switch (tierNum) {
    case 3:
      return 'view_1';
    case 4:
      return 'view_2';
    default:
      return undefined;
  }
};

const correspondenceExchangeSearch = async (
  searchDetails: any
): Promise<ApiResListsType<OhsCorrespondenceListsRecord[]> | null> => {
  const globalSearchRes: any = await searchCorrespondenceExchangeLists(searchDetails);
  return globalSearchRes;
};

const exchangeFilterOptions = (state: RootState): Partial<OhsCorrespondenceFilterPayload> => {
  const { globalfilter, correspondenceExchange } = state;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const filter = globalfilter.filterInfo;
  const userTier = user?.tierNum ?? 0;

  return {
    viewPreset: getViewPreset(userTier),
    next:
      correspondenceExchange.currentPage > 10
        ? correspondenceExchange.exchangeList?.pagination.next ?? ''
        : '',
    archived: filter.correspondenceExchangeModule.archived,
  };
};

const exchangeListRPC = async (
  setExchangeFilters: OhsCorrespondenceFilterPayload,
  id: string
): Promise<any> => {
  const response = await getCorrespondenceExchangeLists(setExchangeFilters, id);
  return response;
};
export const fetchExchangeListsAsync = createAsyncThunk<
  ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null,
  { id: string },
  { state: RootState }
>('exchange/fetchExchangeList', async ({ id }, thunkAPI) => {
  const state = thunkAPI.getState();
  const filter = thunkAPI.getState().globalfilter.filterInfo;
  const searchFilter = thunkAPI.getState().globalSearch.searchInfo;
  const hasGlobalSearch = state.globalSearch.searchInfo.searchKey !== '';

  const setExchangeFilters: OhsCorrespondenceFilterPayload = {
    ...exchangeFilterOptions(state),
    page:
      state.correspondenceExchange.currentPage > 10 ? 0 : state.correspondenceExchange.currentPage,
    sort: { ...JSON.parse(String(filter.correspondenceExchangeModule.sort)) },
    status: filter.correspondenceExchangeModule.status,
    count: false,
  };

  try {
    const response = hasGlobalSearch
      ? await correspondenceExchangeSearch({
          ...searchFilter,
          filter: {
            rollId: id,
            page:
              state.correspondenceExchange.currentPage > 10
                ? 0
                : state.correspondenceExchange.currentPage,
            count: false,
            archived: filter.correspondenceExchangeModule.archived ?? false,
          },
        })
      : await exchangeListRPC(setExchangeFilters, id);
    return response as ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchExchangeListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null,
  { id: string },
  { state: RootState }
>('exchange/fetchExchangeCountList', async ({ id }, thunkAPI) => {
  const state = thunkAPI.getState();
  const filter = thunkAPI.getState().globalfilter.filterInfo;
  const searchFilter = thunkAPI.getState().globalSearch.searchInfo;
  const hasGlobalSearch = state.globalSearch.searchInfo.searchKey !== '';
  const exchangeFiltersWithCount: OhsCorrespondenceFilterPayload = {
    ...exchangeFilterOptions(state),
    count: true,
    page: 1,
    sort: { order: -1, key: 'dateCreated' },
    status: [],
  };

  try {
    const response = hasGlobalSearch
      ? await correspondenceExchangeSearch({
          ...searchFilter,
          filter: {
            rollId: id,
            count: true,
            page: 1,
            archived: filter.correspondenceExchangeModule.archived ?? false,
          },
        })
      : await getCorrespondenceExchangeLists(exchangeFiltersWithCount, id);
    return response as ApiResListsType<OhsCorrespondenceExchangeRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const exchangeSlice = createSlice({
  name: 'correspondenceExchange',
  initialState,
  reducers: {
    setExchangeCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setExchangeSubRows: (state, action: PayloadAction<{ id: string; subRows: string[] }>) => {
      if (state.exchangeList?.items) {
        const index = state.exchangeList?.items?.findIndex(
          (item) => item._id === action.payload.id
        );
        if (index > -1) {
          state.exchangeList.items[index] = {
            ...state.exchangeList.items[index],
            subRows: action.payload.subRows,
          };
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExchangeListsAsync.pending, (state) => {
        state.isLoading = true;
        state.exchangeList = {
          items: [],
          pagination: state.exchangeList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchExchangeListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.exchangeList = action.payload;
          if (state?.exchangeList?.pagination?.totalPages) {
            // store total pages to localstorage
            setOhsLocalStorage('exchangeListtotalPages', state.exchangeList.pagination.totalPages);
          }
          // get last _id and set it to pagination next
          const setItemsLastId = [...(state.exchangeList?.items ?? [])].pop()?._id ?? '';
          state.exchangeList.pagination.next = setItemsLastId;
          state.exchangeList.pagination.totalPages =
            getOhsLocalStorage('exchangeListtotalPages') ?? 0;
          state.isLoading = false;
        }
      })
      .addCase(fetchExchangeListsAsync.rejected, (state) => {
        state.exchangeList = null;
      })
      .addCase(fetchExchangeListsCountAsync.fulfilled, (state, action) => {
        if (state.exchangeList && action.payload && action.payload.pagination) {
          setOhsLocalStorage('exchangeListtotalPages', action?.payload?.pagination?.totalPages);
          state.exchangeList.pagination.totalPages = action?.payload?.pagination?.totalPages ?? 0;
        }
      });
  },
});

const exchangeState = (state: RootState) => state.correspondenceExchange;
// Memoized Selectors
export const getOhsCorrespondenceExchangeModuleState = createSelector(
  [exchangeState],
  (correspondenceExchange) => correspondenceExchange
);
export const { setExchangeCurrentPage, setExchangeSubRows } = exchangeSlice.actions;
export const correspondenceExchangeModuleReducer = exchangeSlice.reducer;
