// Function to chunk an array into smaller arrays of given size
const chunkArray = (array: string[], size: number): string[][] => {
  return Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
    array.slice(index * size, index * size + size)
  );
};

export type OhsCorrespondenceExchangeRes = {
  success: boolean;
  result?: { item: any };
  error?: any;
};

// Function to merge responses
export function mergeChunkProcessResults(responses: any[]): any {
  // eslint-disable-next-line no-restricted-syntax
  for (const res of responses) {
    if (!res.success) {
      return { success: false, error: res.error || {} };
    }
  }

  return responses.reduce(
    (acc, curr) => {
      if (curr.success && curr.result && curr.result.item) {
        acc.success = true;
        acc.result = acc.result || { item: {} };
        Object.assign(acc.result.item, curr.result.item);
      }
      return acc;
    },
    { success: true, result: { item: {} } }
  );
}

const OhsBulkOperationInChunks = {
  chunkArray,
  mergeChunkProcessResults,
};
export default OhsBulkOperationInChunks;
