import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { useAppSelector } from 'app/hooks';
import { useQuery } from 'global-components/tab/OhsTab';
import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import OhsCheckboxFilter from '../filterfields/OhsCheckboxFilter';
import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';
import OhsCategoriesMultSelectFilter from '../filterfields/OhsCategoriesFilter';
import OhsSubcategoriesMultiSelectFilter from '../filterfields/OhsSubcategoriesFilter';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}

function OhsContractorFilters(props: Props) {
  const { classes } = useStyles();
  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const user = OhsUserLocalServices.getLocalOhsUser();
  const userTierNum = user?.tierNum ?? 0;
  const query = useQuery();
  const tabKey = query.get('tab');
  const getModuleContractorCategories = user?.configs.contractor?.CLASSIFICATION_LIST ?? [];

  return (
    <Box className={classes.filterContent}>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id="contractorModule.sort"
          required
          options={filter.contractorModule.tableSortKeyLists?.sortColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {userTierNum <= 3 && tabKey === OhsTabKeyTypes.Workplace && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter
            id="contractorModule.workplaces"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}

      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsCategoriesMultSelectFilter
          ohsFormSettings={props.ohsFormSettings}
          id="contractorModule.categories"
          options={getModuleContractorCategories}
        />
      </Grid>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSubcategoriesMultiSelectFilter
          ohsFormSettings={props.ohsFormSettings}
          id="contractorModule.subcategories"
          categoriesList={getModuleContractorCategories}
        />
      </Grid>

      {userTierNum <= 3 && tabKey === OhsTabKeyTypes.List && (
        <>
          <Grid item md={12} xs={12} className={classes.fieldWrap}>
            <OhsCheckboxFilter
              fieldid="contractorModule.allocatedToOrg"
              label="Allocated to Organisation"
              ohsFormSettings={props.ohsFormSettings}
            />
          </Grid>
          <Grid item md={12} xs={12} className={classes.fieldWrap}>
            <OhsCheckboxFilter
              fieldid="contractorModule.notAllocated"
              label="Not Allocated"
              ohsFormSettings={props.ohsFormSettings}
            />
          </Grid>
        </>
      )}
      {((userTierNum <= 3 &&
        (tabKey === OhsTabKeyTypes.List || tabKey === OhsTabKeyTypes.Workplace)) ||
        userTierNum === 4) && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid="contractorModule.allocatedToWorkplace"
            label="Allocated to Workplace"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}

      {((userTierNum <= 3 && tabKey === OhsTabKeyTypes.Workplace) || userTierNum === 4) && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid="contractorModule.workplaceOwned"
            label="Workplace Owned"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
    </Box>
  );
}

export default OhsContractorFilters;
