import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';

import { OhsDashboardBannerContentButton } from './banner/OhsDashboardBannerModels';

export interface BannerContentType {
  message: string;
  messageColor: string;
  bannerColors: Array<string>;
  buttons?: Array<OhsDashboardBannerContentButton>;
  isPreview?: boolean;
  removeBannerOnClick?: () => void;
}

export interface OhsDashboardState {
  leftSideDrawerToggleOpen: boolean;
  rightSideDrawerToggleOpen: boolean;
  topFilterDrawerToggleOpen: boolean;
  topBannerToggleOpen: boolean;
  topBannerTogglePreviewOpen: boolean;
  topBannerContentPreview: BannerContentType;
  isShowingBanner: boolean;
  pendingRequests: number;
  selectedFilterRecord: any;
}
const initialState = {
  leftSideDrawerToggleOpen: false,
  rightSideDrawerToggleOpen: false,
  topFilterDrawerToggleOpen: false,
  topBannerToggleOpen: false,
  topBannerTogglePreviewOpen: false,
  topBannerContentPreview: {},
  isShowingBanner: false,
  pendingRequests: 0,
  selectedFilterRecord: {},
} as OhsDashboardState;

const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    incrementPendingRequest: (state) => {
      state.pendingRequests += 1;
    },
    decrementPendingRequest: (state) => {
      state.pendingRequests -= 1;
    },
    clearPendingRequest: (state) => {
      state.pendingRequests = 0;
    },
    toggleLeftSideDrawer: (state, action: PayloadAction<boolean>) => {
      state.leftSideDrawerToggleOpen = action.payload;
    },
    toggleRightSideDrawer: (state, action: PayloadAction<boolean>) => {
      state.rightSideDrawerToggleOpen = action.payload;
    },
    toggleTopFilterDrawer: (state, action: PayloadAction<boolean>) => {
      state.topFilterDrawerToggleOpen = action.payload;
    },
    toggleTopBanner: (state, action: PayloadAction<boolean>) => {
      state.topBannerToggleOpen = action.payload;
    },
    toggleTopBannerPreview: (state, action: PayloadAction<boolean>) => {
      state.topBannerTogglePreviewOpen = action.payload;
    },

    setBannerPreviewContent: (state, action: PayloadAction<BannerContentType>) => {
      state.topBannerContentPreview = action.payload;
    },
    setIsShowingBanner: (state, action: PayloadAction<boolean>) => {
      state.isShowingBanner = action.payload;
    },

    // For conditional filters affected by record (ex. record history)
    setSelectedFilterRecord: (state, action: PayloadAction<any>) => {
      state.selectedFilterRecord = action.payload;
    },
  },
});

export const {
  toggleLeftSideDrawer,
  toggleRightSideDrawer,
  toggleTopFilterDrawer,
  toggleTopBanner,
  toggleTopBannerPreview,
  incrementPendingRequest,
  decrementPendingRequest,
  clearPendingRequest,
  setIsShowingBanner,
  setBannerPreviewContent,
  setSelectedFilterRecord,
} = dashboardSlice.actions;

export const getOhsDashboard = (state: RootState) => state.dashboard;

export const dashboardReducer = dashboardSlice.reducer;
