import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { Icon, Label } from 'semantic-ui-react';
import DragIndicatorSharpIcon from '@mui/icons-material/DragIndicatorSharp';

import { DragHandle } from './OhsDragAndDrop';

interface Props {
  label: string | JSX.Element;
  description?: string;
  onDelete: (value: string) => void;
  disabled?: boolean;
}

function DragAndDropSelectItem(props: Props) {
  const useStyles = makeStyles()(() => ({
    labelTag: {
      borderRadius: '.28571429rem !important',
      padding: '0.4em 0.5em !important',
    },
    container: {
      whiteSpace: 'nowrap',
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    handleHolder: {
      marginTop: 'auto',
      marginBottom: 'auto',
      paddingRight: '0.5em !important',
      lineHeight: '1em !important',
    },
    label: {
      wordBreak: 'break-all',
      margin: '0 0 0.5em',
    },
    description: {
      wordBreak: 'break-all',
      color: 'rgba(0, 0, 0, 0.4)',
    },
    closeButton: {
      paddingLeft: '0.5em !important',
    },
  }));

  const { classes } = useStyles();

  return (
    <Label as="a" tag className={classes.labelTag}>
      <div className={classes.container}>
        <span className={classes.handleHolder}>
          {!props.disabled && <DragHandle icon={<DragIndicatorSharpIcon />} />}
        </span>
        <div>
          <p className={classes.label}>{props.label}</p>
          {props.description && <span className={classes.description}>{props.description}</span>}
        </div>
        {!props.disabled && (
          <Icon name="delete" onClick={props.onDelete} className={classes.closeButton} />
        )}
      </div>
    </Label>
  );
}

DragAndDropSelectItem.defaultProps = {
  description: undefined,
  disabled: false,
};

export default DragAndDropSelectItem;
