import React from 'react';

import OhsModal from 'global-components/modal/OhsModal';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  getOhsTaskAssignState,
  setOnAssignModalCloseFunc,
  toggleAssignModalOpen,
} from 'task/store/OhsTaskAssignSlice';

import OhsTaskAssignTable from './OhsTaskAssignTable';

export default function OhsTaskAssignCustomModal() {
  const dispatch = useAppDispatch();
  const { assignModalOpen, taskAssignStatus, onAssignModalCloseFunc } =
    useAppSelector(getOhsTaskAssignState);
  const toggleAssignModal: React.Dispatch<React.SetStateAction<boolean>> = (
    value: boolean | ((prevState: boolean) => boolean)
  ) => {
    if (typeof value === 'boolean') {
      dispatch(toggleAssignModalOpen(value));
      dispatch(setOnAssignModalCloseFunc(null));
    } else {
      dispatch(toggleAssignModalOpen(value(assignModalOpen)));
    }
  };

  return (
    <OhsModal
      title="Assign"
      open={assignModalOpen && !!taskAssignStatus}
      setModalOpenFunc={toggleAssignModal}
      onClose={onAssignModalCloseFunc ?? undefined}
      ContentNode={<OhsTaskAssignTable taskAssignList={taskAssignStatus} />}
    />
  );
}
