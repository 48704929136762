// Common Function

export const getValueByStringProperties = (obj: any, key: string) => {
  let object = obj;
  let stringProperties = key.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
  stringProperties = stringProperties.replace(/^\./, ''); // strip a leading dot
  const a = stringProperties.split('.');
  // eslint-disable-next-line no-plusplus
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in object) {
      object = object[k];
    } else {
      return;
    }
  }
  // eslint-disable-next-line consistent-return
  return object;
};

export const explodeTreeHierarychy = (collection: any[], separator: string) => {
  if (!Array.isArray(collection)) {
    return collection;
  }
  let value = '';

  // eslint-disable-next-line array-callback-return
  collection.map((val, index) => {
    value += val;

    if (index < collection.length - 1) {
      let g = '';

      // eslint-disable-next-line no-plusplus
      for (let m = index + 1; m > 0; m--) {
        g += separator;
      }

      value = `${value} ${g} `;
    }
  });

  return value;
};

export const limitStrLength = (text: string, max_length: number) => {
  if (text.length > max_length - 3) {
    return `${text.substring(0, max_length).trimEnd()} ...`;
  }
  return text;
};

export const removeNonNumeric = (str: string | number) => {
  if (typeof str === 'number') return str;
  const newValue = str.replace(/[^\d.-]/g, '');
  return +newValue;
};

export const removeInvalidExcelCharacters = (title: string) => {
  // Characters not allowed as Excel titles
  // eslint-disable-next-line no-useless-escape
  const invalidChars = /[\[\]:\*?\,/\\]/g;

  // Replace invalid characters with an empty string
  return title.replace(invalidChars, '');
};
