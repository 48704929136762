import { SemanticICONS } from 'semantic-ui-react';

import { OhsAllocationTierRecord } from 'global-components/allocation/OhsAllocatableModels';
import { ModuleType } from 'global-services/constants/OhsObject';

export const searchColumnId = 'searchCol';
export const expanderColumnId = 'expander';
export interface RowOperations {
  maxWidth?: string;
  viewFunc?: (item: any) => void;
  linksButton?: {
    func: (item: any) => void;
    permissionFunc?: (item: any) => boolean;
  };
  viewButton?: {
    func: (item: any) => void;
    label: string;
    permission?: boolean;
    permissionFunc?: (item: any) => boolean;
    icon?: SemanticICONS;
  };
  linksViewButton?: {
    func: (item: any) => void;
    label: string;
    permission?: boolean;
    permissionFunc?: (item: any) => boolean;
  };
  addButton?: {
    func: (item: any) => void;
    label?: string;
    permission: boolean;
    icon?: SemanticICONS;
  };
  openAllocationModalButton?: {
    func: (item: any, index?: number) => void;
    permission?: boolean;
    permissionFunc?: (item: any) => boolean;
  };
  openDeleteModalButton?: {
    func: (item: any, index?: number) => void;
    permission?: boolean;
    permissionFunc?: (item: any) => boolean;
  };

  allocationFunc?: (allocationItem: OhsAllocationTierRecord) => Promise<any>;
  deAllocationFunc?: (deallocationItem: OhsAllocationTierRecord) => Promise<any>;
  bulkAllocation?: {
    disabled: boolean;
    allocationFunc: (allocationItem: OhsAllocationTierRecord) => Promise<any>;
    deAllocationFunc: (deallocationItem: OhsAllocationTierRecord) => Promise<any>;
  };

  copyButton?: {
    func: (item: any) => void;
    showIsCopiedHideButton?: boolean;
    permission?: boolean;
    permissionFunc?: (item: any) => boolean;
  };

  updateButton?: {
    func: (item: any) => void;

    permission?: boolean;
    permissionFunc?: (item: any) => boolean;
  };

  editButton?: {
    func: (item: any, index?: number) => void;
    showFunc?: (item: any) => void;
    permission?: boolean;
    permissionFunc?: (item: any) => boolean;
  };
  cloneButton?: {
    func: (item: any, index?: number) => void;
    permission?: boolean;
    permissionFunc?: (item: any) => boolean;
  };
  archiveButton?: {
    func?: (item: any, isArchive: boolean) => void;

    permission?: boolean;
    morphPermission?: boolean;
    permissionFunc?: (item: any) => boolean;
  };
  signOffButton?: {
    func: (item: any) => void;
    name?: string;
    icon?: SemanticICONS;
    permission?: boolean;
    permissionFunc?: (item: any) => boolean;
  };
  closeButton?: {
    func: (item: any) => void;

    canClose: (item: any) => boolean;
  };
  assignRowButton?: {
    func: (item: any) => void;

    permissionFunc?: (item: any) => boolean;
  };
  assignModalRowButton?: {
    parentId: (item: any) => string;

    parentType: (item: any) => ModuleType;

    taskId: (item: any) => string;

    onModalCloseFunc?: () => void;
  };
  assignButton?: {
    func: (item: any, callback?: (success: boolean) => void) => Promise<void>;

    permission: boolean;
  };
  leaveButton?: {
    func?: (row: any) => void;
  };
  exportButton?: {
    func?: (item: any) => void;
  };
  inspectionButton?: {
    func: (item: any) => void;
  };
  tasksButton?: {
    func: (item: any) => void;
    permissionFunc?: (item: any) => boolean;
  };
  sendButton?: {
    func: (item: any) => void;
    label?: string;
    permissionFunc?: (item: any) => boolean;
  };
  xsiSetupButton?: {
    func: (item: any) => void;
    label?: string;
    permissionFunc?: (item: any) => boolean;
  };
  enableButton?: {
    func: (item: any) => void;
    label?: string;
    permissionFunc?: (item: any) => boolean;
  };
  disableButton?: {
    func: (item: any) => void;
    label?: string;
    permissionFunc?: (item: any) => boolean;
  };
  optionalButton?: {
    func: (item: any) => void;
    label?: string;
    // permission: boolean;
    permissionFunc?: (item: any) => boolean;
  };
  enforceButton?: {
    func: (item: any) => void;
    label?: string;
    permissionFunc?: (item: any) => boolean;
  };
  cloneAndLinkButton?: {
    func: (item: any) => void;
    label?: string;
    permission?: boolean;
    permissionFunc?: (item: any) => boolean;
  };
  linkButton?: {
    func: (item: any) => void;
    label?: string;
    permission?: boolean;
    permissionFunc?: (item: any) => boolean;
  };
}
