import _ from 'lodash';

import { getCurrentTimezone } from 'global-services/OhsDataParse';
import { OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { LinkAccessType, ModuleType } from 'global-services/constants/OhsObject';
import OhsCorrespondenceTemplateContendType from 'correspondence/models/OhsCorrespondenceTemplateContendType';
import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';

export const getAttachment = async (id: string, token: string) => {
  const res: any = await OhsApiRequest(
    RequestType.Attachments,
    ModuleType.Attachment,
    OhsApiRequestName.linkAccessFetch,
    { _id: id },
    { linkAccessDownloadToken: token }
  );

  if (res) {
    return res;
  }
  return null;
};

export const getAttachmentList = async (payload: string[], token: string) => {
  const res: any = await OhsApiRequest(
    RequestType.Attachments,
    ModuleType.Attachment,
    OhsApiRequestName.linkAccessList,
    { _ids: payload },
    { linkAccessDownloadToken: token }
  );

  let attachmentList: any[] = [];
  if (res) {
    attachmentList = (res?._ids || [])
      .filter((a: any) => a) // check if any attachment null or undefined
      .map((attachment: any) => ({
        ...attachment?.details,
        _id: attachment._id,
        url: attachment.url,
        type: attachment.type,
      }));
  }
  return attachmentList;
};

async function exchangeCusvalsFetch(token: string, cusvalId: string): Promise<any> {
  const payload = {
    token,
    moduleType: ModuleType.Correspondence,
    accessType: LinkAccessType.ExchangeCusvalValuesBulkFetch,
    tzDateCreated: getCurrentTimezone(),
    valueIds: [cusvalId],
  };
  return OhsApiRequest(RequestType.Admin, ModuleType.Linkaccess, OhsApiRequestName.Access, payload);
}
async function exchangeFetch(token: string) {
  const payload = {
    token,
    moduleType: ModuleType.Correspondence,
    accessType: LinkAccessType.ExchangeFetch,
    tz: getCurrentTimezone(),
  };
  const exchange: any = await OhsApiRequest(
    RequestType.Admin,
    ModuleType.Linkaccess,
    OhsApiRequestName.Access,
    payload
  );

  if (_.has(exchange, 'result.item.exchanges')) {
    for (let index = 0; index < exchange.result.item.exchanges.length; index += 1) {
      const ex: any = exchange.result.item.exchanges[index];
      if (ex.cusvals) {
        // eslint-disable-next-line no-await-in-loop
        const cusvals = (await exchangeCusvalsFetch(token, ex.cusvals._id)) as any;
        if (_.has(cusvals, 'result.items[0]') === true && cusvals.result.items[0]) {
          const csv = cusvals.result.items[0];
          ex.cusvals = csv;
        }
      }
      if (_.has(ex, 'prose.content')) {
        for (let contentIndex = 0; contentIndex < ex.prose.content.length; contentIndex += 1) {
          const content: any = ex.prose.content[contentIndex];

          if (
            content &&
            content.type === OhsCorrespondenceTemplateContendType.OhsAttachment &&
            _.has(content, 'data.attachments')
          ) {
            if (content.data?.attachments?.length) {
              // eslint-disable-next-line no-await-in-loop
              const attList = await getAttachmentList(
                content.data.attachments?.map((att: OhsAttachment) => att._id),
                exchange.result.item.downloadToken
              );
              content.data.attachments = attList;
              const attDetails = attList[0];
              content.data = { ...content.data, ...attDetails };
            }
          }
        }
      }
      if (ex.attachments && ex.attachments.length) {
        // eslint-disable-next-line no-await-in-loop
        const attList = await getAttachmentList(
          ex.attachments.map((att: OhsAttachment) => att._id),
          exchange.result.item.downloadToken
        );
        ex.attachments = attList;
        ex.attachments.forEach((att: any) => {
          att.downloadLink = att.url;
        });
      }
      if (ex.signatures && ex.signatures.length) {
        for (let attIndex = 0; attIndex < ex.signatures.length; attIndex += 1) {
          const signature = ex.signatures[attIndex];

          if (signature.attachments && signature.attachments[0]) {
            const attId = signature.attachments[0]._id;
            // eslint-disable-next-line no-await-in-loop
            const attList = await getAttachmentList([attId], exchange.result.item.downloadToken);
            if (attList && attList[0]) {
              const attDetails = attList[0];

              ex.signatures[attIndex] = { ...signature, ...attDetails };
            }
          }
        }
      }
    }
  }
  return exchange;
}

async function exchangeCusvalsCreate(
  token: string,
  definitionId: string,
  cusvals: any
): Promise<any> {
  const payload = {
    token,
    moduleType: ModuleType.Correspondence,
    accessType: LinkAccessType.ExchangeCusvalValuesCreate,
    tzDateCreated: getCurrentTimezone(),
    definitionId,
    cusvals,
  };
  return OhsApiRequest(RequestType.Admin, ModuleType.Linkaccess, OhsApiRequestName.Access, payload);
}

async function exchangeEdit(payload: any) {
  const exchangePayload = {
    token: payload.token,
    moduleType: ModuleType.Correspondence,
    accessType: LinkAccessType.ExchangeEdit,
    reply: {
      comment: null,
      signatures: [],
      attachments: [],
    },
    cusvals: null,
    tz: getCurrentTimezone(),
  } as any;

  if (payload.reply) {
    if (payload.reply.comment) {
      exchangePayload.reply.comment = payload.reply.comment;
    }
    if (payload.reply.attachments && payload.reply.attachments.length) {
      exchangePayload.reply.attachments = [];
      payload.reply.attachments.forEach((element: any) => {
        const att = JSON.parse(element.details);
        att.tzDateCreated = getCurrentTimezone();
        exchangePayload.reply.attachments.push(att);
      });
    }
    if (payload.reply.signatures && payload.reply.signatures.length) {
      exchangePayload.reply.signatures = [];
      payload.reply.signatures.forEach((element: any) => {
        element.details.tzDateCreated = getCurrentTimezone();
        exchangePayload.reply.signatures.push({
          name: element.name,
          attachments: [element.details],
        });
      });
    }
  }
  if (payload.cusvals && payload.cusvalsId) {
    const cusvals = await exchangeCusvalsCreate(payload.token, payload.cusvalsId, payload.cusvals);

    if (_.has(cusvals, 'result.item._id') === true) {
      exchangePayload.cusvals = { _id: cusvals.result.item._id, type: 'core.module.cusval.values' };
    }
  }

  const exchange: any = await OhsApiRequest(
    RequestType.Admin,
    ModuleType.Linkaccess,
    OhsApiRequestName.Access,
    exchangePayload
  );
  return exchange;
}

const OhsCorrespondenceLinkAccessServices = { exchangeFetch, exchangeEdit };
export default OhsCorrespondenceLinkAccessServices;
