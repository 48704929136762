import React from 'react';

import { Button, Icon } from 'semantic-ui-react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import OhsModal from 'global-components/modal/OhsModal';
import getMixpanelTraker from 'global-services/mixpanel/OhsMixpanel';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

const useStyles = makeStyles()(() => ({
  textHeader: {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '5px 0 10px 0',
  },
  text: {
    margin: '10px 0',
  },
}));

interface Props {
  freemiumPaidMsgOpen: boolean;
  setFreemiumPaidMsgOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function OhsFreemiumAlertModal(props: Props) {
  const { classes } = useStyles();
  const [toEnquire, setToenquire] = React.useState(false);
  const [enquired, setEnquired] = React.useState(false);
  const user = OhsUserLocalServices.getLocalOhsUser();
  const mixpanel = getMixpanelTraker();

  const handleClose = () => {
    setToenquire(false);
    setEnquired(false);
    props.setFreemiumPaidMsgOpen(false);
  };

  const handleEnquireReq = () => {
    try {
      mixpanel?.track('Account Upgrade Select', {
        'Plan Name': 'Upgrade from  Safety Champion Light',
      });
    } catch (e) {
      console.error(e);
    }
    setEnquired(true);
  };

  React.useEffect(() => {
    setToenquire(false);
    setEnquired(false);
  }, []);

  const messageContent =
    toEnquire && !enquired ? (
      <>
        <Box className={classes.text}>
          Great to hear you&apos;re keen to upgrade to a Safety Champion Premium subscription to
          experience more great features and modules.
        </Box>
        <Box className={classes.text}>
          Click the &apos;<strong>Enquire</strong>&apos; button to have one of our team members
          contact you to discuss your options.
        </Box>
      </>
    ) : (
      !enquired && (
        <Box>
          <Box className={classes.textHeader}>Hi there!</Box>
          {user && (
            <>
              {user.tierNum === 3 && (
                <Box>
                  Unfortunately you are not able to access this module using Safety Champion Light.
                  Click the button to request an upgrade, or close this window to resume your
                  session.
                </Box>
              )}
              {user.tierNum > 3 && (
                <Box className={classes.text}>
                  Unfortunately you do not have access to this module. If you have any questions,
                  please speak to your system administrator.
                </Box>
              )}
            </>
          )}
          <Box className={classes.text}>The Safety Champion Team</Box>
        </Box>
      )
    );

  const messageSuccess = enquired ? (
    <>
      <Box className={classes.textHeader}>Thank you for your enquiry!</Box>
      <Box className={classes.text}>
        One of our customer experience team members will contact you within one business day.
      </Box>
    </>
  ) : (
    ''
  );

  return (
    <OhsModal
      title="PAID Plan"
      open={props.freemiumPaidMsgOpen}
      onClose={handleClose}
      ContentNode={
        <>
          <Box>{messageContent}</Box>
          <Box>{messageSuccess}</Box>
        </>
      }
      actionNode={
        <>
          <Button onClick={() => handleClose()}>Close</Button>
          {!toEnquire && (
            <Button onClick={() => setToenquire(true)} icon labelPosition="right" color="green">
              Request Upgrade
              <Icon name="check" />
            </Button>
          )}
          {toEnquire && !enquired && (
            <Button onClick={() => handleEnquireReq()} icon labelPosition="right" color="green">
              Enquire
            </Button>
          )}
        </>
      }
    />
  );
}
