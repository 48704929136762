/* eslint-disable max-len */
const exportCSVFormating = (exportList: any[]) => {
  const formattedCSVList: any[] = [];
  exportList.forEach((item) => {
    const exportItem = { ...item };

    exportItem.orgName = item.orgTier.name ?? '';
    exportItem.numEmployees = item.orgTier.numEmployees?.toString() ?? '';
    exportItem.userName = [
      `${item.user.firstName} ${item.user.lastName}`,
      item.user.email,
      `+${item.user.phoneCountryCode} ${item.user.phone}`,
    ]
      .filter(Boolean)
      .join('\n');

    exportItem.location = [
      item.orgTier.country,
      item.orgTier.state ? ` - ${item.orgTier.state}` : '',
    ]
      .filter(Boolean)
      .join('');

    exportItem.industry = item.orgTier.industry ?? '';

    formattedCSVList.push(exportItem);
  });

  return formattedCSVList;
};

const OhsFreemiumExportUtils = {
  exportCSVFormating,
};

export default OhsFreemiumExportUtils;
