import React from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';
import OhsCheckboxFilter from '../filterfields/OhsCheckboxFilter';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}

function OhsHumanResourceTrainingFilters(props: Props) {
  const { classes } = useStyles();
  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const user = OhsUserLocalServices.getLocalOhsUser();
  const userTierNum = user?.tierNum ?? 0;

  return (
    <Box className={classes.filterContent}>
      <Grid item md={12} xs={12} className={classes.fieldWrap}>
        <OhsSelectField
          title="Sort By"
          id="hrTrainingModule.sort"
          required
          options={filter.hrTrainingModule.tableSortKeyLists?.sortColAscDescOptions}
          ohsFormSettings={props.ohsFormSettings}
        />
      </Grid>
      {userTierNum <= 3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsWorkplaceFilter
            id="hrTrainingModule.workplaces"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
      {userTierNum === 3 && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsCheckboxFilter
            fieldid="hrTrainingModule.skipOrgRecords"
            label="Hide Organisation Records"
            ohsFormSettings={props.ohsFormSettings}
          />
        </Grid>
      )}
    </Box>
  );
}

export default OhsHumanResourceTrainingFilters;
