import { getOhsLocalStorage, OhsLocalStoragePrefix } from 'global-services/OhsDataParse';

import { OhsUserDataLocalStorage, UserDataLocalStorageName } from './OhsUserModels';
import { OhsUser } from './OhsUser';

const getLocalOhsUser = (): OhsUser | undefined => {
  const userData = getOhsLocalStorage(UserDataLocalStorageName) as OhsUserDataLocalStorage;
  if (userData && userData.data) {
    const user = new OhsUser(userData.data);

    if (user.name && user._id) {
      return user;
    }
  }

  return undefined;
};

const removeLocalOhsUser = () => {
  localStorage.removeItem(OhsLocalStoragePrefix + UserDataLocalStorageName);
};

const OhsUserLocalServices = { getLocalOhsUser, removeLocalOhsUser };
export default OhsUserLocalServices;
