import React from 'react';

import { Box, Button, styled } from '@mui/material';

import OhsComponentLoading from 'global-services/utils/OhsLoading';

import { OhsMethodList, OhsMfaActiveMethodListTypes } from '../../OhsMfaTypes';
import { fetchUserMfaList, userMfaVerifyChallenge } from '../../enrollflows/OhsMfaServices';
import { getMethodIcon } from '../../OhsMfaUtils';

const MfaButton = styled(Button)(() => ({
  justifyContent: 'left',
  background: '#efefef',
  width: '100%',
  marginTop: '6px',
  padding: '15px',
  color: 'black',
  fontWeight: 'bold',
  '& > div': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
  '&.Mui-disabled': {
    background: 'whitesmoke',
  },
}));

export const MfaActiveList = styled(Box)(() => ({
  padding: '8px 12px',
  background: '#f4f4f4',
  borderRadius: '5px',
  width: '100%',
  margin: '10px 5px 10px 0',
  '& .mfamethod': {
    display: 'flex',
    '& .method': {
      '&::first-letter': {
        textTransform: 'uppercase',
      },
      fontWeight: 'bold',
      marginRight: '5px',
    },
  },
}));

export const MfaActiveListWrap = styled(Box)(() => ({
  marginBottom: '5px',
}));

interface Props {
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setMethodToVerify: React.Dispatch<any>;
  setRecoveryMethodInfo: React.Dispatch<any>;
}

function OhsMfaVerifyMethodLists(props: Props) {
  const [activeMfaLists, setActiveMfaMethods] = React.useState<OhsMethodList[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const { setActiveStep, setMethodToVerify, setRecoveryMethodInfo } = props;

  const getUserMfaList = async () => {
    setIsLoading(true);
    const userMfaList: OhsMfaActiveMethodListTypes = await fetchUserMfaList();
    setRecoveryMethodInfo(userMfaList?.recovery ?? []);
    const mfaMethodList = userMfaList?.authenticators?.map((list: any) => {
      return {
        ...list,
        icon: getMethodIcon(list.method),
        title: list.method === 'app' ? 'Authentication App' : list.name,
      };
    });
    setIsLoading(false);
    setActiveMfaMethods(mfaMethodList);
  };

  React.useEffect(() => {
    getUserMfaList();
  }, []);

  const handleMfaChallengeMethod = async (methodId: string) => {
    setIsLoading(true);
    const enrollUserReq = await userMfaVerifyChallenge(methodId);
    if (enrollUserReq) {
      setMethodToVerify(enrollUserReq.authenticator);
      setIsLoading(false);
      setActiveStep(1);
    }
  };

  if (isLoading) return <OhsComponentLoading />;
  return (
    <MfaActiveListWrap>
      <Box pb={1}>To continue, you need to select a method then verify it.</Box>
      {activeMfaLists.map((method: OhsMethodList, index: number) => {
        return (
          <MfaButton
            key={index}
            disabled={method.isEnabled}
            startIcon={method.icon}
            onClick={() => handleMfaChallengeMethod(method.id ?? '')}
          >
            <Box>
              {method.title}{' '}
              {method.isEnabled && <Box color={method.isEnabled ? 'green' : 'black'}>enabled</Box>}
            </Box>
          </MfaButton>
        );
      })}
    </MfaActiveListWrap>
  );
}

export default OhsMfaVerifyMethodLists;
