import axios from 'axios';

import {
  responseMixpanelInterceptor,
  responseRejectedMixpanelInterceptor,
} from 'global-services/mixpanel/OhsMixpanelInterceptors';

import OhsApiServices from './OhsApiServices';

axios.defaults.baseURL = OhsApiServices.getApiRoot();

axios.defaults.baseURL = OhsApiServices.getApiRoot();

// Add a response Mixpanel interceptor
axios.interceptors.response.use(responseMixpanelInterceptor, responseRejectedMixpanelInterceptor);

axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    return config;
  },
  (error) => {
    // Do something with request error
    return Promise.reject(error);
  }
);

export default axios;
