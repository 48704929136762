/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';

import MenuItem from '@mui/material/MenuItem';
import { bindMenu, usePopupState, bindFocus } from 'material-ui-popup-state/hooks';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { Box, Divider } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Icon } from 'semantic-ui-react';

const useCascadingMenuStyles = makeStyles()(() => ({
  menu: {
    marginTop: '5px',
    minWidth: '250px',
    marginLeft: '-3px',
    '& li': {
      minWidth: '250px',
    },
  },
  submenu: {
    minWidth: '250px',
    marginLeft: '0',
  },
  title: {
    flexGrow: 1,
  },
  moreArrow: {
    marginRight: 0,
  },
  menuitem: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'initial',
  },
  mainmenuitem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'initial',
  },
  label: {
    padding: '4px 0 4px 16px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'initial',
    fontSize: '12px',
  },
  divider: {
    width: '100%',
    minWidth: '250px',
    marginBottom: '4px !important',
    marginTop: '4px !important',
  },
}));

const CascadingContext = React.createContext({
  parentPopupState: null,
  rootPopupState: null,
});
interface CascadingSubmenuTypes {
  popupId: string;
  title: string;
  classes?: any;
  item: any;
  children: React.ReactNode;
}

export function CascadingSubmenu(props: CascadingSubmenuTypes) {
  const { classes } = useCascadingMenuStyles();
  const { parentPopupState } = React.useContext(CascadingContext);
  const popupState = usePopupState({
    popupId: props.popupId,
    variant: 'popover',
    parentPopupState,
    disableAutoFocus: true,
  });
  return (
    <>
      <MenuItem {...bindFocus(popupState)} value={props.item.name} className={classes.menuitem}>
        {props.item.icon ? <Icon name={props.item.icon} /> : ''}
        <span className={classes.title}>{props.title}</span>
        <ChevronLeftIcon className={classes.moreArrow} />
      </MenuItem>
      <CascadingMenu
        {...props}
        classes={{ ...props.classes, paper: classes.submenu }}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        popupState={popupState}
      />
    </>
  );
}

CascadingSubmenu.defaultProps = {
  classes: undefined,
};

interface CascadingMenuItemProps {
  onClick?: any;
  children: React.ReactNode;
  item: any;
  ohsFormSettings: any;
  id: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  parentValue: string[];
}

export function CascadingMenuItem(props: CascadingMenuItemProps) {
  const { classes } = useCascadingMenuStyles();
  const { rootPopupState } = React.useContext(CascadingContext) as any;
  if (!rootPopupState) throw new Error('must be used inside a CascadingMenu');

  const result = props.ohsFormSettings.useFormMethods.watch(props.id) ?? ([] as string[]);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLLIElement>) => {
      const newItem = [...props.parentValue, props.item.name];
      const exists = (result || []).some((arr: any) => {
        return (
          arr.length === newItem.length &&
          arr.every((element: any, index: number) => element === newItem[index])
        );
      });
      if (!exists) {
        props.ohsFormSettings.useFormMethods.setValue(props.id, [...(result || []), newItem]);
        props.ohsFormSettings.useFormMethods.trigger(props.id);
      }
      props.setOpen(false);
      rootPopupState.close(event);
      if (props.onClick) props.onClick(event);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootPopupState, props.onClick]
  );

  return (
    <MenuItem {...props} onClick={handleClick} className={classes.menuitem}>
      {props.item.icon ? <Icon name={props.item.icon} /> : ''}
      {props.item.name}
    </MenuItem>
  );
}

CascadingMenuItem.defaultProps = {
  onClick: undefined,
};

interface MenuItemLabelProps {
  icon?: any;
  name?: any;
}

export function MenuItemLabel(props: MenuItemLabelProps) {
  const { classes } = useCascadingMenuStyles();

  return (
    <MenuItem className={classes.label} disabled>
      {props?.icon ? <Icon name={props.icon} /> : ''}
      {props.name}
    </MenuItem>
  );
}

MenuItemLabel.defaultProps = {
  icon: undefined,
  name: undefined,
};

interface CascadingMenuProps {
  popupState: any;
  anchorOrigin: {
    vertical: number | 'center' | 'top' | 'bottom';
    horizontal: number | 'left' | 'center' | 'right';
  };
  transformOrigin: {
    vertical: number | 'center' | 'top' | 'bottom';
    horizontal: number | 'left' | 'center' | 'right';
  };

  classes?: any;
  children: React.ReactNode;
}

function CascadingMenu(props: CascadingMenuProps) {
  const { classes } = useCascadingMenuStyles();
  const { rootPopupState } = React.useContext(CascadingContext);

  const context = React.useMemo(
    () => ({
      rootPopupState: rootPopupState || props.popupState,
      parentPopupState: props.popupState,
    }),
    [rootPopupState, props.popupState]
  );

  return (
    <CascadingContext.Provider value={context}>
      <HoverMenu {...props} {...bindMenu(props.popupState)} className={classes.menu} />
    </CascadingContext.Provider>
  );
}

CascadingMenu.defaultProps = {
  classes: undefined,
};

interface MenuWithSubMenuProps {
  mainitem: any;
  ohsFormSettings: any;
  id: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  parentValue: string[];
}

export function MenuWithSubMenu({
  mainitem,
  ohsFormSettings,
  id,
  setOpen,
  parentValue,
}: MenuWithSubMenuProps) {
  const { classes } = useCascadingMenuStyles();
  return mainitem.options && mainitem.options.length > 0 ? (
    mainitem.options.map((item: any) => {
      if (item === 'SEPARATOR') return <Divider component="hr" className={classes.divider} />;
      if (item.static) return <MenuItemLabel name={item.static} icon={item.icon} />;
      return item.options ? (
        <CascadingSubmenu popupId={item.name} title={item.name} item={item}>
          {item.options.length > 0 &&
            item.options.map((subitem: any) => {
              if (subitem === 'SEPARATOR')
                return <Divider component="hr" className={classes.divider} />;
              if (subitem.static)
                return <MenuItemLabel name={subitem.static} icon={subitem.icon} />;
              let renderMenus: any;
              if (subitem.options && subitem.options.length > 0) {
                renderMenus = (
                  <OhsOptionWithSubMenu
                    popupId={subitem.name.replace(/[^A-Z0-9]/gi, '')}
                    name={subitem.name}
                    options={subitem}
                    ohsFormSettings={ohsFormSettings}
                    icon={subitem.icon}
                    id={id}
                    setOpen={setOpen}
                    parentValue={[...(parentValue || []), item.name]}
                  />
                );
              } else {
                renderMenus = (
                  <CascadingMenuItem
                    item={subitem}
                    ohsFormSettings={ohsFormSettings}
                    id={id}
                    setOpen={setOpen}
                    parentValue={[...(parentValue || []), item.name]}
                  >
                    {subitem.name}
                  </CascadingMenuItem>
                );
              }
              return renderMenus;
            })}
        </CascadingSubmenu>
      ) : (
        <CascadingMenuItem
          item={item}
          ohsFormSettings={ohsFormSettings}
          id={id}
          setOpen={setOpen}
          parentValue={parentValue}
        >
          {item.name}
        </CascadingMenuItem>
      );
    })
  ) : (
    <CascadingMenuItem
      item={mainitem}
      ohsFormSettings={ohsFormSettings}
      id={id}
      setOpen={setOpen}
      parentValue={parentValue}
    >
      {mainitem.name}
    </CascadingMenuItem>
  );
}

interface Props {
  name: string;
  options: any;
  popupId: string;
  ohsFormSettings: any;
  icon?: any;
  id: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  parentValue?: string[];
}

export function OhsOptionWithSubMenu(props: Props) {
  const { classes } = useCascadingMenuStyles();
  const popupState = usePopupState({
    popupId: props.popupId,
    variant: 'popover',
  });

  return (
    <Box>
      <MenuItem value={props.name} {...bindFocus(popupState)} className={classes.mainmenuitem}>
        {props?.icon ? <Icon name={props.icon} /> : ''}
        {props.name} <ChevronLeftIcon className={classes.moreArrow} />
      </MenuItem>
      <CascadingMenu
        popupState={popupState}
        anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
        transformOrigin={{ vertical: 'center', horizontal: 'right' }}
      >
        <MenuWithSubMenu
          setOpen={props.setOpen}
          mainitem={props.options}
          ohsFormSettings={props.ohsFormSettings}
          id={props.id}
          parentValue={[...(props.parentValue || []), props.name]}
        />
      </CascadingMenu>
    </Box>
  );
}

OhsOptionWithSubMenu.defaultProps = {
  icon: undefined,
  parentValue: undefined,
};

export default OhsOptionWithSubMenu;
