import OhsUserLocalServices from 'user/OhsUserLocalServices';
import ModuleHomeUrl from 'dashboard/leftSideDrawer/ModuleHomeUrl';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import { fetchCurrentUserRemote } from 'user/OhsUserServices';

import OhsLoginServices from '../../login/OhsLoginServices';

export default async function checkCurrentUrlAfterMorph(tierAfterMorph?: OhsTier) {
  const userAfterMorph = await fetchCurrentUserRemote();
  const user = userAfterMorph ? OhsUserLocalServices.getLocalOhsUser() : undefined;

  const { pathname } = window.location;
  if (user && user._id && pathname) {
    if (user.tierNum < 3) {
      window.location.replace(OhsLoginServices.getHomeUrl(tierAfterMorph || user.tier));
      return;
    }
    const modulePath = pathname.split('/')[1];
    const subPath = pathname.split('/')[2];
    let newUrlAfterMorph = pathname;
    switch (modulePath) {
      case 'admin2': {
        if (subPath === 'user-settings') {
          window.location.reload();
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }
        break;
      }
      case 'action': {
        if (
          user.configs.action?.PERMISSIONS.view === true ||
          user.configs.action?.PERMISSIONS.create === true
        ) {
          if (user.configs.action?.PERMISSIONS.create !== true || subPath !== 'add') {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).action;
          }
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }

        break;
      }
      case 'chemical': {
        if (
          user.configs.chemical?.PERMISSIONS.view === true ||
          user.configs.chemical?.PERMISSIONS.create === true
        ) {
          if (user.configs.chemical?.PERMISSIONS.create !== true || subPath !== 'add') {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).chemical;
          }
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }

        break;
      }
      case 'correspondence': {
        if (
          user.configs.correspondence?.PERMISSIONS.view_prose === true ||
          user.configs.correspondence?.PERMISSIONS.create_prose === true
        ) {
          if (user.configs.correspondence?.PERMISSIONS.create_prose !== true || subPath !== 'add') {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).correspondence;
          }
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }

        break;
      }
      case 'contractor': {
        if (
          user.configs.contractor?.PERMISSIONS.view === true ||
          user.configs.contractor?.PERMISSIONS.create === true
        ) {
          if (user.configs.contractor?.PERMISSIONS.create !== true || subPath !== 'add') {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).contractor;
          }
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }

        break;
      }
      case 'documents': {
        if (
          user.configs.vdoc?.PERMISSIONS.view === true ||
          user.configs.vdoc?.PERMISSIONS.create === true
        ) {
          if (user.configs.vdoc?.PERMISSIONS.create !== true || subPath !== 'add') {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).vdoc;
          }
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }

        break;
      }
      case 'people': {
        if (
          user.configs.hr?.PERMISSIONS.view === true ||
          user.configs.hr?.PERMISSIONS.create === true
        ) {
          if (user.configs.hr?.PERMISSIONS.create !== true || subPath !== 'add') {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).hr;
          }
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }

        break;
      }
      case 'ai': {
        if (
          user.configs.incident?.PERMISSIONS.view === true ||
          user.configs.incident?.PERMISSIONS.create === true
        ) {
          if (user.configs.incident?.PERMISSIONS.create !== true || subPath !== 'add') {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).incident;
          }
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }

        break;
      }
      case 'inspection': {
        if (
          user.configs.inspection?.PERMISSIONS.view === true ||
          user.configs.inspection?.PERMISSIONS.create === true
        ) {
          if (user.configs.inspection?.PERMISSIONS.create !== true || subPath !== 'add') {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).inspection;
          }
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }

        break;
      }
      case 'review-plan': {
        if (
          user.configs.reviewplan?.PERMISSIONS.view === true ||
          user.configs.reviewplan?.PERMISSIONS.create === true
        ) {
          if (user.configs.reviewplan?.PERMISSIONS.create !== true || subPath !== 'add') {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).reviewplan;
          }
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }

        break;
      }
      case 'safety-plan': {
        if (
          user.configs.safetyplan?.PERMISSIONS.view === true ||
          user.configs.safetyplan?.PERMISSIONS.create === true
        ) {
          if (user.configs.safetyplan?.PERMISSIONS.create !== true || subPath !== 'add') {
            newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).safetyplan;
          }
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }

        break;
      }
      case 'visitor': {
        if (user.configs.visitor?.PERMISSIONS.site_view === true) {
          newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).visitor;
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }
        break;
      }
      case 'noticeboard': {
        if (user.configs.noticeboard?.PERMISSIONS.view === true) {
          newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).noticeboard;
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }
        break;
      }
      case 'crisk': {
        if (user.configs.corporateRisk?.PERMISSIONS.view === true) {
          newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).corporateRisk;
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }
        break;
      }
      case 'assets': {
        if (user.configs.asset?.PERMISSIONS.view === true) {
          newUrlAfterMorph = (ModuleHomeUrl[user.tier.type] as any).asset;
        } else {
          newUrlAfterMorph = OhsLoginServices.getHomeUrl(user.tier);
        }
        break;
      }
      default: {
        window.location.reload();
        break;
      }
    }
    window.location.replace(newUrlAfterMorph);
  }
}
