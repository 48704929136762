import { useAppDispatch, useAppSelector } from 'app/hooks';
import { TaskAssignStatusFilter } from 'task/OhsTaskTypes';
import {
  TaskAssignRecordState,
  fetchTaskAssignStatusAsync,
  getOhsTaskAssignState,
  setTaskAssign,
} from 'task/store/OhsTaskAssignSlice';

export default function useOhsTaskAssign() {
  const dispatch = useAppDispatch();

  const tasklistsReducer = useAppSelector(getOhsTaskAssignState) as TaskAssignRecordState;

  const getTaskAssignStatus = (taskStatusObject: TaskAssignStatusFilter) => {
    dispatch(fetchTaskAssignStatusAsync(taskStatusObject));
  };

  const setTaskAssignInfo = (val: TaskAssignStatusFilter) => {
    dispatch(setTaskAssign(val));
  };

  const { isLoading, taskAssignStatus } = tasklistsReducer;
  const isTaskAssignLoading = isLoading;
  return { isTaskAssignLoading, taskAssignStatus, getTaskAssignStatus, setTaskAssignInfo } as const;
}
