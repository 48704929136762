import OhsUserLocalServices from 'user/OhsUserLocalServices';
import { ModuleType } from 'global-services/constants/OhsObject';
import { ModuleUrl } from 'global-services/constants/OhsRoutes';
import { getModuleName } from 'user/OhsUserServices';

import OhsBreadCrumbAction from './OhsBreadcrumbAction';
import OhsBreadcrumbAsset from './OhsBreadcrumbAsset';
import OhsBreadCrumbChemical from './OhsBreadcrumbChemical';
import OhsBreadCrumbContractor from './OhsBreadcrumbContractor';
import OhsBreadCrumbCorporateRisk from './OhsBreadcrumbCorporateRisk';
import OhsBreadCrumbCorrespondence from './OhsBreadcrumbCorrespondence';
import OhsBreadCrumbDocuments from './OhsBreadcrumbDocuments';
import OhsBreadCrumbIncident from './OhsBreadcrumbIncident';
import OhsBreadCrumbInspection from './OhsBreadcrumbInspection';
import OhsBreadCrumbNoticeboard from './OhsBreadcrumbNoticeboard';
import OhsBreadCrumbReviewplan from './OhsBreadcrumbReviewplan';
import OhsBreadCrumbSafetyplan from './OhsBreadcrumbSafetyplan';
import OhsBreadCrumbVisitor from './OhsBreadcrumbVisitor';
import OhsBreadcrumbsNameAndRef from './OhsBreadcrumbsNameAndRef';
import OhsBreadcrumbHR from './OhsBreadcrumbHR';
import OhsBreadcrumbTraining from './OhsBreadcrumbTraining';

const getOhsBreadcrumbsNameAndRef = (): OhsBreadcrumbsNameAndRef => {
  const user = OhsUserLocalServices.getLocalOhsUser();
  const { pathname } = window.location;
  let ohsBreadcrumbsNameAndRef = new OhsBreadcrumbsNameAndRef();
  if (user && user._id && pathname) {
    if (user.tierNum > 2) {
      ohsBreadcrumbsNameAndRef.homeRef = { href: '/task/active' };
    } else {
      ohsBreadcrumbsNameAndRef.homeRef = { href: '/admin2/home' };
    }
    const modulePath = pathname.split('/')[1];
    const subPath = pathname.split('/')[2];
    const viewPath = pathname.split('/')[3];
    const operationPath = pathname.substring(modulePath.length + 1);
    switch (modulePath) {
      case ModuleUrl.Admin2: {
        if (subPath === 'user-settings') {
          ohsBreadcrumbsNameAndRef.leafName = { name: 'User Settings' };
        } else if (subPath === 'export') {
          ohsBreadcrumbsNameAndRef.leafName = {
            name: getModuleName(user.configs, ModuleType.Export),
          };
        } else {
          ohsBreadcrumbsNameAndRef.leafName = {
            name: user.configs.admin2?.TITLE ?? 'Administration',
          };
        }
        break;
      }
      case ModuleUrl.MobileAppAdmin: {
        ohsBreadcrumbsNameAndRef.leafName = { name: 'Mobile App Admin' };
        break;
      }
      case ModuleUrl.DashboardBanner: {
        ohsBreadcrumbsNameAndRef.leafName = { name: 'Dashboard Banner' };
        break;
      }
      case ModuleUrl.Documents: {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbDocuments(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          operationPath,
          user
        );
        break;
      }
      case ModuleUrl.SafetyPlan: {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbSafetyplan(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case ModuleUrl.Inspection: {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbInspection(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case ModuleUrl.ResourceCentre: {
        ohsBreadcrumbsNameAndRef.leafName = { name: 'Resource Centre' };
        break;
      }
      case ModuleUrl.Action: {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbAction(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          operationPath,
          user
        );
        break;
      }
      case ModuleUrl.Asset: {
        ohsBreadcrumbsNameAndRef = OhsBreadcrumbAsset(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case ModuleUrl.Chemical: {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbChemical(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case ModuleUrl.Correspondence: {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbCorrespondence(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          user
        );
        break;
      }
      case ModuleUrl.Contractor: {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbContractor(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          user
        );
        break;
      }
      case ModuleUrl.CorporateRisk: {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbCorporateRisk(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case ModuleUrl.People: {
        ohsBreadcrumbsNameAndRef =
          subPath === 'training'
            ? OhsBreadcrumbTraining(
                ohsBreadcrumbsNameAndRef,
                modulePath,
                pathname.split('/')[3],
                pathname.split('/')[4],
                operationPath,
                user
              )
            : OhsBreadcrumbHR(
                ohsBreadcrumbsNameAndRef,
                modulePath,
                subPath,
                viewPath,
                operationPath,
                user
              );
        break;
      }
      case ModuleUrl.Incident: {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbIncident(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case ModuleUrl.Visitor: {
        const varPath = pathname.split('/')[4];
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbVisitor(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          varPath,
          user
        );
        break;
      }
      case ModuleUrl.ReviewPlan: {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbReviewplan(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          operationPath,
          user
        );
        break;
      }
      case ModuleUrl.Noticeboard: {
        ohsBreadcrumbsNameAndRef = OhsBreadCrumbNoticeboard(
          ohsBreadcrumbsNameAndRef,
          modulePath,
          subPath,
          viewPath,
          user
        );
        break;
      }
      default: {
        // statements;
        break;
      }
    }
  }
  return ohsBreadcrumbsNameAndRef;
};
export default getOhsBreadcrumbsNameAndRef;
