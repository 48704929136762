import React from 'react';

import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import OhsModuleBackground from 'global-components/register/OhsModuleBackground';
import { ModuleRoute, ModuleUrl, OhsModuleTypeByUrl } from 'global-services/constants/OhsRoutes';
import OhsModuleHeader from 'global-components/register/OhsModuleHeader';
import { getModuleName } from 'user/OhsUserServices';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import OhsNoAccessMessage from './OhsNoAccessMessage';

const useStyles = makeStyles()(() => ({
  container: {
    width: '100% !important',
    height: '100% !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function OhsNoAccessPage() {
  const { classes } = useStyles();
  const location = useLocation();

  const pathInclude = (word: string) => _.includes(location.pathname, word);
  const user = OhsUserLocalServices.getLocalOhsUser();
  const isHomepage = pathInclude(ModuleRoute.Home);

  const modulePath: ModuleUrl = location.pathname.split('/')[1] as ModuleUrl;
  const moduleType = _.get(OhsModuleTypeByUrl, modulePath) || '';
  const moduleName = moduleType ? getModuleName(user?.configs, moduleType) : '';

  return (
    <OhsModuleBackground>
      {isHomepage && <OhsModuleHeader title={`${moduleName} Register`} as="h2" />}
      <div className={classes.container}>
        <OhsNoAccessMessage title="Permission Denied" />
      </div>
    </OhsModuleBackground>
  );
}

export default OhsNoAccessPage;
