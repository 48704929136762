import { ApiResponseType, OhsApiRequestName, RequestType } from 'global-services/api/OhsApiModels';
import OhsApiRequest from 'global-services/api/OhsApiRequest';
import { ModuleType } from 'global-services/constants/OhsObject';
import OhsSessionServices from 'user/session/OhsSessionServices';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import { OhsMfaMethod } from '../OhsMfaTypes';

export const fetchUserMfaList = (): Promise<any | null> => {
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.MfaList);
};

export const userMfaVerifyChallenge = (methodId: string): Promise<any> => {
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.MfaVerifyChallenge, {
    methodId,
  });
};

export const userMfaVerifyCode = (
  code: string,
  bufferDuration: boolean
): Promise<ApiResponseType<OhsMfaMethod> | null> => {
  const bufferToggle = bufferDuration ? { toggle: bufferDuration } : {};
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.MfaVerify, {
    code,
    ...bufferToggle,
  });
};

export const userMfaVerifyRecoveryCode = (
  code: string,
  bufferDuration: boolean
): Promise<ApiResponseType<OhsMfaMethod> | null> => {
  const bufferToggle = bufferDuration ? { toggle: bufferDuration } : {};
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.MfaVerify, {
    code,
    ...bufferToggle,
  });
};

export const userMfaEnrollRequest = (
  methodType: string,
  destination?: string
): Promise<ApiResponseType<OhsMfaMethod> | null> => {
  let payload = {
    methodType,
    destination: destination ?? undefined,
  };
  if (methodType === 'app') payload = { methodType, destination: undefined };
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.MfaEnroll, payload);
};

export const userMfaEnrollConfirmRequest = (
  code: string
): Promise<ApiResponseType<OhsMfaMethod> | null> => {
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.MfaEnrollConfirm, {
    code,
  });
};

export const userMfaDelete = (methodId: string): Promise<ApiResponseType<OhsMfaMethod> | null> => {
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.MfaDeleteMethod, {
    methodId,
  });
};

export const userMfaReset = (): Promise<ApiResponseType<OhsMfaMethod> | null> => {
  return OhsApiRequest(RequestType.Admin, ModuleType.User, OhsApiRequestName.MfaReset);
};

export const userMfaCancel = async () => {
  const response: any = await OhsApiRequest(
    RequestType.Admin,
    ModuleType.User,
    OhsApiRequestName.MfaCancel
  );

  if (response.success) {
    OhsSessionServices.setUserToken(OhsSessionServices.getPriorActiveToken());

    OhsUserLocalServices.removeLocalOhsUser();
    window.location.reload();
  }
};
