import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { OhsFilterList } from 'dashboard/topFilter/OhsFilterModels';
import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
} from 'global-services/api/OhsApiModels';
import { setOhsLocalStorage } from 'global-services/OhsDataParse';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';
import { OhsGlobalSearchPayload } from 'search/OhsSearchModels';
import globalSearchKey from 'search/OhsSearchServices';
import { TaskListsRecord } from 'task/OhsTaskTypes';
import { updateSearchInfo } from 'search/OhsSearchUtils';
import OhsUserLocalServices from 'user/OhsUserLocalServices';
import { OhsUser } from 'user/OhsUser';

import getContractorLists, {
  getContractorAllocatedList,
  getContractorLinkedTasksList,
} from './OhsContractorService';
import {
  ContractorLinkedTaskListRes,
  OhsContractorListsFilterPayload,
  OhsOrgTabFilterPayload,
  OhsWorkplaceTabFilterPayload,
} from './models/OhsContractorModels';
import OhsContractorRecord from './models/OhsContractorRecord';
import parseListAllocations from './utils/OhsParseListAllocations';

const getFilterBasedOnTab = {
  org: {
    viewPreset: 'view_1',
    archived: false,
    categories: [],
    subcategories: [],
  },
  wp: {
    viewPreset: 'view_2',
    archived: false,
    allocatedToWorkplace: true,
    categories: [],
    subcategories: [],
    workplaces: [],
    workplaceOwned: true,
  },
  li: {
    viewPreset: 'view_3',
    archived: false,
    allocatedToOrg: true,
    allocatedToWorkplace: true,
    notAllocated: true,
    categories: [],
    subcategories: [],
  },
  contractorList_tier4: {
    viewPreset: 'view_4',
    archived: false,
    allocatedToWorkplace: true,
    workplaceOwned: true,
    categories: [],
    subcategories: [],
  },
};

export interface ContractorListsRecordState {
  isLoading: boolean;
  contractorLists: ApiResListsType<any[]> | null;
  currentPage: number;
  linkedTasksList: {
    isLoading: boolean;
    taskList: TaskListsRecord[];
  };
}

const initialState: ContractorListsRecordState = {
  isLoading: false,
  contractorLists: null,
  currentPage: 1,
  linkedTasksList: {
    isLoading: false,
    taskList: [],
  },
};

const contractorSearch = async (
  search: OhsGlobalSearchPayload,
  count: boolean,
  page: number,
  apiRequestName?: OhsApiRequestName
): Promise<ApiResListsType<OhsContractorRecord[]> | null> => {
  const requiredSearchFilters =
    apiRequestName === OhsApiRequestName.WorkerGroupList
      ? {
          archived: search.filter?.archived,
          page: search.filter?.page,
          count: search.filter?.count,
          modules: search.filter?.modules,
        }
      : search.filter;
  const searchInfo = { ...search, filter: { ...requiredSearchFilters, count, page } };
  const globalSearchRes: any = await globalSearchKey(searchInfo, apiRequestName);
  return globalSearchRes.result;
};

export const getContractorListsWithAllocations = (
  state: RootState,
  filterInfo:
    | OhsOrgTabFilterPayload
    | OhsWorkplaceTabFilterPayload
    | OhsContractorListsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const { globalSearch, contractor } = state;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const hasGlobalSearch = globalSearch.searchInfo.searchKey !== '';
  const searchInfo = updateSearchInfo(globalSearch.searchInfo, OhsApiRequestName.List, user);
  const getContractorListsReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const contractorList = await (hasGlobalSearch
      ? contractorSearch(searchInfo, false, contractor.currentPage, OhsApiRequestName.List)
      : getContractorLists(filterInfo));
    // exit if list result is empty
    if (contractorList && contractorList?.items.length === 0) {
      return new Promise((resolve) => {
        resolve(contractorList);
      });
    }

    const getIDs = contractorList?.items.map((item: OhsContractorRecord) => item._id) as string[];
    const allocatedList = await getContractorAllocatedList(getIDs);
    const contractorListWithAllocations = parseListAllocations(
      contractorList,
      allocatedList
    ) as ApiResListsType<OhsContractorRecord[]> | null;

    return new Promise((resolve) => {
      resolve(contractorListWithAllocations);
    });
  };

  return getContractorListsReq();
};

const contractorListsRPC = async (
  state: RootState,
  user: OhsUser,
  setContractorListsFilters:
    | OhsOrgTabFilterPayload
    | OhsWorkplaceTabFilterPayload
    | OhsContractorListsFilterPayload,
  searchInfo: OhsGlobalSearchPayload
): Promise<any> => {
  const userTier = user?.tierNum ?? 0;
  const hasGlobalSearch = searchInfo?.searchKey !== '';
  if (userTier && userTier === 3 && setContractorListsFilters?.viewPreset === 'view_3') {
    const response = await getContractorListsWithAllocations(state, setContractorListsFilters);
    return response;
  }

  if (setContractorListsFilters?.viewPreset !== 'view_3') {
    const response = await getContractorLists(setContractorListsFilters);
    return response;
  }
  if (userTier && userTier === 5) {
    const response = hasGlobalSearch
      ? await contractorSearch(
          searchInfo,
          setContractorListsFilters.count,
          setContractorListsFilters.page,
          OhsApiRequestName.WorkerGroupList
        )
      : await getContractorLists(setContractorListsFilters);
    return response;
  }
  return [];
};

const globalRequiredFilter = (
  inputObj: Record<string, any>,
  keysToKeep: Record<string, any>
): Record<string, any> => {
  return Object.keys(keysToKeep).reduce((filteredObj, key) => {
    if (
      Object.prototype.hasOwnProperty.call(keysToKeep, key) &&
      Object.prototype.hasOwnProperty.call(inputObj, key)
    ) {
      filteredObj[key] = inputObj[key];
    }
    return filteredObj;
  }, {} as Record<string, any>);
};

export const fetchContractorListsAsync = createAsyncThunk<
  ApiResListsType<OhsContractorRecord[]> | null,
  OhsTabKeyTypes.List | OhsTabKeyTypes.Workplace | OhsTabKeyTypes.Organisation,
  { state: RootState }
>(
  'contractor/fetchContractorList',
  async (
    props: OhsTabKeyTypes.List | OhsTabKeyTypes.Workplace | OhsTabKeyTypes.Organisation,
    thunkAPI
  ) => {
    const state = thunkAPI.getState();
    const filter = thunkAPI.getState().globalfilter.filterInfo as OhsFilterList;
    const globalContractorFilter = filter.contractorModule;
    const { globalSearch, contractor } = thunkAPI.getState();
    const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
    const hasGlobalSearch = globalSearch.searchInfo.searchKey !== '';
    const userTier = user?.tierNum ?? 0;

    let requiredFilter: any = {};
    if (props === OhsTabKeyTypes.List && userTier >= 4) {
      requiredFilter = getFilterBasedOnTab.contractorList_tier4;
    } else {
      requiredFilter = getFilterBasedOnTab[props];
    }
    const setContractorListFilters: any = {
      ...globalRequiredFilter(globalContractorFilter, requiredFilter),
      viewPreset: requiredFilter.viewPreset,
      page: contractor.currentPage > 10 ? 0 : contractor.currentPage,
      next: contractor.currentPage > 10 ? contractor.contractorLists?.pagination.next ?? '' : '',
      sort: { ...JSON.parse(String(globalContractorFilter.sort)) },
      count: false,
    };
    let searchRequestApi = OhsApiRequestName.List;
    if (props === OhsTabKeyTypes.Organisation) {
      searchRequestApi = OhsApiRequestName.OrgList;
    } else if (props === OhsTabKeyTypes.Workplace) {
      searchRequestApi = OhsApiRequestName.OrgWorkplaceList;
    }
    const requiredApirequest =
      user?.tierNum === 5 ? OhsApiRequestName.WorkerGroupList : searchRequestApi;

    const searchInfo = updateSearchInfo(globalSearch.searchInfo, requiredApirequest, user);
    try {
      if (userTier === 3 && OhsTabKeyTypes.List) {
        const response = await contractorListsRPC(
          state,
          user,
          setContractorListFilters,
          globalSearch.searchInfo
        );
        return response;
      }
      const response = await (hasGlobalSearch
        ? contractorSearch(searchInfo, false, contractor.currentPage, requiredApirequest)
        : contractorListsRPC(state, user, setContractorListFilters, globalSearch.searchInfo));
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchContractorListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsContractorRecord[]> | null,
  OhsTabKeyTypes.List | OhsTabKeyTypes.Workplace | OhsTabKeyTypes.Organisation,
  { state: RootState }
>(
  'contractor/fetchContractorListWithCount',
  async (
    props: OhsTabKeyTypes.List | OhsTabKeyTypes.Workplace | OhsTabKeyTypes.Organisation,
    thunkAPI
  ) => {
    const filter = thunkAPI.getState().globalfilter.filterInfo as OhsFilterList;
    const globalContractorFilter = filter.contractorModule;
    const { globalSearch, contractor } = thunkAPI.getState();
    const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
    const hasGlobalSearch = globalSearch.searchInfo.searchKey !== '';
    const userTier = user?.tierNum ?? 0;
    let requiredFilter: any = {};
    if (props === OhsTabKeyTypes.List && userTier >= 4) {
      requiredFilter = getFilterBasedOnTab.contractorList_tier4;
    } else {
      requiredFilter = getFilterBasedOnTab[props];
    }
    const contractorListFilterWithCount: any = {
      ...globalRequiredFilter(globalContractorFilter, requiredFilter),
      viewPreset: requiredFilter.viewPreset,
      page: 1,
      sort: { order: 1, key: 'businessName' },
      count: true,
    };
    let searchRequestApi = OhsApiRequestName.List;
    if (props === OhsTabKeyTypes.Organisation) {
      searchRequestApi = OhsApiRequestName.OrgList;
    } else if (props === OhsTabKeyTypes.Workplace) {
      searchRequestApi = OhsApiRequestName.OrgWorkplaceList;
    }
    const requiredApirequest =
      user?.tierNum === 5 ? OhsApiRequestName.WorkerGroupList : searchRequestApi;

    const searchInfo = updateSearchInfo(globalSearch?.searchInfo, requiredApirequest, user);
    try {
      const response = hasGlobalSearch
        ? await contractorSearch(searchInfo, true, contractor.currentPage, requiredApirequest)
        : await getContractorLists(contractorListFilterWithCount);
      return response;
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

export const fetchContractorLinkedTasksListAsync = createAsyncThunk<
  ApiResponseType<ContractorLinkedTaskListRes> | null,
  string,
  { state: RootState }
>('contractor/fetchContractorLinkedTasksList', async (contractorId: string, thunkAPI) => {
  try {
    const response = await getContractorLinkedTasksList(contractorId);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const contractorSlice = createSlice({
  name: 'contractor',
  initialState,
  reducers: {
    setCurrentRegisterPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    setContractorIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContractorListsAsync.pending, (state) => {
        state.isLoading = true;
        state.contractorLists = {
          items: [],
          pagination: state.contractorLists?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchContractorListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          state.contractorLists = action.payload;
          let setItemsLastId = '';
          const contractorListsItems = [...state.contractorLists.items];
          if (contractorListsItems.length > 0) setItemsLastId = contractorListsItems.pop()._id;
          state.contractorLists.pagination.next = setItemsLastId;
          state.contractorLists.pagination.totalPages = 1;
          state.isLoading = false;
        }
      })
      .addCase(fetchContractorListsAsync.rejected, (state) => {
        state.contractorLists = null;
      })
      .addCase(fetchContractorListsCountAsync.fulfilled, (state, action) => {
        if (state.contractorLists && action.payload) {
          setOhsLocalStorage('contractorListtotalPages', action.payload.pagination.totalPages);
          state.contractorLists.pagination.totalPages = action.payload.pagination.totalPages ?? 0;
        }
      })
      .addCase(fetchContractorLinkedTasksListAsync.pending, (state) => {
        state.linkedTasksList.taskList = [];
        state.linkedTasksList.isLoading = true;
      })
      .addCase(fetchContractorLinkedTasksListAsync.fulfilled, (state, action) => {
        if (action.payload?.success) {
          state.linkedTasksList.taskList = action.payload.result?.items ?? [];
          state.linkedTasksList.isLoading = false;
        }
      });
  },
});

const contractorState = (state: RootState) => state.contractor;
export const getOhsContractorModuleState = createSelector(
  [contractorState],
  (contractor) => contractor
);

export const { setCurrentRegisterPage, setContractorIsLoading } = contractorSlice.actions;
export const contractorModuleReducer = contractorSlice.reducer;
