import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';
import { OhsApiRequestName } from 'global-services/api/OhsApiModels';
import { TierType } from 'global-services/constants/OhsObject';
import { OhsUser } from 'user/OhsUser';

import { GlobalSearchState, OhsGlobalSearchPayload } from './OhsSearchModels';

const getPayload = (globalSearch: GlobalSearchState, count: boolean) => {
  const { searchInfo, currentPage } = globalSearch;
  const page = currentPage > 10 ? 0 : currentPage;
  const payload = {
    ...globalSearch,
    searchInfo: {
      ...searchInfo,
      filter: {
        ...searchInfo.filter,
        page,
        count,
      },
    },
  };
  return payload;
};

export const getApiReqNameByTab = (tabKey: OhsTabKeyTypes): OhsApiRequestName => {
  switch (tabKey) {
    case OhsTabKeyTypes.Organisation:
      return OhsApiRequestName.OrgList;
    case OhsTabKeyTypes.Workplace:
      return OhsApiRequestName.OrgWorkplaceList;
    default:
      return OhsApiRequestName.List;
  }
};

export const updateSearchInfo = (
  searchInfo: OhsGlobalSearchPayload,
  apiRequestName: OhsApiRequestName,
  user: OhsUser
): OhsGlobalSearchPayload => {
  if (user?.tier.type === TierType.T3 && apiRequestName === OhsApiRequestName.List) {
    return { ...searchInfo, filter: { ...searchInfo.filter, skipAllocatedRecords: true } };
  }
  return searchInfo;
};

export default getPayload;
