import { TierType } from 'global-services/constants/OhsObject';
import { OhsUser } from 'user/OhsUser';

// SITE
export const withVisitorSignOutPermission = (user: OhsUser | undefined, isArchived: boolean) => {
  if (user && !isArchived) {
    const userTier = user?.tier?.type || '';
    const validUserType = userTier === TierType.T3 || userTier === TierType.T4;
    const withPermission =
      user.configs.visitor?.PERMISSIONS.visit_arrive ||
      user.configs.visitor?.PERMISSIONS.visit_leave ||
      false;

    if (validUserType && withPermission) return true;
  }
  return false;
};

export const withVisitorSiteEditViewPermission = (user: OhsUser | undefined) => {
  if (user) {
    const userTier = user?.tier?.type || '';
    const validUserType = userTier === TierType.T3;
    const withPermission = user.configs.visitor?.PERMISSIONS.site_edit || false;
    if (validUserType && withPermission) return true;
  }
  return false;
};

export const withVisitorSiteEditPermission = (
  user: OhsUser | undefined,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && !isArchived && recordTier) {
    const userTier = user?.tier?.type || '';

    if (withVisitorSiteEditViewPermission(user) && userTier === recordTier) return true;
  }
  return false;
};

export const withVisitorSiteClonePermission = (
  user: OhsUser | undefined,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && !isArchived && recordTier) {
    const userTier = user?.tier?.type || '';
    const validTier = userTier === recordTier;
    const validUserType = userTier === TierType.T3;
    const withPermission = user.configs.visitor?.PERMISSIONS.site_clone || false;
    if (validTier && validUserType && withPermission) return true;
  }
  return false;
};

export const withVisitorSiteAllocatePermission = (
  user: OhsUser | undefined,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && !isArchived && recordTier) {
    const userTier = user?.tier?.type || '';
    const validTier = userTier === recordTier;
    const validUserType = userTier === TierType.T3;
    const withPermission = user.configs.visitor?.PERMISSIONS.site_allocate || false;
    if (validTier && validUserType && withPermission) return true;
  }
  return false;
};

export const withVisitorSiteArchivePermission = (
  user: OhsUser | undefined,
  recordTier: TierType | undefined
) => {
  if (user && recordTier) {
    const userTier = user?.tier?.type || '';
    const validTier = userTier === recordTier;
    const validUserType = userTier === TierType.T3;
    const withPermission = user.configs.visitor?.PERMISSIONS.site_archive || false;
    if (validTier && validUserType && withPermission) return true;
  }
  return false;
};

// FORM
export const withVisitorFormEditViewPermission = (user: OhsUser) => {
  if (user) {
    const userTier = user?.tier?.type || '';
    const validUserType = userTier === TierType.T3;
    const withPermission = user.configs.visitor?.PERMISSIONS.form_edit || false;
    if (validUserType && withPermission) return true;
  }
  return false;
};

export const withVisitorFormEditPermission = (
  user: OhsUser | undefined,
  recordTier: TierType | undefined,
  isArchived: boolean
) => {
  if (user && !isArchived && recordTier) {
    const userTier = user?.tier?.type || '';

    if (withVisitorFormEditViewPermission(user) && userTier === recordTier) return true;
  }
  return false;
};

export const withVisitorFormArchivePermission = (
  user: OhsUser | undefined,
  recordTier: TierType | undefined
) => {
  if (user && recordTier) {
    const userTier = user?.tier?.type || '';
    const validTier = userTier === recordTier;
    const validUserType = userTier === TierType.T3;
    const withPermission = user.configs.visitor?.PERMISSIONS.form_archive || false;
    if (validTier && validUserType && withPermission) return true;
  }
  return false;
};
