import Cookies from 'js-cookie';

import { getOhsLocalStorage } from './OhsDataParse';
import { OhsMultiUserSessionToken, OhsSessionToken } from './constants/OhsStorageNames';

export const isAuthenticated = (): boolean => {
  const token = Cookies.get(OhsSessionToken);
  let flag = false;
  if (token) {
    flag = true;
  }
  return flag;
};

export const isMultiAuthenticated = (): boolean => {
  const token = Cookies.get(OhsMultiUserSessionToken);
  let flag = false;
  if (token) {
    flag = true;
  }
  return flag;
};

export const isMorphFlagName = 'isMorph';

export const isMorph = (): boolean | null => {
  const isMorphString = getOhsLocalStorage(isMorphFlagName);
  if (isMorphString === 'true') {
    return true;
  }
  if (isMorphString === 'false') {
    return false;
  }
  return null;
};
