import React from 'react';

import { FieldValues } from 'react-hook-form';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import OhsMultipleSelectField from 'global-components/form/fields/OhsMultipleSelectField';
import { getModuleOptions } from 'user/OhsUserServices';
import { ModuleType } from 'global-services/constants/OhsObject';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

interface Props {
  id?: string;
  moduleOptionLists?: {
    name: string;
    key: ModuleType;
  }[];
  ohsFormSettings: OhsFormSettings<FieldValues>;
  singleSelect?: boolean;
  disabled?: boolean;
  required?: boolean;
}
function OhsModuleFilter(props: Props) {
  const user = OhsUserLocalServices.getLocalOhsUser();
  const moduleOptionLists = props.moduleOptionLists ?? getModuleOptions(user?.configs);
  const moduleOptions = moduleOptionLists.map((list: any) => {
    return { name: list.name, value: list.key };
  });

  return props.singleSelect ? (
    <OhsSelectField
      title="Modules"
      id={props.id || 'modules'}
      required={props.required ?? false}
      options={moduleOptions}
      ohsFormSettings={props.ohsFormSettings}
      disabled={props.disabled}
    />
  ) : (
    <OhsMultipleSelectField
      title="Modules"
      id={props.id || 'modules'}
      required={props.required ?? false}
      options={moduleOptions}
      ohsFormSettings={props.ohsFormSettings}
      disabled={props.disabled}
    />
  );
}

OhsModuleFilter.defaultProps = {
  id: undefined,
  moduleOptionLists: undefined,
  singleSelect: false,
  disabled: false,
  required: false,
};

export default OhsModuleFilter;
