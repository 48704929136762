import React from 'react';

import { makeStyles } from 'tss-react/mui';
import { FormControl, ListItemText, MenuItem, TextField } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import _ from 'lodash';
import Checkbox from '@mui/material/Checkbox';

import { OhsFormFieldBaseProps } from '../services/OhsFormModels';
import { returnFieldStyle, returnVariant } from '../components/OhsFieldLayout';
import OhsFormFieldLayout from '../components/OhsFormFieldLayout';

const useStyles = makeStyles()(() => ({
  searchMenuItem: {
    padding: '2px 0',
    zIndex: '999',
    margin: '10px',
    background: 'white',
  },
  selectAllMenuItem: {
    background: '#fafafa',
    padding: '0',
    borderTop: '1px solid #e2e2e2',
    borderBottom: '1px solid #e2e2e2',
  },
  menuItem: {
    flexDirection: 'row',
    padding: '2px 0',
    marginBottom: '1px',
    alignItems: 'flex-start',
    wordBreak: 'break-word',
  },
  menuItemText: { overflowWrap: 'break-word', whiteSpace: 'normal', padding: '9px 0 0 0' },
  // selectField: {
  //   '& .MuiSelect-select': {
  //     maxWidth: '600px', // static measurement gave display issues currently
  //   },
  // },
  optionContainer: {
    maxWidth: '40% !important',
  },
}));
export interface MultipleOptionListTypes {
  name: string;
  value: string | number;
  searched?: boolean;
  selected?: boolean;
}
interface Props extends OhsFormFieldBaseProps {
  options: Array<MultipleOptionListTypes>;
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
}
function InputEle(props: Props) {
  const { classes } = useStyles();
  const [searchKeyword, setSearchKeyword] = React.useState('');
  const [optionLists, setOptionLists] = React.useState<MultipleOptionListTypes[]>([]);
  const [searchLists, setSearchOptionLists] = React.useState<MultipleOptionListTypes[]>([]);
  const error = _.get(props.ohsFormSettings.useFormMethods.formState.errors, props.id);
  const result = props.ohsFormSettings.useFormMethods.watch(props.id) || [];
  const defaultOptions = React.useMemo(
    () =>
      props.options.map((option: MultipleOptionListTypes) => {
        return { ...option, selected: result.includes(option.value), searched: false };
      }),
    [result?.join(), props.options]
  );
  const isAllSelected =
    optionLists.length === optionLists.filter((item: any) => item.selected).length;
  const checkifAllSearchIsSelected = optionLists.filter(
    (item: any) => item.searched && item.selected
  );
  const isAllSearchSelected = searchLists.length === checkifAllSearchIsSelected.length;
  const isDisabled = props.disabled || false;

  const getSelectedOptions = (options: MultipleOptionListTypes[]) => {
    return options.filter((item: any) => item.selected).map((item: any) => item.value);
  };

  const setSelectedOptions = (
    options: MultipleOptionListTypes[],
    selectedValues: string[]
  ): MultipleOptionListTypes[] => {
    return options.map((option: MultipleOptionListTypes) => {
      const optionVal = option.value as string;
      return {
        ...option,
        selected: selectedValues.includes(optionVal),
      };
    });
  };
  const handleChange = (event: SelectChangeEvent) => {
    const value = (event.target.value as unknown as string[]) ?? [];
    if (value[value.length - 1] === 'all') {
      // Select ALL checkbox
      const updateSearchOptions = optionLists.map((item: any) => {
        const searchedItems = searchLists.map((x: any) => x.value);
        const updateSelected = !isAllSearchSelected;
        return {
          ...item,
          selected: searchedItems.includes(item.value) ? updateSelected : item.selected,
        };
      });

      const selectAllOptions = defaultOptions.map((option: MultipleOptionListTypes) => {
        return { ...option, selected: true };
      });
      const getAllOptions = result.length === optionLists.length ? [] : selectAllOptions;
      const updateSelectedOptions = searchKeyword !== '' ? updateSearchOptions : getAllOptions;
      const selectedOptions = getSelectedOptions(updateSelectedOptions);

      const getSelectedValues = setSelectedOptions(optionLists, selectedOptions);
      setOptionLists(getSelectedValues);
      props.ohsFormSettings.useFormMethods.setValue(
        props.id,
        getSelectedOptions(getSelectedValues)
      );
    } else {
      const getSelectedValues = setSelectedOptions(optionLists, value);
      setOptionLists(getSelectedValues);
      props.ohsFormSettings.useFormMethods.setValue(props.id, value);
    }
    props.ohsFormSettings.useFormMethods.trigger(props.id);
  };

  React.useEffect(() => {
    props.ohsFormSettings.useFormMethods.register(props.id, {
      validate: {
        notEmpty: () => {
          const value = props.ohsFormSettings.useFormMethods.getValues(props.id) ?? [];
          if (props.required === true) {
            if (value && value.length > 0) {
              return true;
            }
          } else {
            return true;
          }

          return false;
        },
      },
    });
  }, [props.id, props.ohsFormSettings.useFormMethods, props.required, result]);

  React.useEffect(() => {
    if (searchKeyword !== '') {
      // set 'searched' property to true if the keyword is found within the option name
      const filteredOptions = optionLists.map((option: MultipleOptionListTypes) => {
        const searchedOption = option.name
          .toLocaleLowerCase()
          .includes(searchKeyword.toLocaleLowerCase());
        const updatedOption = { ...option, searched: searchedOption };
        return updatedOption;
      });
      setSearchOptionLists(filteredOptions.filter((item: any) => item.searched === true));
      setOptionLists(filteredOptions);
    } else {
      setSearchOptionLists([]);
      setOptionLists(optionLists);
    }
  }, [searchKeyword]);

  React.useEffect(() => {
    if (!_.isEqual(optionLists, defaultOptions) && !searchKeyword) {
      setOptionLists(defaultOptions);
    }
  }, [defaultOptions]);

  const stopImmediatePropagation = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return (
    <FormControl component="fieldset" fullWidth>
      <Select
        error={error != null}
        fullWidth
        size="small"
        margin="dense"
        required={props.required}
        displayEmpty
        multiple
        value={result}
        onChange={handleChange}
        variant={returnVariant(props.ohsFormSettings.layout)}
        style={returnFieldStyle(props.ohsFormSettings.layout, {
          marginTop: '8px',
        })}
        renderValue={(): string | JSX.Element => {
          // get the 'name' of the selected value - { name: 'Name 1'; value: 'name1' }
          const getSelected = optionLists.filter((item: any) => item.selected === true);
          const selectedItems =
            getSelected.length > 0 ? (
              getSelected.map((item: any) => item.name).join(', ')
            ) : (
              <span style={{ color: 'gray' }}>-- Please Select --</span>
            );

          return selectedItems;
        }}
        disabled={isDisabled}
        MenuProps={{
          PaperProps: {
            className: classes.optionContainer,
          },
        }}
      >
        <MenuItem
          onKeyDown={(e) => e.stopPropagation()}
          onClickCapture={stopImmediatePropagation}
          className={classes.searchMenuItem}
        >
          <TextField
            value={searchKeyword}
            size="small"
            id="search-options"
            label="Search Options"
            variant="outlined"
            sx={{ width: '100%', background: 'white' }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchKeyword(event.target.value);
            }}
          />
        </MenuItem>

        <MenuItem value="all" className={classes.selectAllMenuItem}>
          <Checkbox
            checked={searchKeyword !== '' ? isAllSearchSelected : isAllSelected}
            indeterminate={result.length > 0 && result.length < optionLists.length}
          />
          <ListItemText primary="Select All" />
        </MenuItem>
        {optionLists.map((item: MultipleOptionListTypes, index: number) => {
          return (
            <MenuItem
              key={index}
              value={item.value}
              className={classes.menuItem}
              sx={{
                ...(searchKeyword
                  ? {
                      display: item.searched ? 'flex' : 'none',
                    }
                  : {
                      display: 'flex',
                    }),
              }}
            >
              <Checkbox checked={item.selected ?? false} />
              <ListItemText primary={item.name} className={classes.menuItemText} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default function OhsMultipleSelectField(props: Props) {
  return <OhsFormFieldLayout {...props} inputEle={<InputEle {...props} />} />;
}
