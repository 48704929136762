import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid } from '@mui/material';
import { FieldValues } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import { useAppSelector } from 'app/hooks';
import OhsSelectField from 'global-components/form/fields/OhsSelectField';
import { OhsTabKeyTypes } from 'global-components/tab/OhsTabModels';
import { useQuery } from 'global-components/tab/OhsTab';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import OhsCheckboxFilter from '../filterfields/OhsCheckboxFilter';
import { getGlobalFilter } from '../OhsTopFilterSlice';
import { OhsFilterList } from '../OhsFilterModels';
import OhsWorkplaceFilter from '../filterfields/OhsWorkplaceFilter';
import OhsCategoriesMultSelectFilter from '../filterfields/OhsCategoriesFilter';
import OhsSubcategoriesMultiSelectFilter from '../filterfields/OhsSubcategoriesFilter';

const useStyles = makeStyles()(() => ({
  root: {
    flexGrow: 1,
  },
  fieldWrap: {
    margin: '10px 0 10px 0',
  },
  filterContent: {
    width: '100%',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
interface Props {
  ohsFormSettings: OhsFormSettings<FieldValues>;
}

const conditions = {
  sortBy: 'sortBy',
  categories: 'categories',
  subCategories: 'subCategories',
  workplaceFilter: 'workplaceFilter',
  archived: 'archived',
  allocatedToOrg: 'allocatedToOrg',
  notAllocated: 'notAllocated',
  workplaceOwned: 'workplaceOwned',
  allocatedToWorkplace: 'allocatedToWorkplace',
};

const permissions = {
  sortBy: false,
  subCategories: false,
  categories: false,
  workplaceFilter: false,
  archived: false,
  allocatedToOrg: false,
  notAllocated: false,
  workplaceOwned: false,
  allocatedToWorkplace: false,
};

function OhsReviewPlanFilters(props: Props) {
  const { classes } = useStyles();
  const filter = useAppSelector(getGlobalFilter) as OhsFilterList;
  const user = OhsUserLocalServices.getLocalOhsUser();
  const userTierNum = user?.tierNum ?? 0;
  const getModuleReviewPlanLists = user?.configs.reviewplan?.CLASSIFICATION_LIST ?? [];

  const query = useQuery();
  const tabKey = query.get('tab') ?? OhsTabKeyTypes.Organisation;
  const [filterPermissions, setFilterPermissions] = useState(permissions);

  const managePermissions = useCallback(
    (userTier: any, tabName: any) => {
      let visibleFilterPermissions = {};
      if (userTier === 3) {
        if (tabName === OhsTabKeyTypes.Organisation) {
          visibleFilterPermissions = {
            [conditions.sortBy]: true,
            [conditions.categories]: true,
            [conditions.subCategories]: true,
          };
        }
        if (tabName === OhsTabKeyTypes.Workplace) {
          visibleFilterPermissions = {
            [conditions.sortBy]: true,
            [conditions.categories]: true,
            [conditions.subCategories]: true,
            [conditions.workplaceFilter]: true,
            [conditions.workplaceOwned]: true,
            [conditions.allocatedToWorkplace]: true,
          };
        }
        if (tabName === OhsTabKeyTypes.List) {
          visibleFilterPermissions = {
            [conditions.sortBy]: true,
            [conditions.categories]: true,
            [conditions.subCategories]: true,
            [conditions.archived]: false,
            [conditions.allocatedToWorkplace]: true,
            [conditions.allocatedToOrg]: true,
            [conditions.notAllocated]: true,
          };
        }
      }
      if (userTier === 4) {
        visibleFilterPermissions = {
          [conditions.sortBy]: true,
          [conditions.categories]: true,
          [conditions.subCategories]: true,
          [conditions.archived]: false,
          [conditions.allocatedToWorkplace]: true,
          [conditions.workplaceOwned]: true,
        };
      }

      setFilterPermissions({ ...permissions, ...visibleFilterPermissions });
    },
    [userTierNum, tabKey]
  );

  useEffect(() => {
    managePermissions(userTierNum, tabKey);
  }, [userTierNum, tabKey]);

  const FilterWrapper = ({ children }: any) => {
    const memoizedWrapper = useMemo(
      () => (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          {children}
        </Grid>
      ),
      [children]
    );

    return memoizedWrapper;
  };

  return (
    <Box className={classes.filterContent}>
      {filterPermissions.sortBy && (
        <FilterWrapper>
          <OhsSelectField
            title="Sort By"
            id="reviewPlanModule.sort"
            required
            options={filter.reviewPlanModule.tableSortKeyLists?.sortColAscDescOptions}
            ohsFormSettings={props.ohsFormSettings}
          />
        </FilterWrapper>
      )}

      {filterPermissions.workplaceFilter && (
        <FilterWrapper>
          <OhsWorkplaceFilter ohsFormSettings={props.ohsFormSettings} required={false} />
        </FilterWrapper>
      )}

      {filterPermissions.categories && (
        <FilterWrapper>
          <OhsCategoriesMultSelectFilter
            ohsFormSettings={props.ohsFormSettings}
            id="reviewPlanModule.categories"
            options={getModuleReviewPlanLists}
          />
        </FilterWrapper>
      )}
      {filterPermissions.subCategories && (
        <Grid item md={12} xs={12} className={classes.fieldWrap}>
          <OhsSubcategoriesMultiSelectFilter
            ohsFormSettings={props.ohsFormSettings}
            id="reviewPlanModule.subcategories"
            categoriesList={getModuleReviewPlanLists}
          />
        </Grid>
      )}

      {filterPermissions.archived && (
        <FilterWrapper>
          <OhsCheckboxFilter
            fieldid="reviewPlanModule.archived"
            label="Archived"
            ohsFormSettings={props.ohsFormSettings}
          />
        </FilterWrapper>
      )}

      {filterPermissions.allocatedToOrg && (
        <FilterWrapper>
          <OhsCheckboxFilter
            fieldid="reviewPlanModule.allocatedToOrg"
            label="Allocated to Organisation"
            ohsFormSettings={props.ohsFormSettings}
          />
        </FilterWrapper>
      )}

      {filterPermissions.notAllocated && (
        <FilterWrapper>
          <OhsCheckboxFilter
            fieldid="reviewPlanModule.notAllocated"
            label="Not Allocated"
            ohsFormSettings={props.ohsFormSettings}
          />
        </FilterWrapper>
      )}

      {filterPermissions.workplaceOwned && (
        <FilterWrapper>
          <OhsCheckboxFilter
            fieldid="reviewPlanModule.workplaceOwned"
            label="Workplace Owned"
            ohsFormSettings={props.ohsFormSettings}
          />
        </FilterWrapper>
      )}

      {filterPermissions.allocatedToWorkplace && (
        <FilterWrapper>
          <OhsCheckboxFilter
            fieldid="reviewPlanModule.allocatedToWorkplace"
            label="Allocated to Workplace"
            ohsFormSettings={props.ohsFormSettings}
          />
        </FilterWrapper>
      )}
    </Box>
  );
}

export default OhsReviewPlanFilters;
