export default class OhsIncidentFilterModels {
  view1IncidentList: {
    viewPreset: 'view_1';
    categories?: string[];
    archived?: boolean;
    complete?: boolean;
    severities?: string[];
    skipOrgRecords: boolean;
    confidential?: boolean;
  };

  view2IncidentList: {
    viewPreset: 'view_2';
    categories?: string[];
    workplaces?: string[];
    archived?: boolean;
    workplaceOwned?: boolean;
    complete?: boolean;
    severities?: string[];
    confidential?: boolean;
  };

  view3IncidentList: {
    viewPreset: 'view_3';
    categories?: string[];
    archived?: boolean;
    workplaceOwned?: boolean;
    complete?: boolean;
    severities?: string[];
  };

  sort: string;

  tableSortKeyLists: {
    sortColAscDescOptions: { name: string; value: string }[];
  };

  constructor() {
    this.view1IncidentList = {
      viewPreset: 'view_1',
      categories: [],
      archived: false,
      severities: [],
      skipOrgRecords: false,
      confidential: true,
    };
    this.view2IncidentList = {
      viewPreset: 'view_2',
      categories: [],
      workplaces: [],
      archived: false,
      workplaceOwned: true,
      severities: [],
      confidential: true,
    };
    this.view3IncidentList = {
      viewPreset: 'view_3',
      categories: [],
      archived: false,
      workplaceOwned: true,
      severities: [],
    };

    this.tableSortKeyLists = {
      sortColAscDescOptions: [
        { name: 'Created Order: Newest to Oldest', value: '{ "key": "_id", "order": -1 }' },
        { name: 'Created Order: Oldest to Newest', value: '{ "key": "_id", "order": 1 }' },

        { name: 'Ref No.: High to Low ', value: '{ "key": "reference", "order": -1 }' },
        { name: 'Ref No.: Low to High', value: '{ "key": "reference", "order": 1 }' },

        {
          name: 'Date Identified: Newest to Oldest',
          value: '{ "key": "dateOccurred", "order": -1 }',
        },
        {
          name: 'Date Identified: Oldest to Newest',
          value: '{ "key": "dateOccurred", "order": 1 }',
        },

        { name: 'Date Due: Newest to Oldest', value: '{ "key": "dateDue", "order": -1 }' },
        { name: 'Date Due: Oldest to Newest', value: '{ "key": "dateDue", "order": 1 }' },
        {
          name: 'Category: A-Z',
          value: '{ "key": "category", "order": 1 }',
        },
        {
          name: 'Category: Z-A',
          value: '{ "key": "category", "order": -1 }',
        },
      ],
    };
    this.sort = '{ "key": "_id", "order": -1 }';
  }
}
