import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'app/store';
import { ApiResListsType, OhsApiRequestName } from 'global-services/api/OhsApiModels';
import OhsDocumentsListsRecord, {
  OhsDocumentsFilterPayload,
} from 'documents/models/OhsDocumentsRecord';
import handleRegisterAsyncData, {
  handleRegisterCountAsyncData,
} from 'global-components/register/OhsModuleRegisterUtils';
import OhsUserLocalServices from 'user/OhsUserLocalServices';
import { OhsUser } from 'user/OhsUser';

import getDocumentsLists, {
  getCopiedDocumentsLists,
  getDocumentsAllocatedList,
} from './OhsDocumentsServices';
import parseListAllocations from './utils/OhsParseListAllocations';
import OhsDocumentsFilterModels from '../dashboard/topFilter/OhsDocumentsFilterModels';
import OhsCorrespondenceListsRecord from '../correspondence/models/OhsCorrespondenceRecord';
import globalModuleSearch from '../search/OhsSearchServices';
import { TierType } from '../global-services/constants/OhsObject';
import { listActiveTaskFor } from '../task/OhsTaskServices';

export interface DocumentsListsRecordState {
  isLoading: boolean;
  documentsList: ApiResListsType<OhsDocumentsListsRecord[]> | null;
  currentPage: number;
}

const initialState: DocumentsListsRecordState = {
  isLoading: false,
  documentsList: null,
  currentPage: 1,
};

export const getViewPreset = (tierNum: number) => {
  switch (tierNum) {
    case 2:
      return 'view_1';
    case 3:
      return 'view_2';
    case 4:
    case 5:
      return 'view_4';
    default:
      return undefined;
  }
};

const fetchAllocations = async (documentsList: any): Promise<ApiResListsType<any[]> | null> => {
  if (documentsList && documentsList?.items.length === 0) {
    return new Promise((resolve) => {
      resolve(documentsList);
    });
  }

  // get allocated records from documents _ids
  const getIDs: string[] = [];
  documentsList?.items.forEach((item: OhsDocumentsListsRecord) => {
    if (item._id && item.tier?.type === TierType.T3) getIDs.push(item._id);
  });
  if (getIDs && getIDs.length === 0) {
    return new Promise((resolve) => {
      resolve(documentsList);
    });
  }
  const allocatedList = await getDocumentsAllocatedList(getIDs);

  // map allocated records to safetylist
  const documentsListWithAllocations = parseListAllocations(
    documentsList,
    allocatedList
  ) as ApiResListsType<OhsDocumentsListsRecord[]> | null;

  return new Promise((resolve) => {
    resolve(documentsListWithAllocations);
  });
};

const documentSearch = async (
  searchDetails: any,
  tierNum: number
): Promise<ApiResListsType<OhsCorrespondenceListsRecord[]> | null> => {
  let result: any;
  const globalSearchRes: any = await globalModuleSearch(
    searchDetails,
    tierNum === 5 ? OhsApiRequestName.WorkerGroupList : OhsApiRequestName.List
  );

  result = globalSearchRes.result;

  if (tierNum === 3) {
    result = await fetchAllocations(result);
  }

  if (result && (tierNum === 3 || tierNum === 4)) {
    const taskList = await listActiveTaskFor(result.items.map((item: any) => item._id));
    result.items.forEach((item: any) => {
      item.review = taskList.find((t) => t._id === item._id);
    });
  }

  return result;
};

const getSearchFilter = (state: RootState): any => {
  const searchFilter: any = state.globalSearch.searchInfo;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const { filter } = searchFilter;
  return {
    ...searchFilter,
    filter:
      user?.tier.type === TierType.T5
        ? {
            modules: filter.modules,
            count: filter.count,
            page: filter.page,
            archived: filter.archived,
          }
        : {
            ...filter,
            skipAllocatedRecords: true,
          },
  };
};

const documentsFilterOptions = (
  state: RootState,
  filterKey: keyof OhsDocumentsFilterModels
): Partial<OhsDocumentsFilterPayload> => {
  const { globalfilter } = state;
  const filter = globalfilter.filterInfo;
  let obj: any = {
    archived: filter.documentsModule[filterKey].archived,
  };
  if (filterKey === 'documents') {
    obj = {
      ...obj,
      categories: filter.documentsModule[filterKey].categories,
      subcategories: filter.documentsModule[filterKey].subcategories,
      reviewScheduled: filter.documentsModule[filterKey].reviewScheduled,
      allocatedToWorkplace: filter.documentsModule[filterKey].allocatedToWorkplace,
      notAllocated: filter.documentsModule[filterKey].notAllocated,
      workplaceOwned: filter.documentsModule[filterKey].workplaceOwned,
    };
  } else if (filterKey === 'template') {
    obj = {
      ...obj,
      copied: filter.documentsModule[filterKey].copied,
      copiedVersionUpdated: filter.documentsModule[filterKey].copiedVersionUpdated,
      notCopied: filter.documentsModule[filterKey].notCopied,
    };
  } else {
    obj = {
      ...obj,
      categories: filter.documentsModule[filterKey].categories,
      subcategories: filter.documentsModule[filterKey].subcategories,
      workplaces: filter.workplaces,
    };
  }
  return obj as Partial<OhsDocumentsFilterPayload>;
};

const getDocumentsWithAllocations = (
  filterInfo: OhsDocumentsFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  const getDocumentsListReq = async (): Promise<ApiResListsType<any[]> | null> => {
    const documentsList = await getDocumentsLists(filterInfo);

    // exit if list result is empty
    const res = fetchAllocations(documentsList);
    return res;
  };

  return getDocumentsListReq();
};

const documentsListsRPC = async (
  user: OhsUser,
  filters: OhsDocumentsFilterPayload
): Promise<any> => {
  const userTier = user?.tierNum ?? 0;

  let response: any = null;
  if (userTier && userTier === 2 && user?.configs.vdoc?.PERMISSIONS.view) {
    response = await getDocumentsLists(filters);
  }
  if (userTier && userTier === 3 && user?.configs.vdoc?.PERMISSIONS.view) {
    response = await getDocumentsWithAllocations(filters);
  }
  if (userTier && (userTier === 4 || userTier === 5) && user?.configs.vdoc?.PERMISSIONS.view) {
    response = await getDocumentsLists(filters);
  }

  if (response && (userTier === 3 || userTier === 4)) {
    const taskList = await listActiveTaskFor(response.items.map((item: any) => item._id));
    response.items.forEach((item: any) => {
      item.review = taskList.find((t) => t._id === item._id);
    });
  }

  return response;
};
const documentsCopyListsRPC = async (
  user: OhsUser,
  filters: OhsDocumentsFilterPayload
): Promise<any> => {
  if (user?.configs.vdoc?.PERMISSIONS.view) {
    const response = await getCopiedDocumentsLists(filters);
    return response;
  }
  return null;
};
export const fetchDocumentsListsAsync = createAsyncThunk<
  ApiResListsType<OhsDocumentsListsRecord[]> | null,
  {
    preset: 'view_1' | 'view_2' | 'view_3' | 'view_4';
    isTemplate: boolean;
    filterKey: keyof OhsDocumentsFilterModels;
  },
  { state: RootState }
>('documents/fetchDocumentsList', async ({ preset, isTemplate, filterKey }, thunkAPI) => {
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const userTier = user?.tierNum ?? 0;
  const state = thunkAPI.getState();
  const filter = thunkAPI.getState().globalfilter.filterInfo;
  const hasGlobalSearch = state.globalSearch.searchInfo.searchKey !== '';

  const documentFilters: OhsDocumentsFilterPayload = {
    ...documentsFilterOptions(state, filterKey),
    viewPreset: preset,
    page: state.documents.currentPage > 10 ? 0 : state.documents.currentPage,
    sort: { ...JSON.parse(String(filter.documentsModule[filterKey].sort)) },
    count: false,
  };

  const searchFilters = getSearchFilter(state);

  try {
    // eslint-disable-next-line no-nested-ternary
    const response = hasGlobalSearch
      ? await documentSearch(
          {
            ...searchFilters,
            filter: {
              ...searchFilters.filter,
              archived: documentFilters.archived,
            },
          },
          userTier
        )
      : isTemplate
      ? await documentsCopyListsRPC(user, documentFilters)
      : await documentsListsRPC(user, documentFilters);

    return response as ApiResListsType<OhsDocumentsListsRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchDocumentsListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsDocumentsListsRecord[]> | null,
  {
    preset: 'view_1' | 'view_2' | 'view_3' | 'view_4';
    isTemplate: boolean;
    filterKey: keyof OhsDocumentsFilterModels;
  },
  { state: RootState }
>('documents/fetchDocumentsCountList', async ({ preset, isTemplate, filterKey }, thunkAPI) => {
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const userTier = user?.tierNum ?? 0;
  const filter = thunkAPI.getState().globalfilter.filterInfo;
  const state = thunkAPI.getState();
  const hasGlobalSearch = state.globalSearch.searchInfo.searchKey !== '';

  const documentsFiltersWithCount: OhsDocumentsFilterPayload = {
    ...documentsFilterOptions(state, filterKey),
    viewPreset: preset,
    count: true,
    page: 1,
    sort: { ...JSON.parse(String(filter.documentsModule[filterKey].sort)) },
  };
  const searchFilters = getSearchFilter(state);

  try {
    // eslint-disable-next-line no-nested-ternary
    const response = hasGlobalSearch
      ? await documentSearch(
          {
            ...searchFilters,
            filter: {
              ...searchFilters.filter,
              archived: documentsFiltersWithCount.archived,
            },
          },
          userTier
        )
      : isTemplate
      ? await getCopiedDocumentsLists(documentsFiltersWithCount)
      : await getDocumentsLists(documentsFiltersWithCount);
    return response as ApiResListsType<OhsDocumentsListsRecord[]> | null;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  reducers: {
    setDocumentsCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocumentsListsAsync.pending, (state) => {
        state.isLoading = true;
        state.documentsList = {
          items: [],
          pagination: state.documentsList?.pagination ?? {
            page: 0,
            totalPages: 0,
            next: '',
          },
        };
      })
      .addCase(fetchDocumentsListsAsync.fulfilled, (state, action) => {
        if (action.payload) {
          const documentsList = handleRegisterAsyncData(action.payload, 'documentsListtotalPages');
          state.isLoading = false;
          state.documentsList = documentsList;
          state.currentPage = documentsList.pagination.page ?? 1;
        }
      })
      .addCase(fetchDocumentsListsAsync.rejected, (state) => {
        state.documentsList = null;
      })
      .addCase(fetchDocumentsListsCountAsync.fulfilled, (state, action) => {
        const documentsCountResult: any = action.payload;

        if (documentsCountResult) {
          const countDocumentsLists = handleRegisterCountAsyncData(
            documentsCountResult,
            state.documentsList,
            'documentsListtotalPages'
          );
          state.documentsList = countDocumentsLists;
        }
      });
  },
});

const documentsState = (state: RootState) => state.documents;
// Memoized Selectors
export const getOhsDocumentsModuleState = createSelector(
  [documentsState],
  (documents) => documents
);
export const { setDocumentsCurrentPage } = documentsSlice.actions;
export const documentsModuleReducer = documentsSlice.reducer;
