import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';
import { MultipleOptionListTypes } from 'global-components/form/fields/OhsMultipleSelectField';

import { PersonResponsibleLookup } from './models/OhsFormModals';

export interface OhsActionLink {
  type: ModuleType.Action;
  _id: string;
  reference?: string;
}

export interface OhsActionTaskActiveList {
  type: string;
  _id: string;
  task: {
    type: string;
    _id: string;
  };
}

export class OhsActionsRecord {
  _id: string;

  tier?: OhsTier | null;

  archived?: boolean;

  overview: string;

  description: string;

  personReporting: string;

  personResponsible: string;

  personResponsibleOther: string;

  personResponsibleLinks: PersonResponsibleLookup[] | '';

  personResponsibleEmail: string;

  attachments: OhsAttachment[];

  cusvals: any;

  categoryCusvals: any;

  category: string;

  categoryOther: string | null;

  dateIdentified: string;

  dateDue: string;

  tzDateCreated: string;

  dateCreated: string;

  reference: string;

  taskId: string;

  taskActive: boolean;

  complete?: boolean;

  comment?: {
    comment: string;
    date: string;
    by: {
      type: string;
      name: string;
      email: string;
      _id: string;
    };
  };

  createdBy: {
    type: ModuleType.User;
    _id: string;
    name: string;
    email: string;
  };

  editComments?: {
    comment: string;
    date: string;
    by: {
      email: string;
      name: string;
      type: string;
      _id: string;
    };
  }[];

  type?: string;

  constructor(tier?: OhsTier) {
    this._id = '';
    this.tier = tier;
    this.overview = '';
    this.description = '';
    this.personReporting = '';
    this.personResponsible = '';
    this.personResponsibleOther = '';
    this.personResponsibleLinks = [];
    this.personResponsibleEmail = '';
    this.attachments = [];
    this.cusvals = null;
    this.categoryCusvals = null;
    this.category = '';
    this.categoryOther = null;
    this.dateIdentified = '';
    this.dateDue = '';
    this.tzDateCreated = '';
    this.dateCreated = '';
    this.reference = '';
    this.taskId = '';
    this.taskActive = false;
    this.tier = tier;
    this.createdBy = {
      type: ModuleType.User,
      _id: '',
      name: '',
      email: '',
    };
    this.type = '';
  }
}

export interface OhsActionCustomFilters {
  archived: boolean;
  categories?: string[];
  workplaces?: string[];
  next?: string;
}

export interface OhsActionFilterPayload extends OhsActionCustomFilters {
  skipOrgRecords?: boolean;
  severities?: string[];
  viewPreset?: 'view_1' | 'view_2';
  page: number;
  count: boolean;
  sort: {
    order: number;
    key: string;
  };
}

export interface OhsActionSearchFilter {
  page: number;
  count: boolean;
  modules: string[];
  skipOrgRecords: boolean;
  workplaces: (string | null)[];
  workplaceOptions?: Array<MultipleOptionListTypes> | null;
  task: {
    complete: boolean;
    includeMasterRecords: boolean;
  };
  skipAllocatedRecords: boolean;
}

export interface OhsActionSearchMain {
  searchKey: string;
  substringSearch: boolean;
  highlight: boolean;
  filter: OhsActionSearchFilter;
}
