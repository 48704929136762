import React, { useEffect } from 'react';

import {
  fetchAvailableInspectionListAsync,
  setCurrentRegisterPage,
  getOhsInspectionList,
  fetchAvailableInspectionListCountAsync,
  getAvailableInspectionSearch,
} from 'inspection/OhsInspectionSlice';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getGlobalFilter } from 'dashboard/topFilter/OhsTopFilterSlice';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

export default function useOhsInspectionListAvailable() {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const filter = useAppSelector(getGlobalFilter);
  const dispatch = useAppDispatch();
  const inspectionReducer = useAppSelector(getOhsInspectionList);
  const searchInfo = useAppSelector(getAvailableInspectionSearch);

  const { availableInspectionList, isLoading } = inspectionReducer;
  const user = OhsUserLocalServices.getLocalOhsUser();

  const fetchAvailableInspectionList = async () => {
    if (user) {
      await dispatch(fetchAvailableInspectionListAsync());
      await dispatch(fetchAvailableInspectionListCountAsync());
    }
  };

  useEffect(() => {
    dispatch(setCurrentRegisterPage(currentPage));
    fetchAvailableInspectionList();
  }, [user?._id, currentPage, filter, searchInfo]);

  return [
    isLoading,
    availableInspectionList,
    currentPage,
    setCurrentPage,
    fetchAvailableInspectionList,
  ] as const;
}
