import React, { useEffect, useState } from 'react';

import { makeStyles } from 'tss-react/mui';
import { Grid } from '@mui/material';
import _ from 'lodash';

import { OhsFormSettings } from 'global-components/form/services/OhsFormModels';
import {
  OhsCategoryRecordField,
  OhsSubcategoryRecordField,
} from 'global-services/constants/record-field/OhsGlobalRecordFiled';

import { OhsExcelConfig } from './OhsExcelExportModels';
import OhsDateField from '../form/fields/OhsDateField';
import OhsSelectField from '../form/fields/OhsSelectField';
import OhsTextField from '../form/fields/OhsTextField';
import OhsCusvalSelectField from '../form/cusvals/select/OhsCusvalSelectField';

interface Props {
  configList: Array<OhsExcelConfig>;
  ohsFormSettings: OhsFormSettings;
  recordList: Array<any>;
}

const useStyles = makeStyles()(() => ({
  modalFooter: {
    width: '100%',
    display: 'flex',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  marginLeft: {
    marginLeft: 'auto !important',
  },
}));

const CategoryKey = `${OhsCategoryRecordField.key}-multiple`;
const SubcategoryKey = `${OhsSubcategoryRecordField.key}-multiple`;

const handleOptions = (item: string | boolean) => {
  const yesOption = { name: 'Yes', value: true };
  const noOption = { name: 'No', value: false };

  if (typeof item === 'string') {
    if (item.toLocaleLowerCase() === 'true') return yesOption;
    if (item.toLocaleLowerCase() === 'false') return noOption;
  } else if (typeof item === 'boolean') {
    if (item) return yesOption;
    if (!item) return noOption;
  }
  return { name: item, value: item };
};

function OhsExcelExportModalFilter(props: Props) {
  const [initialized, setInitialized] = useState({});
  const { classes } = useStyles();

  const [category, subcategory]: [string[], string[]] = props.ohsFormSettings.useFormMethods.watch([
    CategoryKey,
    SubcategoryKey,
  ]);

  const formatOptions = (optionList: string[]) => {
    return optionList.map((option: string) => ({
      name: option,
      value: option,
    }));
  };
  const returnSubcategoryByCategories = () => {
    const categoryList = new Array<any>();
    const recordListBySelectedCategory = props.recordList.filter((record) =>
      (category || []).includes(record[OhsCategoryRecordField.key])
    );

    recordListBySelectedCategory.forEach((record) => {
      // if category exist, edit, if not, create
      if (record[OhsCategoryRecordField.key]) {
        const categoryIndex = categoryList.findIndex(
          // eslint-disable-next-line @typescript-eslint/no-shadow
          (category: any) =>
            category[OhsCategoryRecordField.key] === record[OhsCategoryRecordField.key]
        );
        const existingCategory = categoryIndex >= 0;
        // in category, populate subcategory options
        if (existingCategory) {
          const targetCategory = categoryList[categoryIndex];
          const subcategoryIndex = targetCategory[OhsSubcategoryRecordField.key].findIndex(
            // eslint-disable-next-line @typescript-eslint/no-shadow
            (subcategory: string) => subcategory === record[OhsSubcategoryRecordField.key]
          );
          const existingSubcategory = subcategoryIndex >= 0;
          if (!existingSubcategory) {
            categoryList[categoryIndex][OhsSubcategoryRecordField.key].push(
              record[OhsSubcategoryRecordField.key]
            );
          }
        } else {
          categoryList.push({
            [OhsCategoryRecordField.key]: record[OhsCategoryRecordField.key],
            subcategory: record[OhsSubcategoryRecordField.key]
              ? [record[OhsSubcategoryRecordField.key]]
              : [],
          });
        }
      }
    });

    let optionList = new Array<string>();
    recordListBySelectedCategory.map((item) =>
      optionList.push(item[OhsSubcategoryRecordField.key])
    );
    optionList = _.uniq(optionList);
    return optionList;
  };

  const getFilterField = (config: OhsExcelConfig) => {
    switch (config.filter?.type) {
      case 'multiple': {
        const id = `${config.fn}-${config.filter.type}`;
        const options =
          config.fn === OhsSubcategoryRecordField.key
            ? formatOptions(returnSubcategoryByCategories())
            : config.filter.options?.map((item) => {
                return handleOptions(item);
              }) ?? [];

        if (options.length === 1 && options[0].name) {
          const isInitialized = _.get(initialized, id) || false;
          if (!isInitialized) {
            props.ohsFormSettings.useFormMethods.setValue(id, [options[0].name]);
            setInitialized({
              ...initialized,
              [id]: true,
            });
          }
        }
        return (
          <OhsCusvalSelectField
            id={id}
            required={false}
            ohsFormSettings={props.ohsFormSettings}
            options={options}
            multi
            noLabel
          />
        );
      }
      case 'dateRange':
        return (
          <Grid container columnSpacing={2}>
            <Grid xs={6} item>
              <OhsDateField
                title="From"
                id={`${config.fn}-${config.filter.type}-from`}
                required={false}
                ohsFormSettings={props.ohsFormSettings}
              />
            </Grid>
            <Grid item xs={6}>
              <OhsDateField
                title="To"
                id={`${config.fn}-${config.filter.type}-to`}
                required={false}
                ohsFormSettings={props.ohsFormSettings}
              />
            </Grid>
          </Grid>
        );
      case 'amount':
        return (
          <Grid container spacing={2}>
            <Grid xs={12} item>
              <OhsSelectField
                title="Currency"
                id={`${config.fn}-${config.filter.type}-currency`}
                required={false}
                options={
                  config.filter.options?.map((item) => {
                    return { name: item, value: item };
                  }) ?? []
                }
                ohsFormSettings={props.ohsFormSettings}
              />
            </Grid>
            <Grid item xs={6}>
              <OhsTextField
                type="number"
                title="Min"
                id={`${config.fn}-${config.filter.type}-min`}
                maxAmount={config.filter.maxAmount}
                minAmount={config.filter.minAmount}
                required={false}
                ohsFormSettings={props.ohsFormSettings}
              />
            </Grid>
            <Grid item xs={6}>
              <OhsTextField
                type="number"
                title="Max"
                id={`${config.fn}-${config.filter.type}-max`}
                maxAmount={config.filter.maxAmount}
                minAmount={config.filter.minAmount}
                required={false}
                ohsFormSettings={props.ohsFormSettings}
              />
            </Grid>
          </Grid>
        );
      default:
        break;
    }
    return null;
  };

  useEffect(() => {
    if ((subcategory || []).length > 0) {
      const validOptions = returnSubcategoryByCategories();
      const newSubcategoryValues: string[] = [];

      (subcategory || []).map((item: string) => {
        if (validOptions.includes(item)) {
          newSubcategoryValues.push(item);
        }
        return item;
      });

      props.ohsFormSettings.useFormMethods.setValue(SubcategoryKey, newSubcategoryValues);
    }
  }, [category]);

  useEffect(() => {
    setInitialized({});
  }, [props.configList]);

  return (
    <table className="ui striped selectable table primary" style={{ tableLayout: 'fixed' }}>
      <tbody>
        {props.configList.map((config) =>
          config.filter ? (
            <tr key={config.fn}>
              <td width="200px">
                <div className={classes.labelContainer}>{config.fd}</div>
              </td>
              <td>{getFilterField(config)}</td>
            </tr>
          ) : null
        )}
      </tbody>
    </table>
  );
}

export default OhsExcelExportModalFilter;
