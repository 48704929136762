// eslint-disable-next-line max-classes-per-file
import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { OhsFilterList } from 'dashboard/topFilter/OhsFilterModels';
import { GlobalSearchState } from 'search/OhsSearchModels';
import { ModuleType, OhsObject } from 'global-services/constants/OhsObject';
import { getCurrentTimezone } from 'global-services/OhsDataParse';
import OhsCusvalDefinition from 'global-components/form/cusvals/OhsCusvalDefinition';

export interface OhsNoticeBoardCustomFilters {
  archived?: boolean;
  workplaces?: string[];
  next?: string;
}
export interface OhsNoticeBoardListsFilterPayload extends OhsNoticeBoardCustomFilters {
  viewPreset: string;
  page: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
}
export interface OhsNoticeBoardRootState {
  globalfilter: {
    filterInfo: OhsFilterList;
  };
  noticeBoard: NoticeBoardListsRecordState;
  globalSearch: GlobalSearchState;
}
export enum OhsNoticeBoardViewPresets {
  View1NoticeBoardList = 'view1NoticeBoardList',
  View2NoticeBoardList = 'view2NoticeBoardList',
}

export interface NoticeBoardListsRecordState {
  isLoading: boolean;
  noticeBoardList: ApiResListsType<any[]> | null;
  noticeBoardFormList: ApiResListsType<any[]> | null;
  noticeBoardFullList: any | null;
  currentPage: number;
  currentViewPreset: OhsNoticeBoardViewPresets;
}

export interface NoticeboardAllocListRes {
  items: AllocatedListType[];
}
export interface AllocatedListType {
  _id: string;
  allocated: boolean;
}

export class OhsNoticeBoard {
  _id?: string;

  title: string;

  category: string | null;

  categoryOther: string | null;

  subcategory: string | null;

  subcategoryOther: string | null;

  description: string | null;

  tzDateCreated: string;

  constructor() {
    this._id = '';
    this.title = '';
    this.category = null;
    this.categoryOther = null;
    this.subcategory = null;
    this.subcategoryOther = null;
    this.description = null;
    this.tzDateCreated = '';
  }
}

export enum OhsFileType {
  OhsAttachmentImg = 'Image',
  OhsAttachmentPdf = 'PDF',
  OhsAttachmentWord = 'Word',
  OhsAttachmentPpt = 'Power Point',
  OhsAttachmentExcel = 'Excel',
  OhsAttachmentFile = 'File',
}

export const ImageFileExtensionList = ['png', 'gif', 'jpg', 'jpeg', 'webp', 'svg', 'bmp'];

const PdfFileExtensionList = ['pdf'];
const WordFileExtensionList = ['doc', 'docx', 'dotx'];
const PowerPointFileExtensionList = ['ppt', 'pptx', 'ppsx', 'pps', 'potx', 'ppsm'];
const ExcelFileExtensionList = ['xls', 'xlsx', 'xlsb', 'xlsm'];

export const getFileTypebyFileName = (fileName?: string): OhsFileType => {
  if (fileName && fileName.indexOf('.') > 0) {
    const fileExtension = fileName.split('.').pop() ?? '';

    if (ImageFileExtensionList.includes(fileExtension.toLocaleLowerCase())) {
      return OhsFileType.OhsAttachmentImg;
    }
    if (PdfFileExtensionList.includes(fileExtension.toLocaleLowerCase())) {
      return OhsFileType.OhsAttachmentPdf;
    }
    if (WordFileExtensionList.includes(fileExtension.toLocaleLowerCase())) {
      return OhsFileType.OhsAttachmentWord;
    }
    if (PowerPointFileExtensionList.includes(fileExtension.toLocaleLowerCase())) {
      return OhsFileType.OhsAttachmentPpt;
    }
    if (ExcelFileExtensionList.includes(fileExtension.toLocaleLowerCase())) {
      return OhsFileType.OhsAttachmentExcel;
    }
  }

  return OhsFileType.OhsAttachmentFile;
};

export enum OhsNoticeboardBlockTypeDisplay {
  OhsRichText = 'Rich Text',
  OhsDocument = 'Document',
  OhsAttachmentImg = 'Image',
  OhsAttachmentPdf = 'PDF',
  OhsAttachmentWord = 'Word',
  OhsAttachmentPpt = 'Power Point',
  OhsAttachmentExcel = 'Excel',
  OhsAttachmentFile = 'File',
  OhsYoutube = 'Youtube',
  OhsLink = 'Link',
}

export enum OhsNoticeboardBlockType {
  OhsRichText = 'text/html',
  OhsDocument = 'application/vnd.safetychampion.core_module_vdoc',
  OhsAttachment = 'application/vnd.safetychampion.attachment',
  OhsYoutube = 'video/x-youtube',
  OhsLink = 'application/vnd.safetychampion.externalLinks',
}

export const setBlockDisplayType = (
  blockType: OhsNoticeboardBlockType,
  attachmentFileName?: string
) => {
  switch (blockType) {
    case OhsNoticeboardBlockType.OhsLink:
      return OhsNoticeboardBlockTypeDisplay.OhsLink;
    case OhsNoticeboardBlockType.OhsRichText:
      return OhsNoticeboardBlockTypeDisplay.OhsRichText;
    case OhsNoticeboardBlockType.OhsYoutube:
      return OhsNoticeboardBlockTypeDisplay.OhsYoutube;
    case OhsNoticeboardBlockType.OhsDocument:
      return OhsNoticeboardBlockTypeDisplay.OhsDocument;
    case OhsNoticeboardBlockType.OhsAttachment:
      return getFileTypebyFileName(attachmentFileName);

    default:
      return blockType;
  }
};

export interface OhsNoticeboardContact {
  phones: string[];
  emails: string[];
  role: string | null;
  roleOther: string | null;
  notes: string;
  name: string;
}

export interface NoticeBoardFormArchiveResponce {
  success: true;
  result: {};
}
export interface NoticeboardFormYoububeMessage {
  type: NoticeboardFormMessageType.Youtube;
  value: string;
}

export interface NoticeboardFormRichTextMessage {
  type: NoticeboardFormMessageType.RichText;
  value: any;
}

export enum NoticeboardFormMessageType {
  Youtube = 'video/x-youtube',
  RichText = 'text/html',
}

export class NoticeboardFormMessages {
  pre: NoticeboardFormYoububeMessage | NoticeboardFormRichTextMessage | null;

  in: NoticeboardFormYoububeMessage | NoticeboardFormRichTextMessage | null;

  post: NoticeboardFormYoububeMessage | NoticeboardFormRichTextMessage | null;

  constructor() {
    this.pre = null;
    this.in = null;
    this.post = null;
  }
}

export class OhsNoticeboardFormRecord {
  _id?: string;

  type: ModuleType.NoticeBoardForm;

  cusvals: OhsCusvalDefinition | null;

  title: string;

  formTitle?: string;

  description: string | null;

  messages: NoticeboardFormMessages;

  tzDateCreated: string;

  archived?: boolean;

  tier?: OhsObject;

  constructor() {
    this._id = '';
    this.type = ModuleType.NoticeBoardForm;
    this.cusvals = new OhsCusvalDefinition();
    this.title = '';
    this.description = null;
    this.messages = { pre: null, in: null, post: null };
    this.tzDateCreated = getCurrentTimezone();
  }
}
