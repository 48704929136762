import React from 'react';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';

import OhsContractorRecord from 'contractor/models/OhsContractorRecord';
import { parseObjectDisplayValue, parseOtherDisplayValue } from 'global-services/OhsDataParse';
import { OhsMainLocationRecordField } from 'global-services/constants/record-field/OhsGlobalRecordFiled';

import OhsCircularLabel from '../OhsCircularLabel';

const useStyles = makeStyles()((theme) => ({
  customPaper: {
    padding: theme.spacing(1),
    border: '2px solid #ccc',
    width: '500px',
    maxHeight: '400px',
    overflowY: 'auto',
  },
  customTooltip: {
    background: 'transparent',
  },
  listItemText: {
    display: 'flex',
    alignItems: 'start',
  },
}));

interface Props {
  arrayData: any;
  arrayDefine?: { key: string; name: string }[];
  color?: string;
}

export default function OhsArrayRowDisplay(props: Props) {
  const { classes } = useStyles();
  if (
    props.arrayData == null ||
    props.arrayData.length === undefined ||
    props.arrayData.length === 0
  ) {
    return <OhsCircularLabel value={0} color={props.color ?? 'nocolor'} />;
  }
  if (
    props.arrayDefine == null ||
    props.arrayDefine.length === undefined ||
    props.arrayDefine.length === 0
  ) {
    return <OhsCircularLabel value={props.arrayData.length} color={props.color ?? 'nocolor'} />;
  }

  return (
    <Tooltip
      title={
        <Paper elevation={0} className={classes.customPaper} style={{ textAlign: 'left' }}>
          {props.arrayData.map((dataItem: any, dataIndex: React.Key | null | undefined) => {
            // Filter items with non-empty display values
            const itemsToDisplay =
              props.arrayDefine?.filter((defineItem) => {
                const displayValue = parseObjectDisplayValue(_.get(dataItem, defineItem.key)) ?? '';
                return displayValue.trim() !== '';
              }) ?? [];

            if (itemsToDisplay?.length === 0) {
              return null;
            }

            return (
              <List key={dataIndex} style={{ padding: '0px' }}>
                {itemsToDisplay.map((defineItem) => (
                  <ListItem key={JSON.stringify(defineItem)} style={{ padding: '0px 16px' }}>
                    <ListItemText style={{ margin: '0px' }}>
                      <div className={classes.listItemText}>
                        <Typography
                          variant="subtitle1"
                          style={{ fontWeight: 'bold', marginRight: '0.3em' }}
                        >
                          {defineItem.name}:
                        </Typography>
                        {parseObjectDisplayValue(_.get(dataItem, defineItem.key)) ?? ''}
                      </div>
                    </ListItemText>
                  </ListItem>
                ))}
                {dataIndex !== props.arrayData.length - 1 && (
                  <Divider style={{ margin: '8px 0px' }} />
                )}
              </List>
            );
          })}
        </Paper>
      }
      arrow={false}
      classes={{ tooltip: classes.customTooltip }}
    >
      <p>
        <OhsCircularLabel value={props.arrayData.length} color={props.color ?? 'nocolor'} />
      </p>
    </Tooltip>
  );
}

OhsArrayRowDisplay.defaultProps = { arrayDefine: [], color: 'nocolor' };

export const getOhsArrayRowDisplay = (
  header: string,
  accessor: string | ((record: any) => any[]),
  arrayDefine?: { key: string; name: string }[],
  color?: (record: any) => string
) => {
  return {
    Header: header,
    accessor,

    Cell: (value: any) => {
      const colorProp = color ? color(value.row.original) : undefined;

      return (
        <OhsArrayRowDisplay arrayData={value.value} arrayDefine={arrayDefine} color={colorProp} />
      );
    },
  };
};

export const parseLocationsArray = (record: OhsContractorRecord) => {
  const results = [] as any[];
  if (record && record.locations?.length > 0) {
    record.locations.forEach((item) => {
      const newItem = {} as any;
      newItem[OhsMainLocationRecordField.key] = `${parseOtherDisplayValue(
        item.main,
        item.mainOther
      )} --- ${parseOtherDisplayValue(item.sub, item.subOther)}`;
      results.push(newItem);
    });
  }

  return results;
};
