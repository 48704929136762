import React from 'react';

import { Box } from '@mui/material';

import useOhsOnboardingList from 'freemium/hooks/OhsOnboardingListHook';
import OhsRegister from 'global-components/register/OhsRegister';
import { setAssetIsLoading } from 'assets/OhsAssetSlice';
import OhsRegisterTableServerPagination from 'global-components/register/table/OhsRegisterTableServerPagination';
import OhsModuleHeader from 'global-components/register/OhsModuleHeader';
import OhsModuleHeaderButtons from 'global-components/register/OhsModuleHeaderButtons';
import { toggleTopFilterDrawer } from 'dashboard/OhsDashboardSlice';
import { useAppDispatch } from 'app/hooks';
import OhsModuleBackground from 'global-components/register/OhsModuleBackground';
import {
  OhsDateCreatedRecordField,
  OhsEmployeesRecordField,
  OhsIndustryRecordField,
  OhsLocationRecordField,
  OhsOrganisationRecordField,
} from 'global-services/constants/record-field/OhsGlobalRecordFiled';
import { setFilterCallback } from 'dashboard/topFilter/OhsTopFilterSlice';
import OhsExcelExportModal from 'global-components/excel/OhsExcelExportModal';
import {
  parseExportDataByKeys,
  setConfigFilterOption,
} from 'global-components/excel/OhsExcelExportServices';
import { OhsFreemiumConfigList } from 'global-components/excel/OhsExcelExportModels';
import OhsFreemiumExportUtils from 'freemium/utils/OhsFreemiumExportUtils';

import { setCurrentRegisterPage } from './OhsOnboardingListSlice';
import { OhsOnboardingServices } from './OhsOnboardingServices';

const userLocation = (rowInfo: any) => {
  return (
    <Box sx={{ wordBreak: 'break-all' }}>
      {rowInfo.orgTier.country}
      {rowInfo.orgTier.state ? ` - ${rowInfo.orgTier.state}` : ''}
    </Box>
  );
};

const dateCreated = (rowInfo: any) => {
  return (
    <>
      <Box sx={{ wordBreak: 'break-all' }}>{rowInfo.dateCreated}</Box>
      <Box sx={{ wordBreak: 'break-all' }}>{rowInfo.tzDateCreated}</Box>
    </>
  );
};

const dateCompleted = (rowInfo: any) => {
  return (
    <>
      <Box sx={{ wordBreak: 'break-all' }}>{rowInfo.dateCompleted}</Box>
      <Box sx={{ wordBreak: 'break-all' }}>{rowInfo.tzDateCompleted}</Box>
    </>
  );
};

const userNameField = (rowInfo: any) => {
  return (
    <>
      <Box sx={{ wordBreak: 'break-all' }}>{rowInfo.user.email}</Box>
      <Box sx={{ wordBreak: 'break-all' }}>
        {rowInfo.user.firstName} {rowInfo.user.lastName}
      </Box>
      <Box sx={{ wordBreak: 'break-all' }}>{`+${rowInfo.user.phoneCountryCode.replace(
        '+',
        ''
      )} ${rowInfo.user.phone.replace('+61', '')}`}</Box>
    </>
  );
};

function OhsOnboardingList() {
  const [freemiumExportList, setFreemiumExportList] = React.useState<any[]>([]);
  const [freemiumConfigList, setFreemiumConfigList] = React.useState<any[]>([]);

  const [excelModalOpen, setExcelModalOpen] = React.useState(false);
  const { isLoading, onboardingList, currentPage, setCurrentPage, fetchOnboardingList } =
    useOhsOnboardingList();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    fetchOnboardingList();
  }, []);

  const exportOnboardingList = async () => {
    const getAllOnboardingLists = await OhsOnboardingServices.getAllOnboardingList();

    let onboardingListForExport: any[] = getAllOnboardingLists ?? [];
    onboardingListForExport = OhsFreemiumExportUtils.exportCSVFormating(onboardingListForExport);
    onboardingListForExport = parseExportDataByKeys(onboardingListForExport, ['cusvals']);

    const props = [...OhsFreemiumConfigList];
    const configList = setConfigFilterOption(onboardingListForExport, props);

    setExcelModalOpen(true);
    setFreemiumExportList(onboardingListForExport);
    setFreemiumConfigList([...configList]);
  };

  const onExcelModalClose = () => {
    // console.log('export success');
  };

  const tableCol = React.useMemo(
    () => [
      {
        Header: `${OhsOrganisationRecordField.name} Name`,
        accessor: 'orgTier.name',
      },
      {
        Header: `Number of ${OhsEmployeesRecordField.name}`,
        accessor: 'orgTier.numEmployees',
      },
      {
        Header: OhsIndustryRecordField.name,
        accessor: 'orgTier.industry',
      },
      {
        Header: OhsLocationRecordField.name,
        accessor: 'orgTier.country',
        Cell: (val: any) => userLocation(val.row.original),
      },
      {
        Header: 'User Name',
        Cell: (val: any) => userNameField(val.row.original),
      },
      {
        Header: OhsDateCreatedRecordField.name,
        accessor: 'orgTier.dateCreated',
        Cell: (val: any) => dateCreated(val.row.original),
      },
      {
        Header: 'Date Completed',
        accessor: 'orgTier.dateCompleted',
        Cell: (val: any) => dateCompleted(val.row.original),
      },
    ],
    [setAssetIsLoading]
  );

  const columns = [...tableCol];

  const openFilter = (open: boolean) => {
    // set callback to filter and execute when filter is changed
    dispatch(
      setFilterCallback(() => {
        dispatch(setCurrentRegisterPage(currentPage));
      })
    );
    dispatch(toggleTopFilterDrawer(open));
  };

  return (
    <OhsModuleBackground>
      <OhsModuleHeader title="Onboardings" as="h3">
        <OhsModuleHeaderButtons
          iconButton={{
            func: () => openFilter(true),
            icon: 'filter',
            color: 'blue',
          }}
          openExcelModal={() => exportOnboardingList()}
        />
      </OhsModuleHeader>

      <Box sx={{ position: 'relative' }} pt={2}>
        <OhsRegister
          columns={columns}
          data={onboardingList?.items ?? []}
          hasExpanding={false}
          isLoading={isLoading}
          useTableSearch
        />
      </Box>
      {!isLoading && (
        <OhsRegisterTableServerPagination
          currentPage={currentPage}
          totalPages={onboardingList?.pagination.totalPages ?? 0}
          setCurrentPage={setCurrentPage}
        />
      )}
      <OhsExcelExportModal
        fileName="onboardingLists"
        open={excelModalOpen}
        setOpen={setExcelModalOpen}
        configList={freemiumConfigList}
        exportRecordList={freemiumExportList}
        onClose={onExcelModalClose}
      />
    </OhsModuleBackground>
  );
}

export default OhsOnboardingList;
