import { OhsAttachment } from 'global-components/form/fields/attachments/OhsAttachmentModels';
import { ModuleType, OhsModuleCreateBy } from 'global-services/constants/OhsObject';
import { OhsTier } from 'global-services/constants/tier/OhsTier';

import { OhsContractorCustomFilters } from '../../contractor/models/OhsContractorModels';

export interface OhsHumanResourceCustomFilters {
  archived?: boolean;
  workplaces?: string[];
  next?: string;
  skipOrgRecords?: boolean;
}

export interface OhsHrCustomFilters {
  archived?: boolean;
  categories?: string[] | [];
  next?: string;
}

export interface OhsHumanResourceFilterPayload extends OhsHumanResourceCustomFilters {
  viewPreset?: 'view_1' | 'view_2' | 'view_3';
  page: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
}

export interface OhsHrListsFilterPayload extends OhsContractorCustomFilters {
  viewPreset?: 'view_1' | 'view_2' | 'view_3';
  page: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
  allocatedToOrg: boolean;
  allocatedToWorkplace: boolean;
  notAllocated: boolean;
}

export interface OhsCommunicationTabFilterPayload {
  viewPreset?: 'view_1';
  page: number;
  sort: {
    order: number;
    key: string;
  };
  count: boolean;
  archived?: boolean;
  next?: string;
}

export default class OhsHumanResourceListsRecord {
  attachments: OhsAttachment[];

  avatar?: OhsAttachment[];

  complete?: boolean;

  name: string;

  gender?: string;

  genderOther?: string;

  postalAddress: string;

  dateOfBirth: string;

  personalEmail: string;

  personalPhone: string;

  phone: string;

  emergencyContactName: string;

  emergencyContactPhone: string;

  createdBy: OhsModuleCreateBy;

  email: string;

  position: string;

  positionOther?: string;

  managerName: string;

  managerEmail: string;

  tier?: OhsTier;

  worksplaceTier?: OhsTier;

  type: ModuleType;

  referenceId: string;

  dateCreated: string;

  dateStarted?: string;

  notes?: string;

  _id: string;

  profilePhoto?: { downloadUrl: string | undefined };

  archived?: boolean;

  constructor(tier?: OhsTier) {
    this.attachments = [];
    this.complete = false;
    this.name = '';
    this.postalAddress = '';
    this.createdBy = new OhsModuleCreateBy();
    this.email = '';
    this.personalEmail = '';
    this.personalPhone = '';
    this.phone = '';
    this.dateOfBirth = '';
    this.emergencyContactName = '';
    this.emergencyContactPhone = '';
    this.position = '';
    this.managerName = '';
    this.managerEmail = '';
    this.tier = tier;
    this.referenceId = '';
    this.type = ModuleType.Task;
    this._id = '';
    this.dateCreated = '';
  }
}
