import React from 'react';

import { makeStyles } from 'tss-react/mui';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';

import { ModuleUrl, OhsModuleTypeByUrl } from 'global-services/constants/OhsRoutes';
import { getModuleName } from 'user/OhsUserServices';
import OhsMessageBox from 'global-components/message-box/OhsMessageBox';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

import { getRouteMessages } from './OhsUtility';

export const noAccessPageStyles = makeStyles()(() => ({
  messageBox: {
    width: '100%',
    border: '2px solid #95c9d4',
    borderRadius: '5px',
    background: '#f8ffff',
    padding: '1em 1.5em',
  },
  title: {
    color: '#0e566c !important',
    marginBottom: '.25rem !important',
  },
  description: {
    color: '#0e566c',
  },
}));

const getArticle = (word: string) => {
  const vowelRegex = /^[aeiou]/i;
  return vowelRegex.test(word) ? 'an' : 'a';
};

const commonMessage = 'You do not have permission ';

interface Props {
  title: string;
  moduleName?: string;
}
function OhsNoAccessMessage(props: Props) {
  const location = useLocation();

  const pathInclude = (word: string) => _.includes(location.pathname, word);
  const user = OhsUserLocalServices.getLocalOhsUser();

  const modulePath: ModuleUrl = location.pathname.split('/')[1] as ModuleUrl;
  const moduleType = _.get(OhsModuleTypeByUrl, modulePath) || '';
  const moduleName = moduleType ? getModuleName(user?.configs, moduleType) : '';
  const articleAndModuleName = moduleName ? `${getArticle(moduleName)} ${moduleName}` : '';

  const routeMessages = getRouteMessages(articleAndModuleName, moduleName);
  const returnPageFunction = () => {
    const matchedRoute = routeMessages.find(({ route }) => pathInclude(route));

    if (props.moduleName) {
      return `${commonMessage} to view this ${props.moduleName}`;
    }
    if (matchedRoute) {
      return `${commonMessage} ${matchedRoute.message}`;
    }

    return 'You do not have permission to view this page';
  };

  return <OhsMessageBox title={props.title} description={returnPageFunction()} />;
}

OhsNoAccessMessage.defaultProps = {
  moduleName: '',
};

export default OhsNoAccessMessage;
