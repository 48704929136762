import {
  ApiResListsType,
  ApiResponseType,
  OhsApiRequestName,
  OhsApiRequestOptions,
  RequestType,
} from 'global-services/api/OhsApiModels';
import { ModuleType } from 'global-services/constants/OhsObject';
import { OhsAllocationItem } from 'global-components/allocation/OhsAllocatableModels';
import OhsContractorLookup from 'contractor/models/OhsContractorLookUp';
import { OhsHrLookup } from 'human-resource/models/OhsHumanResourceModels';
import { getCurrentTimezone } from 'global-services/OhsDataParse';
import { parseHrLookupList } from 'human-resource/OhsHumanResourceServices';
import { parseContractorLookupList } from 'contractor/OhsContractorService';
import OhsFetchAll from 'global-services/OhsFetchAll';

import OhsApiRequest from '../global-services/api/OhsApiRequest';
import {
  CorporateRiskAllocListRes,
  OhsCorporateRiskFilterPayload,
} from './models/OhsCorporateRiskModels';
import OhsCorporateRiskRecord from './models/OhsCorporateRiskRecord';
import OhsCorporateRiskTaskRecord from './models/OhsCorporateRiskTaskRecord';

const getCorporateRiskLists = (
  filterInfo: OhsCorporateRiskFilterPayload
): Promise<ApiResListsType<any[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.CorporateRisk, OhsApiRequestName.List, {
    filter: filterInfo,
  });
};
export const getCorporateRiskAllocatedList = (
  corporateRiskListIDs: string[]
): Promise<OhsCorporateRiskRecord[] | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.AllocatedList,
    {
      _ids: corporateRiskListIDs,
    }
  );
};

export const getCorporateRiskDetails = async (_id: string): Promise<OhsCorporateRiskRecord> => {
  const res: OhsCorporateRiskRecord | null = await OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.Fetch,
    { _id }
  );
  return res || new OhsCorporateRiskRecord();
};
export const archiveCorporateRisk = (payload: any, archived: boolean) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    archived ? OhsApiRequestName.UnArchive : OhsApiRequestName.Archive,
    payload
  );
};
export const deleteCorporateRisk = (corporateRiskId: string) => {
  return OhsApiRequest(RequestType.Module, ModuleType.CorporateRisk, OhsApiRequestName.Delete, {
    _id: corporateRiskId,
  });
};
export const allocateCorporateRisk = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = {
    _id,
    to: [],
    tzDateCreated: getCurrentTimezone(),
  };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  } else {
    payload.to = [allocationItem];
  }
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.Allocate,
    payload
  );
};
export const deAllocateCorporateRisk = (
  _id: string,
  allocationItem: OhsAllocationItem | OhsAllocationItem[]
): Promise<any> => {
  const payload: any = {
    _id,
    to: [],
    tz: getCurrentTimezone(),
  };
  if (Array.isArray(allocationItem)) {
    payload.to = allocationItem.map((item) => ({
      _id: item._id,
      type: item.type,
    }));
  } else {
    payload.to = allocationItem;
  }
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.Deallocate,
    payload
  );
};
export const getCorporateRiskAllocationList = (
  corporateRiskId: string
): Promise<ApiResponseType<CorporateRiskAllocListRes> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.AllocationList,
    {
      _id: corporateRiskId,
    }
  );
};
export const getCorporateRiskRecord = async (
  _id: string,
  disableErrorToast?: boolean
): Promise<OhsCorporateRiskRecord> => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.Fetch,
    { _id },
    { disableErrorToast }
  );
  if (!res) return new OhsCorporateRiskRecord();
  const newRes = {
    ...res,
    cusvalsDefinition: res.cusvals,
    subcategoryCusvalsDefinition: res.subcategoryCusvals,
  };
  return newRes;
};
export const getCorporateRiskTaskRecord = async (
  _id: string,
  taskId: string,
  disableErrorToast?: boolean
): Promise<OhsCorporateRiskTaskRecord> => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.Task,
    OhsApiRequestName.Fetch,
    {
      _id: taskId,
      for: {
        _id,
        type: ModuleType.CorporateRisk,
      },
    },
    { disableErrorToast }
  );
  if (!res) return new OhsCorporateRiskTaskRecord();

  let newRes = res;
  if (newRes?.for?.save) {
    newRes = {
      ...res,
      for: {
        save: {
          ...res?.for?.save,
          cusvalsDefinition: res?.for?.save?.cusvals,
          subcategoryCusvalsDefinition: res?.for?.save?.subcategoryCusvals,
        },
      },
    };
  }
  return newRes;
};

export const setConfidential = async (_id: string, isConfidential: boolean): Promise<any> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    isConfidential ? OhsApiRequestName.ConfidentialSet : OhsApiRequestName.ConfidentialUnSet,
    {
      _id,
    }
  );
};

export const editCorporateRisk = (payload: any, options?: OhsApiRequestOptions) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.Edit,
    payload,
    options
  );
};

export const getCorporateRiskLinkedActionList = async (_id: string) => {
  const res: any = await OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.LinkedActions,
    {
      _id,
    }
  );
  return res;
};

export const addCorporateRisk = (payload: any, options?: OhsApiRequestOptions) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.Create,
    payload,
    options
  );
};

export const getHrList = (filter?: any): Promise<ApiResListsType<OhsHrLookup[]> | null> => {
  return OhsApiRequest(RequestType.Module, ModuleType.CorporateRisk, OhsApiRequestName.HRLookup, {
    filter,
  });
};
export const getAllHrList = async () => {
  const res = await OhsFetchAll(getHrList, { archived: false });
  if (!res) return null;
  return parseHrLookupList(res?.items || []);
};

export const getContractorList = (
  filter?: any
): Promise<ApiResListsType<OhsContractorLookup[]> | null> => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.ContractorLookup,
    {
      filter,
    }
  );
};

export const getAllContractorList = async () => {
  const res = await OhsFetchAll(getContractorList, { archived: false });
  if (!res) return null;
  return parseContractorLookupList(res || []);
};

export const signOffCancelSave = (corporateId: string, taskId: string) => {
  const payload = {
    _id: corporateId,
    task: {
      _id: taskId,
    },
  };
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.SignOffRemoveSave,
    payload
  );
};

export const signOffCRiskTaskRecord = (
  corporateRiskRecord: OhsCorporateRiskTaskRecord,
  corporateId: string,
  taskId: string,
  options?: OhsApiRequestOptions
) => {
  const tasksid = {
    task: {
      _id: taskId,
    },
  };
  const mergedObject = { ...corporateRiskRecord, ...tasksid };
  const payload = mergedObject;
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.SignOff,
    payload,
    options
  );
};
export const getEvidencePdfDownloadUrl = (payload: any) => {
  return OhsApiRequest(
    RequestType.Module,
    ModuleType.CorporateRisk,
    OhsApiRequestName.Fetch,
    payload
  );
};

export default getCorporateRiskLists;
