import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiResListsType } from 'global-services/api/OhsApiModels';
import { OhsIncidentFilterPayload, OhsIncidentRootState } from 'incident/OhsIncidentModels';
import OhsIncidentRecord from 'incident/OhsIncidentRecord';
// eslint-disable-next-line import/no-cycle
import { getIncidentLists } from 'incident/OhsIncidentServices';
import { OhsGlobalSearchPayload } from 'search/OhsSearchModels';
import globalModuleSearch from 'search/OhsSearchServices';
import { OhsUser } from 'user/OhsUser';
import OhsUserLocalServices from 'user/OhsUserLocalServices';

export const incidentListsRPC = async (
  user: OhsUser,
  setIncidentFilters: OhsIncidentFilterPayload,
  userTier: number
): Promise<ApiResListsType<any[]> | null> => {
  if (
    userTier &&
    (userTier === 3 || userTier === 4 || userTier === 5) &&
    user?.configs.incident?.PERMISSIONS.view
  ) {
    return getIncidentLists(setIncidentFilters);
  }
  return null;
};

const incidentGlobalSearch = async (
  incidentSearch: OhsGlobalSearchPayload,
  setIncidentFilters: OhsIncidentFilterPayload,
  count: boolean,
  page: number
): Promise<ApiResListsType<any[]> | null> => {
  const searchInfo = {
    ...incidentSearch,
    filter: { ...incidentSearch.filter, count, page, archived: setIncidentFilters.archived },
  };
  const globalSearchRes: any = await globalModuleSearch(searchInfo);
  return globalSearchRes?.result;
};

export const fetchIncidentListsCountAsync = createAsyncThunk<
  ApiResListsType<OhsIncidentRecord[]> | null,
  undefined
>('incident/fetchIncidentCountList', async (_, thunkAPI) => {
  const {
    incident,
    globalSearch,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsIncidentRootState;
  const hasIncidentSearch = globalSearch.searchInfo.searchKey !== '';
  const { currentViewPreset } = incident;

  const viewPresetFilter = filterInfo.incidentModule[currentViewPreset];

  const incidentFiltersWithCount: OhsIncidentFilterPayload = {
    ...viewPresetFilter,
    count: true,
    page: 1,
    sort: { order: 1, key: '_id' },
  };

  try {
    const response = hasIncidentSearch
      ? await incidentGlobalSearch(globalSearch.searchInfo, incidentFiltersWithCount, true, 1)
      : await getIncidentLists(incidentFiltersWithCount);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});

export const fetchIncidentListsAsync = createAsyncThunk<
  ApiResListsType<OhsIncidentRecord[]> | null,
  undefined
>('incident/fetchIncidentList', async (_, thunkAPI) => {
  const {
    incident,
    globalSearch,
    globalfilter: { filterInfo },
  } = thunkAPI.getState() as OhsIncidentRootState;
  const user = OhsUserLocalServices.getLocalOhsUser() as OhsUser;
  const userTier = user?.tierNum ?? 0;
  const data = incident.incidentLists;
  const hasIncidentSearch = globalSearch.searchInfo.searchKey !== '';

  const { currentPage, currentViewPreset } = incident;

  const viewPresetFilter = filterInfo.incidentModule[currentViewPreset];

  const setIncidentFilters: OhsIncidentFilterPayload = {
    ...viewPresetFilter,
    next: currentPage > 10 ? data?.pagination?.next ?? '' : '',
    page: incident.currentPage > 10 ? 0 : incident.currentPage,
    sort: { ...JSON.parse(String(filterInfo.incidentModule.sort)) },
    count: false,
  };

  try {
    const response = hasIncidentSearch
      ? await incidentGlobalSearch(globalSearch.searchInfo, setIncidentFilters, false, currentPage)
      : await incidentListsRPC(user, setIncidentFilters, userTier);
    return response;
  } catch (err: any) {
    return thunkAPI.rejectWithValue(err.response.data);
  }
});
