import { ModuleType } from 'global-services/constants/OhsObject';
import OhsCusvalDefinition from 'global-components/form/cusvals/OhsCusvalDefinition';

import { OhsModuleCategory } from '../OhsModuleCategory';
import { getConfigValue, setPermissions } from '../OhsConfigServices';
import { OhsModuleConfig } from '../OhsModuleConfig';
import OhsModulePermissions from '../OhsModulePermissions';
import OhsFileBoxConfig from '../filebox/OhsFileBoxConfig';

interface IncidentCategoryList {
  title: string;
  description: string;
  options: { opt: string }[];
  cusvals: OhsCusvalDefinition | null;
}

class OhsIncidentConfig implements OhsModuleConfig {
  TITLE: string;

  PERMISSIONS: OhsModulePermissions;

  MORPH_PERMISSIONS: OhsModulePermissions;

  TYPE = ModuleType.Incident;

  BODY_PARTS_IMAGE_LINK: string;

  CREATE_INCIDENT_BODY_PART_INJURY_LIST: OhsModuleCategory[];

  CREATE_INCIDENT_CATEGORY_LIST: IncidentCategoryList[];

  CREATE_INCIDENT_CUSVALS: OhsCusvalDefinition;

  CREATE_INCIDENT_HAND_SIGNATURES: string;

  CREATE_INCIDENT_HAND_SIGNATURES_ROLE_LIST: { title: string; options: string[] }[];

  CREATE_INCIDENT_INJURED_PERSON_LOOKUP: { scope: string } | null;

  CREATE_INCIDENT_INJURED_PERSON_ROLE_LIST: string[];

  CREATE_INCIDENT_LOCATION_LIST: string[];

  CREATE_INCIDENT_PROP_ENV_DAMAGE_CUSVALS: OhsCusvalDefinition;

  DAYS_TO_COMPLETE_TASK: number | null;

  NOTIFICATION_DUE_TYPE_ENABLED: string[];

  ORG_RECORDS_ACCESS: boolean;

  REFERENCE_PREFIX: string;

  RISK_MATRIX_IMAGE_LINK: string;

  TASK_SIGNOFF_CONTROL_LEVEL_LIST: string[];

  TASK_SIGNOFF_CONTROL_REVIEW_LIST: string[];

  TASK_SIGNOFF_EXTERNAL_BODY_LIST: string[];

  TASK_SIGNOFF_HAND_SIGNATURES: string;

  TASK_SIGNOFF_HAND_SIGNATURES_ROLE_LIST: { title: string; options: string[] }[];

  TASK_SIGNOFF_HAZARD_CATEGORY_LIST: string[];

  TASK_SIGNOFF_INCIDENT_CUSVALS: OhsCusvalDefinition;

  TASK_SIGNOFF_LOST_TIME_INJURY_LIST: string[];

  TASK_SIGNOFF_SEVERITY_LIST: string[];

  fileboxConfig?: OhsFileBoxConfig;

  CONFIDENTIAL_ACCESS: boolean;

  CONFIDENTIAL_INCIDENTS: boolean;

  constructor(config: any, fileboxConfig?: any) {
    this.TITLE = getConfigValue(config, 'TITLE');
    this.PERMISSIONS = new OhsModulePermissions(false);
    this.MORPH_PERMISSIONS = new OhsModulePermissions(false);
    setPermissions(this.PERMISSIONS, getConfigValue(config, 'PERMISSIONS'));
    setPermissions(this.MORPH_PERMISSIONS, getConfigValue(config, 'MORPH_PERMISSIONS'));

    this.BODY_PARTS_IMAGE_LINK = getConfigValue(config, 'BODY_PARTS_IMAGE_LINK');
    this.CREATE_INCIDENT_BODY_PART_INJURY_LIST =
      getConfigValue(config, 'CREATE_INCIDENT_BODY_PART_INJURY_LIST') || [];
    this.CREATE_INCIDENT_CATEGORY_LIST =
      getConfigValue(config, 'CREATE_INCIDENT_CATEGORY_LIST') || [];
    this.CREATE_INCIDENT_CUSVALS = getConfigValue(config, 'CREATE_INCIDENT_CUSVALS');
    this.CREATE_INCIDENT_HAND_SIGNATURES = getConfigValue(
      config,
      'CREATE_INCIDENT_HAND_SIGNATURES'
    );
    this.CREATE_INCIDENT_HAND_SIGNATURES_ROLE_LIST =
      getConfigValue(config, 'CREATE_INCIDENT_HAND_SIGNATURES_ROLE_LIST') || [];
    this.CREATE_INCIDENT_INJURED_PERSON_LOOKUP = getConfigValue(
      config,
      'CREATE_INCIDENT_INJURED_PERSON_LOOKUP'
    );
    this.CREATE_INCIDENT_INJURED_PERSON_ROLE_LIST =
      getConfigValue(config, 'CREATE_INCIDENT_INJURED_PERSON_ROLE_LIST') || [];
    this.CREATE_INCIDENT_LOCATION_LIST =
      getConfigValue(config, 'CREATE_INCIDENT_LOCATION_LIST') || [];
    this.CREATE_INCIDENT_PROP_ENV_DAMAGE_CUSVALS = getConfigValue(
      config,
      'CREATE_INCIDENT_PROP_ENV_DAMAGE_CUSVALS'
    );
    this.DAYS_TO_COMPLETE_TASK = getConfigValue(config, 'DAYS_TO_COMPLETE_TASK');
    this.NOTIFICATION_DUE_TYPE_ENABLED =
      getConfigValue(config, 'NOTIFICATION_DUE_TYPE_ENABLED') || [];
    this.ORG_RECORDS_ACCESS = getConfigValue(config, 'ORG_RECORDS_ACCESS') || false;
    this.REFERENCE_PREFIX = getConfigValue(config, 'REFERENCE_PREFIX');
    this.RISK_MATRIX_IMAGE_LINK = getConfigValue(config, 'RISK_MATRIX_IMAGE_LINK');
    this.TASK_SIGNOFF_CONTROL_LEVEL_LIST =
      getConfigValue(config, 'TASK_SIGNOFF_CONTROL_LEVEL_LIST') || [];
    this.TASK_SIGNOFF_CONTROL_REVIEW_LIST =
      getConfigValue(config, 'TASK_SIGNOFF_CONTROL_REVIEW_LIST') || [];
    this.TASK_SIGNOFF_EXTERNAL_BODY_LIST =
      getConfigValue(config, 'TASK_SIGNOFF_EXTERNAL_BODY_LIST') || [];
    this.TASK_SIGNOFF_HAND_SIGNATURES = getConfigValue(config, 'TASK_SIGNOFF_HAND_SIGNATURES');
    this.TASK_SIGNOFF_HAND_SIGNATURES_ROLE_LIST =
      getConfigValue(config, 'TASK_SIGNOFF_HAND_SIGNATURES_ROLE_LIST') || [];
    this.TASK_SIGNOFF_HAZARD_CATEGORY_LIST =
      getConfigValue(config, 'TASK_SIGNOFF_HAZARD_CATEGORY_LIST') || [];
    this.TASK_SIGNOFF_INCIDENT_CUSVALS = getConfigValue(config, 'TASK_SIGNOFF_INCIDENT_CUSVALS');
    this.TASK_SIGNOFF_LOST_TIME_INJURY_LIST =
      getConfigValue(config, 'TASK_SIGNOFF_LOST_TIME_INJURY_LIST') || [];
    this.TASK_SIGNOFF_SEVERITY_LIST = getConfigValue(config, 'TASK_SIGNOFF_SEVERTIY_LIST') || [];
    this.CONFIDENTIAL_ACCESS = getConfigValue(config, 'CONFIDENTIAL_ACCESS') === true;
    this.CONFIDENTIAL_INCIDENTS = getConfigValue(config, 'CONFIDENTIAL_INCIDENTS') === true;
    if (fileboxConfig) {
      this.fileboxConfig = new OhsFileBoxConfig(fileboxConfig);
    }
  }
}

export default OhsIncidentConfig;
